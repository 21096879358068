import {Image, Pressable, StyleSheet, View, ImageBackground, Text} from 'react-native';
import React from 'react';
import {CardStatus} from '../types';
import RatingBox from "../controls/RatingBox";
import TRANSLATES from "../translates/translates";
import {getRatingInterval} from "../func";


interface IProps {
    status: CardStatus,
    style?: any,
    onSelected?: Function,
    isAthlete?: boolean
    price: string
    text1?: string
    text2?: string
}

interface IState {

}

export default class ShopPage extends React.Component<IProps, IState> {


    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    getCardByStatus() {
        switch (this.props.status) {
            case CardStatus.bronze:
                return require('../assets/bronze-pack.png');
            case CardStatus.silver:
                return require('../assets/silver-pack.png');
            case CardStatus.gold:
                return require('../assets/gold-pack.png');
        }
    }

    render() {
        return (
            <Pressable style={[styles.root, this.props.style]}
                       onPress={() => this.props.onSelected && this.props.onSelected()}>
                <Image source={this.getCardByStatus()} style={styles.img}/>
                <View style={styles.content}>

                    <RatingBox text={getRatingInterval(this.props.status)}
                               style={{marginTop: 32, marginLeft: 8, width: 54}}/>

                    <View style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 20,
                        marginLeft: 51,
                        position: 'relative'
                    }}>
                        <ImageBackground source={require('../assets/card-price.png')}
                                         style={{
                                             top: 0,
                                             left: 0,
                                             position: 'absolute',
                                             width: 52,
                                             height: 32,
                                         }}/>
                        <View style={{
                            top: 0,
                            left: 0,
                            position: 'absolute',
                            paddingLeft: 12,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <Text style={{fontSize: 9, color: '#fff', zIndex: 1, fontWeight: '600'}}>
                                {this.props.price}
                            </Text>
                            <Text style={{fontSize: 9, color: '#fff', zIndex: 1, fontWeight: '600'}}>
                                {TRANSLATES['Coins'].toLowerCase()}
                            </Text>
                        </View>
                    </View>
                    {this.props.text1 &&
                        <View style={{
                            alignItems: 'center',
                            width: '100%',
                            marginTop: 56,
                            paddingLeft: 12,
                            paddingRight: 12
                        }}>
                            <Text style={[styles.text, {fontSize: 13}]}>
                                {this.props.text1}
                            </Text>
                            <Text style={styles.text}>
                                {this.props.text2}
                            </Text>
                        </View>
                    }

                </View>

            </Pressable>
        );
    }
}


const styles = StyleSheet.create({
    ratingText: {
        color: '#fff',
        fontWeight: '700',
        fontSize: 10
    },
    ratingImg: {
        width: 12,
        height: 12,
    },
    ratingView: {
        position: 'absolute',
        left: 4,
        top: 4,
        borderRadius: 20,
        width: 36,
        height: 14,
        backgroundColor: 'rgba(64, 92, 191, 1)',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'row'
    },
    img: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: 100,
        height: 180,
    },
    root: {
        alignItems: 'flex-start',
        position: 'relative',
        width: 100,
        height: 180
    },
    content: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
    },
    cardPrice: {},
    text: {
        textAlign: 'center',
        color: '#fff',
        fontWeight: '500',
        fontSize: 10
    }
});
