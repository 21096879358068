import React from "react";
import {StyleSheet, View, Text} from "react-native";
import {COLORS} from "../colors";


export default function AchievementStepper(props: { numbers: number[], done: number, width: number }) {


    const screenWidth = props.width
    const startLeft = 35
    const stepWidth = (screenWidth - 2 * startLeft - 4 * 16) / 3

    let numbers = props.numbers
    if (props.done < 4) {
        numbers = [1, 2, 3, 4]
    }

    return <View style={styles.root}>
        <View style={styles.progress}>
            {numbers.map((num, index) => {
                    return <React.Fragment key={num}>
                        <View style={[styles.doneProgressBack, {
                            backgroundColor: props.done >= num ? COLORS.DARK_BLUE : '#CACDDE',
                            width: index == 0 ? startLeft : stepWidth
                        }, index == 0 && styles.leftProgressBack]}></View>

                        <View style={[styles.step]}>
                            <View style={styles.stepText} key={num}>
                                <Text style={styles.numText} numberOfLines={1}>№ {num}</Text>
                            </View>
                            <View style={props.done >= num ? styles.done : styles.notDone}>
                            </View>
                        </View>
                    </React.Fragment>
                }
            )}
        </View>
    </View>
}


const styles = StyleSheet.create({
    root: {
        marginTop: 32,
        paddingBottom: 6,
        borderRadius: 10,
    },
    stepText: {
        backgroundColor: COLORS.DARK_BLUE,
        borderRadius: 16,
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 4,
        paddingTop: 4,
        position: 'absolute',
        top: -24
    },
    numText: {
        color: '#fff',
        fontSize: 10,
        lineHeight: 12,
        fontWeight: 'bold',
        width: '100%'
    },
    done: {
        width: 8,
        height: 8,
        borderRadius: 8,
        backgroundColor: '#fff',
        zIndex: 10,
        position: 'relative'
    },
    notDone: {
        width: 8,
        height: 8,
        borderRadius: 8,
        backgroundColor: '#CACDDE',
    },
    step: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 16,
        height: 16,
        borderRadius: 16,
        backgroundColor: COLORS.DARK_BLUE,
        position: 'relative'
    },
    progress: {
        marginTop: 8,
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: 8,
        backgroundColor: '#CACDDE',
        borderRadius: 10,
        position: 'relative',
    },
    doneProgressBack: {
        height: 8,
        backgroundColor: COLORS.DARK_BLUE,
    },
    leftProgressBack: {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
    },
})