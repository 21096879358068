import {Dimensions, Image, ImageBackground, Pressable, StyleSheet, Text, View} from "react-native";
import {OnBoardingStyles} from "../Onboarding";
import {COLORS, RED} from "../colors";
import React from "react";
import {BattleStatus, Fight, Fighter} from "../types";
import {MMAAdvantage} from "./types";
import TRANSLATES from "../translates/translates";
import ToolTipButton from "../controls/ToolTipButton";
import WinOrLossBlockInLiveStat from "./WinOrLossBlockInLiveStat";
import {TopUserPanelHeight} from "../UserAppBar";

interface GFightBattleProps {
    advantage?: MMAAdvantage | undefined;
    red: Fighter
    blue: Fighter,
    fight?: Fight,
    onboardStep?: number | null,
    onSelectFighter?: Function,
    selectedFighter?: Fighter
}

export default function GFightBattle(props: GFightBattleProps) {
    let red = Math.round(props.advantage?.red || 0);
    let blue = Math.round(props.advantage?.blue || 0);

    function prepareNickname(name: string) {
        if (!name)
            return "";

        return "'" + name.replace("\n", "") + "'"
    }

    function renderRating(a: Fighter, style: any) {
        return <View style={[styles.ratingView, style]}>
            <Image source={require('../assets/rating.png')} style={[styles.ratingImg, {marginRight: 4}]}/>
            <Text style={styles.ratingText}>{a.rating.total || a.rating.totalRecord}</Text>
        </View>
    }

    function getSelectedBorderStyle(f: Fighter): any {
        if (props.selectedFighter?.id == f.id)
            return {borderColor: f.id == props.red.id ? RED : COLORS.DARK_BLUE, borderWidth: 3, borderStyle: "solid"}

        return {borderWidth: 3, borderStyle: "solid", borderColor: 'transparent'}
    }

    function getAthleteImage(f: Fighter) {
        let source = (f.avatarUrl || f.photoUrl) ? {uri: f.avatarUrl || f.photoUrl} : require('../assets/athlete_shadow.png')
        return <View style={[
            {backgroundColor: '#EFF1F6', borderRadius: 50, overflow: 'hidden'},
            getSelectedBorderStyle(f)
        ]}>
            <ImageBackground source={source} resizeMode="contain" style={styles.athleteImage}/>
        </View>
    }

    function renderLeft(a: Fighter) {
        let flagUrl = a.countryISO && `https://storage.yandexcloud.net/bd-sport-images/country/${a.countryISO}.png`;
        return <Pressable style={{maxWidth: 80}}
                          onPress={() => props.onSelectFighter && props.onSelectFighter(a)}>
            {getAthleteImage(a)}
            <View style={{marginTop: 8}}>
                <View style={[styles.nick_rating, {justifyContent: 'flex-start'}]}>
                    <Text numberOfLines={1} style={[styles.nickname, {textAlign: 'left', marginRight: 8}]}>
                        {prepareNickname(a.nickname)}
                    </Text>
                    {renderRating(a, {right: -40})}
                </View>
                <View
                    style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                    <Text style={[styles.name, {textAlign: 'left'}]}>{a.firstName}</Text>
                    {flagUrl && <Image source={{uri: flagUrl}} style={{width: 18, height: 10, marginLeft: 4}}/>}
                </View>
                <Text style={[styles.name, {textAlign: 'left'}]} numberOfLines={1}>{a.lastName}</Text>
            </View>
        </Pressable>

    }

    function renderRight(a: Fighter) {
        let flagUrl = a.countryISO && `https://storage.yandexcloud.net/bd-sport-images/country/${a.countryISO}.png`;
        return <Pressable style={{maxWidth: 80}} onPress={() => props.onSelectFighter && props.onSelectFighter(a)}>
            {getAthleteImage(a)}
            <View style={{marginTop: 8}}>
                <View style={[styles.nick_rating, {justifyContent: 'flex-end'}]}>
                    {renderRating(a, {left: -40})}
                    <Text numberOfLines={1} style={[styles.nickname, {textAlign: 'right', marginLeft: 8}]}>
                        {prepareNickname(a.nickname)}
                    </Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {flagUrl && <Image source={{uri: flagUrl}} style={{width: 18, height: 10, marginRight: 4}}/>}
                    <Text style={[styles.name, {textAlign: 'right'}]}>{a.firstName}</Text>

                </View>
                <Text style={[styles.name, {textAlign: 'right'}]} numberOfLines={1}>{a.lastName}</Text>
            </View>
        </Pressable>
    }

    const getWidth = () => {
        return Dimensions.get('window').width - 32 - 124 * 2
    }

    const renderAdvantageChart = () => {
        return <View style={[{
            flexDirection: 'column',
            alignItems: 'center',
            width: getWidth(),
            borderWidth: 2,
            borderColor: 'transparent'
        }, props.onboardStep == 1 && OnBoardingStyles.highlight]}>

            {props.advantage &&
                <>
                    <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 12}}>
                        <Text style={styles.headerText}>G-Fight</Text>
                        <ToolTipButton text={TRANSLATES['G-Fight']} style={{marginLeft: 8}}>
                        </ToolTipButton>
                    </View>
                    <View
                        style={{
                            height: 4,
                            width: '100%',
                            flexDirection: 'row',
                            borderRadius: 10,
                            overflow: 'hidden',
                            marginTop: 16
                        }}>
                        {(red > 0 || blue > 0) &&
                            <>
                                <View style={{height: 4, width: red + '%', backgroundColor: RED}}/>
                                <View style={{height: 4, width: (100 - red) + '%', backgroundColor: COLORS.DARK_BLUE}}/>
                            </>
                        }
                    </View>

                    <View style={{
                        marginTop: 8,
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Text style={[styles.valueText, {marginRight: 'auto'}]}>{red}%</Text>
                        <Text style={[styles.valueText, {marginLeft: 'auto'}]}>{blue}%</Text>
                    </View>
                </>
            }

            {props.fight &&
                <>
                    <Text style={{color: '#797B87', fontSize: 12, marginTop: 23}}>{props.fight.weight}</Text>
                    <Text style={{color: '#797B87', fontSize: 12}}>{props.fight.sportType}</Text>
                </>
            }

        </View>
    }

    return <View style={[{flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between'}]}>

        <View>
            {renderLeft(props.red)}
            <WinOrLossBlockInLiveStat fight={props.fight} isRed={true}/>
        </View>

        {renderAdvantageChart()}
        <View style={{alignItems: 'flex-end'}}>
            {renderRight(props.blue)}
            <WinOrLossBlockInLiveStat fight={props.fight} isRed={false}/>
        </View>
    </View>
}

const styles = StyleSheet.create({
    ratingView: {
        height: 14,
        flexDirection: 'row',
        backgroundColor: '#FFDAA5',
        borderRadius: 20,
        paddingLeft: 6,
        paddingRight: 6,
        alignItems: 'center',
        position: "absolute"
    },
    ratingImg: {
        width: 12,
        height: 12,
    },
    ratingText: {
        color: '#2E2D2D',
        fontWeight: '700',
        fontSize: 10
    },
    nick_rating: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 4
    },
    athleteImage: {
        width: 80,
        height: 80
    },
    nickname: {
        color: '#797B87',
        fontSize: 8,
        width: 100,
        height: 12
    },
    name: {
        color: '#2E2D2D',
        fontWeight: '700',
        fontSize: 12,
        maxWidth: 80
    },
    headerText: {
        fontSize: 15,
        fontWeight: '700',
        textAlign: 'center',
        color: '#2E2D2D'
    },
    valueText: {
        color: '#2E2D2D',
        fontSize: 12,
        fontWeight: '700',
        width: 50,
        textAlign: 'center'
    },
})
