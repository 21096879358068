import React from 'react';
import {GestureResponderEvent, Image, Pressable, StyleSheet} from 'react-native';
import MessageToolTip from "./MessageToolTip";


interface IProps {
    text?: string,
    children?: React.ReactNode,
    style?: any
}

interface IState {
    modalVisible: boolean,
    y: number,
    x: number
}

export default class ToolTipButton extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            modalVisible: false,
            x: 0,
            y: 0
        };
    }

    onShowToolTipClick(e: GestureResponderEvent) {
        //console.log(e.nativeEvent.pageY)

        this.setState({modalVisible: true, x: e.nativeEvent.pageX, y: e.nativeEvent.pageY})
    }

    render() {

        return <Pressable
            style={[{height: 20, width: 20, alignItems: 'center', justifyContent: 'center'}, {...this.props.style}]}
            onPress={(e) => this.onShowToolTipClick(e)}>
            <Image style={styles.infoImage} source={require('../icons/info.png')}/>

            {this.state.modalVisible &&
                <MessageToolTip message={this.props.text}
                                onClose={() => this.setState({modalVisible: false})}
                >
                    {this.props.children}
                </MessageToolTip>
            }
        </Pressable>
    }
}


export const styles = StyleSheet.create({
    infoImage: {
        width: 20,
        height: 20
    }
});
