import React from 'react';
import {Pressable, StyleSheet, Text} from 'react-native';
import {MAIN_BAR_COLOR} from '../colors';
import {ServerAPI} from "../ServerAPI";

interface IProps {
    onClick?: Function,
    enabled?: boolean,
    text: string,
    width?: number | string,
    height?: number,
    backgroundColor?: string,
    color?: string,
    margin?: number,
    textStyle?: any,
    style?: any
    track?: boolean,
    upperCase?: boolean
}

export default class FButton extends React.Component<IProps, any> {

    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    onClick() {
        if (this.props.enabled !== false) {
            if (this.props.track !== false)
                ServerAPI.track('buttonClick', this.props.text)
            if (this.props.onClick)
                this.props.onClick()
        }
    }

    render() {
        let textStyle = {
            ...styles.preStatButtonText,
            color: this.props.color || MAIN_BAR_COLOR
        }

        if (this.props.upperCase !== false)
            (textStyle as any).textTransform = 'uppercase';

        return <Pressable
            onPress={() => this.onClick()}
            style={[{
                ...styles.preStatButton,
                margin: this.props.margin == undefined ? 16 : 0,
                width: this.props.width,
                height: this.props.height || 35,
                borderColor: this.props.backgroundColor || MAIN_BAR_COLOR,
                backgroundColor: this.props.backgroundColor,
                ...this.props.style
            }, this.props.enabled === false && styles.disabled]}
        >
            <Text numberOfLines={1}
                  style={[textStyle, this.props.textStyle]}>
                {this.props.text}
            </Text>
        </Pressable>
    }
}

const styles = StyleSheet.create({
    disabled: {
        opacity: 0.7,
    },
    preStatButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 35,
        borderStyle: 'solid',
        borderColor: MAIN_BAR_COLOR,
        borderWidth: 2,
        borderRadius: 30
    },
    preStatButtonText: {

        fontWeight: '700',
    }
});
