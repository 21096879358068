import React, {useEffect, useState} from "react";
import {Animated, ImageBackground, Pressable, Text} from "react-native";
import {COLORS} from "../colors";
import TRANSLATES from "../translates/translates";


export type CardStatusCoefInfo = { [key: string]: number }

export default function GiveRewardScreen(props: {
    children: React.ReactNode,
    animateNum?: number,
    closeReward: () => void
}) {

    const [animation,] = useState(new Animated.Value(0))
    const [step, setStep] = useState(0)

    useEffect(() => {

        const animate = () => {
            setStep(0)
            animation.setValue(0)
            Animated.timing(animation, {
                toValue: 1,
                duration: 700,
                useNativeDriver: false
            }).start(() => {

                setStep(1)
                animation.setValue(0)
                Animated.timing(animation, {
                    toValue: 1,
                    duration: 300,
                    useNativeDriver: false
                }).start(() => setStep(3))

            });
        };

        animate();
    }, [props.animateNum]);

    const scale1 = animation.interpolate({
        inputRange: [0, 1],
        outputRange: [2.0, 0.7],
    });

    const scale2 = animation.interpolate({
        inputRange: [0, 1],
        outputRange: [0.7, 1],
    });

    const scale = step == 0 ? scale1 : scale2


    return <Pressable onPress={props.closeReward}
                      style={{
                          width: '100%',
                          height: ' 100%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: COLORS.YELLOW,
                          flexDirection: 'column'
                      }}>

        <Text style={{
            fontSize: 28,
            fontWeight: '600',
            color: step == 3 ? '#fff' : 'transparent',
            position: 'relative',
            bottom: 50
        }}>
            {TRANSLATES['reward.your-got']}
        </Text>


        <Animated.View style={[{
            transform: [{scale: scale}],
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
        }]}>
            {step != 3 && <ImageBackground
                source={require(`../assets/reward-back.png`)}
                resizeMode="cover"
                style={{
                    position: "absolute",
                    height: 540,
                    width: '100%'
                }}/>
            }

            {props.children}

        </Animated.View>
        <Text style={{
            fontSize: 12,
            fontWeight: '600',
            position: 'absolute',
            bottom: 24, color: '#fff', textTransform: 'uppercase'
        }}>
            {step == 3 ? TRANSLATES['reward.tab-to-continue'] : TRANSLATES['reward.tab-to-skip']}
        </Text>
    </Pressable>
}