import {Animated, Dimensions, Pressable, StyleSheet, Text} from "react-native";
import React, {useEffect, useState} from "react";
import {Defs, LinearGradient, Path, Rect, Stop, Svg} from "react-native-svg";

let id = 0

export function EventRewardOrangeButton(props: { onClick: () => void, text: string, style?: any }) {

    const [animation,] = useState(new Animated.Value(0))


    useEffect(() => {

        const animate = () => {
            animation.setValue(0)
            Animated.timing(animation, {
                toValue: 1,
                duration: 2500,
                useNativeDriver: true
            }).start(() => animate());
        };

        animate();
    }, []);

    let w = Dimensions.get('window').width - 32

    const position = animation.interpolate({
        inputRange: [0, 1],
        outputRange: [-w - 50, w + 50],
    });

    id++

    return <Pressable onPress={() => props.onClick()} style={[styles.root, props.style]}>

        <Svg width={w} height="40" fill="none" style={{position: 'absolute', left: 0, top: 0}}>
            <Defs>
                <LinearGradient id={id + ''} x1={w} y1="2" x2={w} y2="40"
                                gradientUnits="userSpaceOnUse">
                    <Stop stopColor="#FFB146"/>
                    <Stop offset="1" stopColor="#FF9400"/>
                </LinearGradient>
            </Defs>

            <Rect
                x={0}
                y={0}
                width={w}
                height={40}
                fill={`url(#${id})`}/>

        </Svg>

        <Animated.View style={[{position: 'absolute',  width: '100%', height: '100%', transform: [{ translateX: position }]}]}>
            <Svg width={w * 2} height="84px" viewBox="4 0 72 84" fill="none">
                <Path opacity="0.5" d="M28 0H58L27 84H-3L28 0Z" fill="#ffdda9"/>

                <Path opacity="0.5" d="M31 0H39L8 84H0L31 0Z" fill="#ffdda9" translateX="40"/>

            </Svg>
        </Animated.View>

        <Text style={styles.text}>{props.text}</Text>

    </Pressable>
}

const styles = StyleSheet.create({
    root: {
        marginLeft: 16,
        marginRight: 16,
        paddingLeft: 16,
        overflow: 'hidden',
        borderRadius: 10,
        height: 40,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        marginBottom: 24,
    },
    text: {
        color: '#FFFFFF',
        fontWeight: '700',
        fontSize: 15,
        textAlign: 'center',
        zIndex: 1
    },
    img: {
        height: 14,
        width: 14
    },
})
