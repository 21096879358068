import {Dimensions, StyleSheet, Text, View} from "react-native";
import TRANSLATES from "../../translates/translates";
import React from "react";
import {GroundStat} from "./compare-types";
import {COLORS} from "../../colors";
import BarChart from "./BarChart";
import CircleChart from "./CircleChart";

interface IProps {
    groundStat: GroundStat,
    landedColor: string,
    totalColor: string
}

export default function AttemptsTT(props: IProps) {


    let stat = props.groundStat


    let screenWidth = Dimensions.get('window').width

    let takedown = [
        {
            name: TRANSLATES['compare-stat.punches-stat.successful'],
            value: stat.takedown.success,
            color: props.landedColor,
            percent: 0
        },
        {
            name: TRANSLATES['compare-stat.punches-stat.attempts'],
            value: stat.takedown.total,
            color: props.totalColor,
            percent: 0
        }
    ]

    let trans = [
        {
            name: TRANSLATES['compare-stat.punches-stat.successful'],
            value: stat.transition.success,
            color: props.landedColor,
            percent: 0
        },
        {
            name: TRANSLATES['compare-stat.punches-stat.attempts'],
            value: stat.transition.total,
            color: props.totalColor,
            percent: 0
        }
    ]


    return <View>
        <Text style={{fontSize: 12, color: COLORS.BLACK, fontWeight: '500', marginLeft: 16}}>
            {TRANSLATES['compare-stat.ground-stat.takedowns']}
        </Text>

        <View style={{flexDirection: 'row', paddingLeft: 16, paddingRight: 16, marginTop: 16}}>
            <BarChart sortedValues={takedown} width={screenWidth - 32 - 80}/>
            <CircleChart title={TRANSLATES['compare-stat.efficiency']}
                         style={styles.circle}
                         value={100 * props.groundStat.takedown.success / props.groundStat.takedown.total}
                         color={props.totalColor}/>
        </View>

        <Text style={{fontSize: 12, color: COLORS.BLACK, fontWeight: '500', marginLeft: 16, marginTop: 16}}>
            {TRANSLATES['compare-stat.ground-stat.transitions']}
        </Text>

        <View style={{flexDirection: 'row', paddingLeft: 16, paddingRight: 16, marginTop: 16}}>
            <BarChart sortedValues={trans} width={screenWidth - 32 - 80}/>
            <CircleChart title={TRANSLATES['compare-stat.efficiency']}
                         style={styles.circle}
                         value={100 * props.groundStat.transition.success / props.groundStat.transition.total}
                         color={props.totalColor}/>
        </View>
    </View>
}


const styles = StyleSheet.create({
    circle: {
        marginLeft: 16
    }
})
