import {
    StyleSheet,
    View,
    Text, Image, ScrollView, Pressable,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import {ServerAPI} from '../ServerAPI';
import {Gym, UserCard, Weight} from '../types';
import {get3SubList, getData, getHeightExpression, getSumRating, getWidthExpression, saveData} from '../func';
import ScrollViewRefresh from "../controls/ScrollViewRefresh";
import TRANSLATES from "../translates/translates";
import {MAIN_BAR_COLOR, ORANGE_COLOR} from "../colors";
import FButton from "../controls/FButton";
import UserAvatarInfo from "../UserAvatarInfo";
import BottomModal from "../controls/BottomModal";
import Card from "./Card";
import BoardStep from "../controls/BoardStep";
import {OnBoardingStyles} from "../Onboarding";

interface IProps {
    navigation?: any
    route?: any
}


export default function UserGymCards(props: IProps) {

    const [athleteCards, setAthleteCards] = useState<UserCard[]>([]);
    const [gyms, setGyms] = useState<Gym[]>([]);
    const [weights, setWeights] = useState<Weight[]>([]);
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [selectCardWeight, setSelectCardWeight] = useState<Weight | null>(null);
    const [onboardStep, setOnboardStep] = useState<number>(0);


    useEffect(() => {
        refresh();
    }, [])

    async function updateOnBoarding() {
        let res = await getData('OnBoardingDone_Gym2');

        if (!res)
            setOnboardStep(1)
    }

    function onBoardNextClick() {
        if (onboardStep + 1 == 4)
            doneOnboarding()
        else
            setOnboardStep(onboardStep + 1)
    }

    function onBoardSkipClick() {
        doneOnboarding()
    }

    function doneOnboarding() {
        setOnboardStep(0)
        saveData('OnBoardingDone_Gym2', 'done')
    }

    async function refresh() {
        setRefreshing(true)


        let weights = await ServerAPI.get<Weight[]>('/fantasy/weights');
        setWeights(weights)

        let gyms = await ServerAPI.get<Gym[]>('/fantasy/getUserGyms');

        setGyms(gyms)
        setRefreshing(false)

        let list = await ServerAPI.get<UserCard[]>('/fantasy/getUserCards');
        setAthleteCards(list)

        updateOnBoarding()
    }

    function renderCardItem(w: Weight) {

        let gymType = props.route.params?.gymType as string;

        let currentGym = gyms.find(it => it.Type == gymType)

        let cardOnThisPosition = athleteCards.find(c => c.athleteWeightId == w.Id
            && c.gyms?.find(it => it == currentGym?.Id))


        let res = <View style={styles.cardItem} key={w.Id}>
            <Text style={styles.weightText} numberOfLines={1}>{w.Name}</Text>
            <Pressable style={styles.cardPlace} onPress={() => setSelectCardWeight(w)}>
                {cardOnThisPosition ? <Card card={cardOnThisPosition}/> :
                    <Image source={require('../icons/plus.png')} style={{width: 24, height: 24}}/>
                }
            </Pressable>
            <FButton margin={0}
                     track={false}
                     text={TRANSLATES[cardOnThisPosition ? 'Change' : 'Add']}
                     style={{marginTop: 8}}
                     height={24}
                     onClick={() => {
                         ServerAPI.track(cardOnThisPosition ? 'Gym_Change_Card' : 'Gym_Add_Card')
                         setSelectCardWeight(w)
                     }}
                     width="100%"
                     upperCase={false}
                     backgroundColor={cardOnThisPosition ? ORANGE_COLOR : MAIN_BAR_COLOR}
                     color={'#FFFFFF'}
            />
        </View>

        if ((onboardStep == 1 || onboardStep == 2) && w.Id == weights[0].Id)
            return <View style={OnBoardingStyles.highlight}>
                {res}
            </View>

        return res;
    }

    function render3List(cardList: Weight[][]) {
        return cardList.map((subList, idx) =>
            <View
                key={idx}
                style={{
                    flexDirection: 'row',
                    justifyContent: subList.length == 3 ? 'space-between' : 'space-evenly',
                    marginTop: 16
                }}>
                {subList.map(w => renderCardItem(w))}
            </View>
        )
    }

    async function onCardChosen(card: UserCard) {
        setRefreshing(true)
        setSelectCardWeight(null)

        let gymType = props.route.params?.gymType as string;

        await ServerAPI.post('/fantasy/addCardToGym', {
            userCardId: card.id,
            weightId: selectCardWeight?.Id,
            gymType: gymType
        });
        refresh();
    }

    function render3UserCartList(cardList: UserCard[][]) {
        return cardList.map((subList, idx) =>
            <View
                key={idx}
                style={{
                    flexDirection: 'row',
                    justifyContent: subList.length == 3 ? 'space-between' : 'space-evenly',
                    marginTop: 16
                }}>
                {subList.map(it => <Card key={it.id} card={it} onSelected={() => onCardChosen(it)}/>)}
            </View>
        )
    }


    function renderAppBar() {
        return <View style={styles.appBar}>

            <UserAvatarInfo navigation={props.navigation}/>
            <View style={styles.bottom}/>
            <View>
                <Text style={styles.gymNameText}>
                    {TRANSLATES['GeneralGym']}
                </Text>
            </View>

        </View>
    }


    let gymType = props.route.params?.gymType as string;

    let cardList = get3SubList(weights);
    let athleteCardsForChooseDialog = athleteCards.filter(c => c.athleteWeightId == selectCardWeight?.Id);
    let rating = getSumRating(athleteCards, gyms.find(it => it.Type == gymType)?.Id)


    return (<View style={styles.root}>

        {onboardStep > 0 && <View style={{
            width: '100%',
            height: '100%',
            padding: 16,
            position: 'absolute',
            zIndex: 2
        }}>
            <BoardStep onNextClick={() => onBoardNextClick()}
                       currentStep={onboardStep - 1}
                       position={'bottom'}
                       stepCount={3}
                       text={(TRANSLATES as any)['GymOnBoard' + onboardStep]}
                       onSkipClick={() => onBoardSkipClick()}/>
        </View>}

        {renderAppBar()}
        <View style={styles.title}>
            <View style={{width: 200}}>
                <Text style={styles.titleText}>{TRANSLATES['GymPageTitle']}</Text>
            </View>
            <View style={[onboardStep == 3 && OnBoardingStyles.highlight, {marginLeft: 'auto', marginRight: 16}]}>
                <Text style={styles.titleText2}>{rating}</Text>
            </View>
            {/*<Image source={require('../icons/share.png')} style={{width: 24, height: 24}}/>*/}
        </View>

        <ScrollViewRefresh style={styles.content}
                           refreshing={refreshing}
                           onRefresh={() => refresh()}>
            {render3List(cardList)}
            <View style={{height: 60}}/>
        </ScrollViewRefresh>

        {selectCardWeight && <BottomModal visible={true}
                                          title={TRANSLATES['AddCardToGym']}
                                          height={athleteCardsForChooseDialog.length ? 400 : 150}
                                          onCloseClick={() => setSelectCardWeight(null)}>

            {athleteCardsForChooseDialog.length ?
                <ScrollView>
                    {render3UserCartList(get3SubList(athleteCardsForChooseDialog))}
                </ScrollView> :
                <Text>{TRANSLATES['NoWeightCards']}</Text>
            }

        </BottomModal>
        }

    </View>)
}

const appBarHeight = 178

const styles = StyleSheet.create({
    bottom: {
        position: 'absolute',
        bottom: -30,
        left: 0,
        width: getWidthExpression(0),
        backgroundColor: '#EFF1F7',
        borderRadius: 30,
        height: 60
    },
    appBar: {
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: MAIN_BAR_COLOR,
        height: appBarHeight,
    },
    root: {
        height: '100%',
    },
    content: {
        paddingLeft: 16,
        paddingRight: 16,
        height: getHeightExpression(appBarHeight),
    },
    title: {
        marginLeft: 16,
        marginRight: 16,
        position: 'relative',
        top: -10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    titleText: {
        fontSize: 15,
        fontWeight: '500',
        color: '#2E2D2D'
    },
    weightText: {
        textAlign: 'center',
        fontSize: 12,
        fontWeight: '500',
        color: '#A6A9C0'
    },
    gymNameText: {
        marginTop: 24,
        color: '#FFFFFF',
        fontWeight: '700',
        fontSize: 20,
        width: 120
    },
    titleText2: {
        fontSize: 30,
        fontWeight: '700',
        color: '#2E2D2D'
    },
    cardPlace: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginTop: 8,
        width: 100,
        height: 140,
        backgroundColor: '#E5E6EB',
        borderRadius: 10,
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: '#CACDDE'
    },
    cardItem: {
        width: 100,
        alignItems: 'center'
    },
});
