import React from 'react';
import {Image, Pressable, StyleSheet, Text, View} from 'react-native';
import {MAIN_BAR_COLOR} from '../colors';
import {Achievement, AchievementType} from "../types";
import TRANSLATES, {getCurrentLang} from "../translates/translates";
import MessageToolTip from "../controls/MessageToolTip";

interface IProps {
    achievement: Achievement
    index: number,
    isDone: boolean,
    disabledToolTip?: boolean
}


export default function AchievementCard(props: IProps) {

    const [toolTipMsg, setToolTipMsg] = React.useState('')

    const onPress = () => {

        if (props.disabledToolTip)
            return

        let lang = getCurrentLang()
        if (props.isDone)
            setToolTipMsg(lang == 'ru' ? props.achievement.desc_done_ru : props.achievement.desc_done_en)
        else
            setToolTipMsg(lang == 'ru' ? props.achievement.desc_ru : props.achievement.desc_en)
    }

    let content: React.ReactNode = null

    const opacityImage = props.isDone && !props.disabledToolTip ? 1 : 0.5

    switch (props.achievement.type) {
        case AchievementType.rank:
            let lvl = props.index == 3 ? 1 : ((props.index - 3) / 4 + 1)
            content = <>
                <View style={{position: 'relative', alignItems: 'center', height: 23}}>
                    <Image style={[styles.imgRank, {opacity: opacityImage}]}
                           source={require('../assets/status.png')}/>
                    <Text style={styles.levelNumStyle}>{lvl}</Text>
                </View>
                <Text style={[styles.achName, {
                    marginTop: 6,
                    color: props.isDone ? '#000' : '#BABABA'
                }]}>{(TRANSLATES as any)['Level' + lvl]}</Text>
            </>
            break
        case AchievementType.coins:
            content = <>
                <Image style={{width: 23, height: 23, opacity: opacityImage}}
                       source={require('../shop/images/coin1.png')}/>
                <Text style={[styles.achName, {
                    marginTop: 6,
                    color: props.isDone ? '#000' : '#BABABA'
                }]}>{props.achievement.value + ''}</Text>
            </>
            break
        case AchievementType.play_fantasy:
            let text = props.achievement.value_ex ? TRANSLATES[`Achievements.Play.${props.achievement.value_ex}`]
                : props.achievement.value + ' ' + TRANSLATES['Achievements.Event']
            content = <>
                <Image style={{width: 34, height: 23, opacity: opacityImage}}
                       source={require('../assets/game-pad.png')}/>
                <Text
                    style={[styles.achName, {marginTop: 6, color: props.isDone ? '#000' : '#BABABA'}]}>{text}</Text>
            </>
            break
        case AchievementType.general_raiting_gym:
            content = <>
                <Image style={{width: 26, height: 26, opacity: opacityImage}}
                       source={require('../assets/shield.png')}/>
                <Text style={[styles.achName, {
                    marginTop: 3,
                    color: props.isDone ? '#000' : '#BABABA'
                }]}>{props.achievement.value}</Text>
            </>
            break

    }


    return (
        <Pressable style={[styles.root]} onPress={onPress}>
            {toolTipMsg ? <MessageToolTip message={toolTipMsg}
                                           onClose={() => setToolTipMsg('')}/>: <></>
            }
            <Text
                style={[styles.topText, {backgroundColor: props.isDone ? MAIN_BAR_COLOR : '#A2A2A2'}]}>№ {props.index}</Text>
            <View style={{alignItems: 'center', marginTop: 18}}>
                {content}
            </View>
        </Pressable>
    );

}

const styles = StyleSheet.create({
    levelNumStyle: {
        color: '#fff',
        fontWeight: '700',
        fontSize: 10,
        zIndex: 1,
        marginTop: 7
    },
    root: {
        height: 99,
        width: 76,
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        overflow: 'hidden',
        elevation: 3,
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12)',
    },
    imgRank: {
        width: 72,
        height: 23,
        position: 'absolute',
        top: 5
    },
    topText: {
        lineHeight: 24,
        fontWeight: '600',
        fontSize: 10,
        color: '#fff',
        width: '100%',
        height: 24,
        textAlign: 'center',
        backgroundColor: MAIN_BAR_COLOR,
    },
    achName: {
        textAlign: 'center',
        color: '#2E2D2D',
        fontWeight: '700',
        fontSize: 12,
    },
});
