import {StyleSheet, Text, View} from "react-native";
import React from "react";
import {BattleStatus, Fight} from "../types";
import {getEndTime} from "./WinBlock";
import TRANSLATES from "../translates/translates";
import {MAIN_BAR_COLOR} from "../colors";


export default function WinOrLossBlockInLiveStat(props: { fight?: Fight, isRed: boolean }) {

    if (!props.fight || props.fight.status != BattleStatus.Finished)
        return <></>

    if (!props.fight.winner || props.fight.winner == 'draw')
        return <></>

    let winnerId = props.isRed ? props.fight.red.id : props.fight.blue.id

    if (winnerId == props.fight.winner)
        return <View style={styles.winBlock}>
            <View style={styles.winTitle}>
                <Text style={styles.winTextTitle}>{TRANSLATES['Win']}</Text>
            </View>
            <View style={{height: 44, alignItems: 'center', justifyContent: 'center'}}>
                <Text style={styles.winText1}>{props.fight.winType}</Text>
                <Text style={styles.winText2}>({props.fight.winDescription || props.fight.winType})
                    R{props.fight.winRound}, {getEndTime(props.fight)}</Text>
            </View>
        </View>


    return <View style={styles.winBlock}>
        <Text style={styles.winText1}>{TRANSLATES['Loss']}</Text>
    </View>

}

const styles = StyleSheet.create({
    winTitle: {
        width: '100%',
        minHeight: 20,
        backgroundColor: MAIN_BAR_COLOR,
        justifyContent: 'center'
    },
    winBlock: {
        marginTop: 8,
        overflow: 'hidden',
        backgroundColor: '#CACDDE',
        borderRadius: 10,
        width: 124,
        minHeight: 29,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    winTextTitle: {
        textAlign: 'center',
        color: '#fff',
        fontSize: 10
    },
    winText1: {
        color: '#2E2D2D',
        fontSize: 10,
        fontWeight: '700',
    },
    winText2: {
        color: '#797B87',
        fontSize: 10,
        fontWeight: '500',
        textAlign: 'center'
    },
})