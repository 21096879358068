import React from 'react';
import {Platform, RefreshControl, ScrollView, StyleSheet} from 'react-native';
import CircularProgress from "./CircularProgress";


export default class ScrollViewRefresh extends React.Component<{
	children: any,
	style?: any,
	refreshing: boolean,
	onRefresh: Function
}, {
	posY: number
}> {

	private startMoveY: number = 0
	scrolling: number = 0

	constructor(props: any)
	{
		super(props);
		this.state = {
			posY: 0
		}
	}


	render()
	{
		if (Platform.OS === 'web')
		{

			return <ScrollView style={this.props.style}
									 onScroll={(event) =>
									 {
										 this.scrolling = event.nativeEvent.contentOffset.y;
									 }}
									 onMoveShouldSetResponder={(event) => {

										 if (!this.startMoveY)
										 	this.startMoveY = event.nativeEvent.locationY;
										 else
										 {
											 if (Math.abs(event.nativeEvent.locationY - this.startMoveY) > 15)
												 return true;
										 }

										 return false;
									 }}
									 onStartShouldSetResponder={() => true}
									 onResponderMove={(event) =>
									 {
										 if (this.scrolling == 0)
										 {
											 if (!this.startMoveY)
												 this.startMoveY = event.nativeEvent.locationY
											 else
											 {
												 if (Math.abs(event.nativeEvent.locationY - this.startMoveY) > 15)
													 this.setState({
														 posY: Math.min(50, event.nativeEvent.locationY - this.startMoveY)
													 })
											 }
										 }
									 }}
									 onResponderRelease={(event) =>
									 {
										 this.setState({posY: 0})
										 this.startMoveY = 0;
										 if (this.scrolling == 0)
											 this.props.onRefresh()
									 }}>

				{this.state.posY > 0 && <CircularProgress style={[styles.progress, {top: this.state.posY}]}/>}
				{this.props.refreshing && <CircularProgress style={[styles.progress, {top: 50}]}/>}

				{this.props.children}
			</ScrollView>
		}


		return <ScrollView style={this.props.style}
								 refreshControl={<RefreshControl
									  refreshing={this.props.refreshing}
									  onRefresh={() => this.props.onRefresh()}/>}
		>

			{this.props.children}

		</ScrollView>
	}
}

const styles = StyleSheet.create({
	progress: {
		position: "absolute",
		top: 0,
		left: '43%'
	}
});
