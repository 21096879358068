import {Dimensions, PixelRatio, StyleSheet, Text, View} from 'react-native';
import React, {useContext, useEffect, useState} from 'react';
import {ServerAPI} from '../ServerAPI';
import {
    BattleStatus,
    BoughtFantasyInfo,
    FantasyInfo,
    FantasyListFilter,
    FantasyRateRow,
    FantasyStatus,
    Fight,
    Fighter,
    UserCard,
    UserRating,
} from '../types';
import BattleItem from './BattleItem';
import SelectTeamAppBar, {SelectTeamAppBarHeight} from './SelectTeamAppBar';
import BottomModal from '../controls/BottomModal';
import FantasyResultTable from '../main/FantasyResultTable';
import AthleteRowResult from './AthleteRowResult';
import {DAY, getData, getDiffStr, getHeightExpression, isAnonymous, isPastEvent, saveData, UserContext} from '../func';
import TRANSLATES from '../translates/translates';
import SignForm from '../auth/SignForm';
import PreStatTable from "./PreStatTable";
import LiveStatTable from "./LiveStatTable";
import ScrollViewRefresh from "../controls/ScrollViewRefresh";
import SelectFighter1 from "./SelectFighter1";
import SelectFighter2 from "./SelectFighter2";
import SelectFighter3 from "./SelectFighter3";
import SelectFighter4 from "./SelectFighter4";
import FantasyRateRowView from "./FantasyRateRowView";
import BoardStep from "../controls/BoardStep";
import BottomModalAds from "../controls/BottomModalAds";
import FighterRecordHistory from "./FighterRecordHistory";

import {useBottomTabBarHeight} from "@react-navigation/bottom-tabs";
import {hideBanner, showBanner} from "../ads";
import CourseFight from "./CourseFight";
import ComparePreStatTable from "./compare-stat/ComparePreStatTable";
import {EventRewardOrangeButton} from "../controls/EventRewardOrangeButton";
import EventRewardModal from "./EventRewardModal";
import {parse} from "date-fns";
import MessageToolTip from "../controls/MessageToolTip";
import HorBattleList from "./HorBattleList";


interface IProps {
    navigation?: any,
    route?: any
}

interface NavigationParams
{
    athlete: Fighter;
    fantasy: FantasyInfo;
    fantasyRateRow: FantasyRateRow;
    fight?: Fight
    userCards: UserCard[]
}

interface IState {
    list: Fight[],
    savedAthletes: FantasyRateRow[],
    fantasy: FantasyInfo,
    balance: number,
    athleteClicked: Fighter | null,
    loginVisible: boolean,
    registerVisible: boolean,
    signText: string,
    fantasyOpenedInModal: boolean
    athleteResultOpenedInModal: FantasyRateRow | null
    fighterResultOpenedInModal: Fighter | null,
    refreshing: boolean,
    currentUserRating: UserRating | null
    allUserCards: UserCard[],
    compareStat: Fight | null
    preStatDialog: Fight | null,
    liveStatDialog: Fight | null,
    liveStatCourseDialog: Fight | null,
    navigateName: string,
    onboardStep: number | null,
    viewHistoryFighter: Fighter | null,
    navigateParams: NavigationParams
}

const ONBoardSteps = [
    'BattleList_OnBoard1',
    'BattleList_OnBoard2',
    'BattleList_OnBoard3',
    'BattleList_OnBoard4'
    //'BattleList_OnBoard5',
]


const ONBoardStepsCalced = [
    'BattleList2_OnBoard1',
    'BattleList2_OnBoard2'
]

let updateId: any = null

export default function BattleList(props: IProps) {

    let battleClicked: Fight | null = null
    const tabBarHeight = useBottomTabBarHeight()
    const {user, setUser} = useContext(UserContext);
    const [eventRewardVis, setEventRewardVis] = useState<boolean>(false)
    const [toolTipMsg, setToolTipMsg] = useState('')

    let [state, setState] = useState<IState>({
        onboardStep: null,
        athleteClicked: null,
        loginVisible: false,
        registerVisible: false,
        currentUserRating: null,
        fantasyOpenedInModal: false,
        athleteResultOpenedInModal: null,
        fighterResultOpenedInModal: null,
        list: [],
        balance: 100,
        allUserCards: [],
        savedAthletes: [] as FantasyRateRow[],
        fantasy: null as unknown as FantasyInfo,
        refreshing: false,
        preStatDialog: null,
        liveStatDialog: null,
        navigateName: '',
        navigateParams: null as any,
        signText: '',
        viewHistoryFighter: null,
        liveStatCourseDialog: null,
        compareStat: null
    })

    const doUpdate = async () => {
        await refresh()

        showBattleListBanner()


        let fantasyInfo = props.route.params.fantasyInfo as FantasyInfo;
        if (fantasyInfo.Status != FantasyStatus.Open)
            updateId = setInterval(() => {
                if (props.navigation.isFocused())
                    refresh()
            }, 4000)


        return () => {
            hideBanner();
            clearInterval(updateId)
        }
    }

    useEffect(() => {
        doUpdate()
    }, [props])

    const setPartialState = (partialState: any) => {
        setState(prev => ({
            ...prev,
            ...partialState
        }))
    }

    const showBattleListBanner = () => {
        showBanner('BattleList', -PixelRatio.get() * tabBarHeight);
    }


    const refresh = async () => {

        let fantasyInfo = props.route.params.fantasyInfo as FantasyInfo;
        props.navigation.setOptions({title: fantasyInfo.EventName});

        if (fantasyInfo.Id) {
            let fullINfo = await ServerAPI.get<FantasyInfo>(`/fantasy/${fantasyInfo.Id}`)
            setPartialState({fantasy: fullINfo});
            await updateBalance(fullINfo);
        } else {
            setPartialState({fantasy: fantasyInfo})
            await updateBalance(fantasyInfo);
        }

        let list = await ServerAPI.get<Fight[]>(`/events/${fantasyInfo.GloryEventID}`)

        setPartialState({list: list});

        if (ServerAPI.hasAuth() && fantasyInfo.Id) {
            let res = await ServerAPI.get<BoughtFantasyInfo>(`/fantasy/getBoughtFantasy/${fantasyInfo.Id}`)
            setPartialState({
                savedAthletes: res.items,
                currentUserRating: res.userRating,
            });
        }


        if (ServerAPI.hasAuth() && !state.allUserCards?.length) {
            let allUserCards = await ServerAPI.get<UserCard[]>('/fantasy/getUserCards');

            setPartialState({
                allUserCards: allUserCards
            })
        }
    }

    const getRewardDateDiffStr = () => {
        if (!state.fantasy || state.fantasy.Status == FantasyStatus.Open)
            return ''


        let d = parse(state.fantasy.EventDate, 'yyyy-MM-dd HH:mm:ss.S', new Date());

        let diff = (d.getTime() - DAY) - new Date().getTime()


        if (diff <= 0) {
            diff = d.getTime() - new Date().getTime()

            if (diff <= 0)
                return ''
        }

        return getDiffStr(diff)
    }

    const updateBalance = async (fantasyInfo: FantasyInfo) => {

        if (!fantasyInfo)
            return;

        if (fantasyInfo.Status == FantasyStatus.Open) {
            if (ServerAPI.hasAuth()) {
                let value = await ServerAPI.get(`/fantasy/getBalance/${fantasyInfo.Id}`) as number;
                setPartialState({
                    balance: value,
                });
            }

            let res = await getData('OnBoardingDone_BattleList');

            if (!res)
                setPartialState({
                    onboardStep: 0
                })
        } else if (fantasyInfo.Status == FantasyStatus.Calculated) {
            let res = await getData('OnBoardingDone_BattleList2');

            if (!res)
                setPartialState({
                    onboardStep: 0
                })
        }
    }

    const onBoardNextClick = () => {
        if (state.onboardStep!! + 1 == getOnBoardSteps().length)
            doneOnboarding()
        else
            setPartialState({onboardStep: state.onboardStep!! + 1})
    }

    const onBoardSkipClick = () => {
        doneOnboarding()
    }

    const doneOnboarding = () => {
        setPartialState({onboardStep: null})

        let fantasyInfo = props.route.params.fantasyInfo as FantasyInfo;
        if (fantasyInfo.Status == FantasyStatus.Open)
            saveData('OnBoardingDone_BattleList', 'done')
        else
            saveData('OnBoardingDone_BattleList2', 'done')
    }

    const getOnBoardSteps = () => {
        if (state.fantasy?.Status == FantasyStatus.Calculated)
            return ONBoardStepsCalced;

        return ONBoardSteps;
    }


    const onRemoveClick = async (row: FantasyRateRow) => {
        if (state.fantasy.Status == FantasyStatus.Open) {
            let newBalance = await ServerAPI.post(`/fantasy/removeAthlete/${state.fantasy.Id}`, row) as number;
            setPartialState({
                balance: newBalance,
                savedAthletes: state.savedAthletes.filter(it => it.AthleteId != row.AthleteId),
            });
        }

        closeChooseAthlete();
    }

    const athleteClick = async (a: Fighter, battle: Fight | null = null) => {
        if (battle)
            battleClicked = battle;

        ServerAPI.track('Fighter_Pick', a.id)

        if (!ServerAPI.hasAuth()) {
            setPartialState({
                loginVisible: true,
                athleteClicked: a
            });
            return;
        }
        if (isAnonymous(user)) {
            setPartialState({
                registerVisible: true,
                athleteClicked: a
            });
            return;
        }

        let row = state.fantasy.Points?.find(p => p.GAthleteId == a.id) as FantasyRateRow;
        if (!row) {

            if (isPastEvent(state.fantasy))
                setToolTipMsg(TRANSLATES['battle-list.fighter-past']);
            else
                setToolTipMsg(TRANSLATES['battle-list.fighter-not-open']);
            return;
        }

        let fantasyRateRow = state.savedAthletes.find(it => it.AthleteId == row.AthleteId);

        if (state.fantasy.Status == FantasyStatus.Open) {
            if (fantasyRateRow) {
                openPurchasedFantasyRow(a, fantasyRateRow);
            } else {
                if (battle) {
                    if (state.savedAthletes.find(it => battle.red.id == it.GAthleteId || battle.blue.id == it.GAthleteId))
                        return;		//уже купили противника
                }
                if (state.balance < row.Points) {
                    setToolTipMsg(TRANSLATES['NotEnoughCoins']);
                } else {
                    goStep1(a, row);
                }
            }
        } else {
            if (fantasyRateRow?.CalcLog) {
                ServerAPI.track('Fighter_Pick_Coins', a.id)

                setPartialState({
                    fighterResultOpenedInModal: a,
                    athleteResultOpenedInModal: fantasyRateRow,
                });
            } else
                setToolTipMsg(TRANSLATES['battle-list.fighter-past']);
        }
    }

    const openPurchasedFantasyRow = async (a: Fighter, fantasyRateRow: FantasyRateRow) => {

        let usedUserCards = await ServerAPI.get<UserCard[]>(`/fantasy/getUserCards/${fantasyRateRow.Id}`);

        navigate('FantasyRateRowView', {
            athlete: a,
            userCards: [...state.allUserCards, ...usedUserCards],
            fantasyRateRow: fantasyRateRow,
            fantasy: state.fantasy
        });
    }

    const closeChooseAthlete = () => {
        setPartialState({
            navigateName: '',
            navigateParams: null as any
        })
    }

    const getNavigateStep = () => {
        if (state.navigateName == 'SelectFighter1')
            return <SelectFighter1 athlete={state.navigateParams.athlete}
                                   fantasy={state.navigateParams.fantasy}
                                   userCards={state.navigateParams.userCards}
                                   fantasyRateRow={state.navigateParams.fantasyRateRow}
                                   onNextStepClick={(editedRow: FantasyRateRow) => {
                                       goStep2(state.navigateParams.athlete, editedRow, state.navigateParams);
                                   }}
                                   onBackClick={() => closeChooseAthlete()}/>


        if (state.navigateName == 'SelectFighter2')
            return <SelectFighter2 athlete={state.navigateParams.athlete}
                                   fantasy={state.navigateParams.fantasy}
                                   userCards={state.navigateParams.userCards}
                                   fantasyRateRow={state.navigateParams.fantasyRateRow}
                                   onNextStepClick={(editedRow: FantasyRateRow) => {
                                       goStep3(state.navigateParams.athlete, editedRow, state.navigateParams);
                                   }}
                                   onBackClick={() => goStep1(state.navigateParams.athlete, state.navigateParams.fantasyRateRow)}/>

        if (state.navigateName == 'SelectFighter3')
            return <SelectFighter3 athlete={state.navigateParams.athlete}
                                   fantasy={state.navigateParams.fantasy}
                                   userCards={state.navigateParams.userCards}
                                   fantasyRateRow={state.navigateParams.fantasyRateRow}
                                   onNextStepClick={(editedRow: FantasyRateRow) => {
                                       goStep4(state.navigateParams.athlete, editedRow, state.navigateParams);
                                   }}
                                   onBackClick={() => goStep2(state.navigateParams.athlete, state.navigateParams.fantasyRateRow, state.navigateParams)}/>

        if (state.navigateName == 'SelectFighter4')
            return <SelectFighter4 athlete={state.navigateParams.athlete}
                                   fantasy={state.navigateParams.fantasy}
                                   userCards={state.navigateParams.userCards}
                                   fight={state.navigateParams.fight as Fight}
                                   fantasyRateRow={state.navigateParams.fantasyRateRow}
                                   onNextStepClick={async (editedRow: FantasyRateRow) => {
                                       let response = await ServerAPI.post(`/fantasy/buyFantasy/${state.fantasy.Id}`, editedRow) as {
                                           balance: number,
                                           rowId: number
                                       };

                                       editedRow.Id = response.rowId;

                                       setPartialState({
                                           balance: response.balance,
                                           savedAthletes: [...state.savedAthletes, editedRow],
                                       });

                                       openPurchasedFantasyRow(state.navigateParams.athlete, editedRow);
                                   }}
                                   onBackClick={() => goStep3(state.navigateParams.athlete, state.navigateParams.fantasyRateRow, state.navigateParams)}/>


        if (state.navigateName == 'FantasyRateRowView')
            return <FantasyRateRowView
                userCards={state.navigateParams.userCards as UserCard[]}
                onChangeClick={() => goStep1(state.navigateParams.athlete, state.navigateParams.fantasyRateRow)}
                onRemoveClick={() => onRemoveClick(state.navigateParams.fantasyRateRow)}
                closeClick={() => {
                    ServerAPI.track('Fighter_Pick_Event')
                    closeChooseAthlete()
                }}
                athlete={state.navigateParams.athlete}
                fantasy={state.navigateParams.fantasy}
                fantasyRateRow={state.navigateParams.fantasyRateRow}
            />

        return <></>
    }

    const navigate = (name: string, params: any) => {
        let trackName = "";
        switch (name) {
            case 'SelectFighter1':
                trackName = 'Fighter_Pick_1step'
                break;
            case 'SelectFighter2':
                trackName = 'Fighter_Pick_2step'
                break;
            case 'SelectFighter3':
                trackName = 'Fighter_Pick_3step'
                break;
            case 'SelectFighter4':
                trackName = 'Fighter_Pick_4step'
                break;
            case 'FantasyRateRowView':
                trackName = 'Fighter_Pick_Ready'
                break;
        }
        if (trackName)
            ServerAPI.track(trackName)
        setPartialState({
            navigateName: name,
            navigateParams: params
        })
    }

    const goStep1 = async (a: Fighter, row: FantasyRateRow) => {
        let usedUserCards = await ServerAPI.get<UserCard[]>(`/fantasy/getUserCards/${row.Id}`);
        navigate('SelectFighter1', {
            athlete: a,
            fantasy: state.fantasy,
            userCards: [...state.allUserCards, ...usedUserCards],
            fantasyRateRow: {
                AdPoints: [],
                SelectedUserCards: [],
                ...row as any,
            }
        })
    }

    const goStep2 = (fighter: Fighter, row: FantasyRateRow, params: NavigationParams) => {
        navigate('SelectFighter2', {
            athlete: fighter,
            fantasyRateRow: row,
            userCards: params.userCards,
            fantasy: state.fantasy
        });
    }

    const goStep3 = (fighter: Fighter, row: FantasyRateRow, params: NavigationParams) => {
        navigate('SelectFighter3', {
            athlete: fighter,
            fantasyRateRow: row,
            userCards: params.userCards,
            fantasy: state.fantasy
        });
    }

    const goStep4 = (fighter: Fighter, row: FantasyRateRow, params: NavigationParams) => {
        navigate('SelectFighter4', {
            athlete: fighter,
            fantasyRateRow: row,
            fantasy: state.fantasy,
            userCards: params.userCards,
            fight: battleClicked
        });
    }

    let stage: string | null = null;

    let boardSteps = getOnBoardSteps();

    //render
    let isPast = isPastEvent(state.fantasy)

    return (<View style={styles.root}>

        <BottomModal visible={state.loginVisible || state.registerVisible}
                     title={state.signText || TRANSLATES['SignIn']}
                     height={400}
                     onCloseClick={() => setPartialState({
                         athleteClicked: null,
                         loginVisible: false,
                         registerVisible: false
                     })}
        >
            <SignForm
                isSignIn={!state.registerVisible}
                onTitleChanged={(text: string) => {
                    setPartialState({signText: text});
                }}
                doneCallback={async () => {
                    setPartialState({
                        loginVisible: false,
                        registerVisible: false,
                        athleteClicked: null
                    });
                    await refresh()
                }}/>
        </BottomModal>

        {state.onboardStep != null && <View style={{
            width: '100%',
            height: '100%',
            padding: 16,
            position: 'absolute',
            zIndex: 2,
            top: -35
        }}>
            <BoardStep onNextClick={() => onBoardNextClick()}
                       currentStep={state.onboardStep}
                       position={state.onboardStep > 0 && Dimensions.get('window').height < 700 ? 'top' : 'bottom'}
                       stepCount={boardSteps.length}
                       text={(TRANSLATES as any)[boardSteps[state.onboardStep]]}
                       onSkipClick={() => onBoardSkipClick()}/>

        </View>}

        {state.fantasy && <SelectTeamAppBar balance={state.balance}
                                            filter={props.route.params.filter as FantasyListFilter}
                                            onboardVis={state.onboardStep == 0}
                                            userRating={state.currentUserRating}
                                            showResultClick={() => state.fantasy.Id && setPartialState({
                                                fantasyOpenedInModal: true,
                                            })}
                                            fantasy={state.fantasy}/>}

        {(!isPast || state.fantasy?.IsLive) && user?.Email && state.list?.length > 0 && <EventRewardOrangeButton
            onClick={() => {
                ServerAPI.track("get_set_cards_event")

                if (state.fantasy.Status == FantasyStatus.Open)
                    setEventRewardVis(true)
                else {
                    setToolTipMsg(TRANSLATES['battle.list.event-reward-msg'] + ' ' + getRewardDateDiffStr())
                }
            }}
            text={getRewardDateDiffStr() ? TRANSLATES['battle-list.event-reward'] + ' ' + getRewardDateDiffStr() : TRANSLATES['battle-list.event-reward']}
        />}

        {eventRewardVis && <EventRewardModal fantasy={state.fantasy} onClose={() => setEventRewardVis(false)}
                                             navigation={props.navigation}/>}
        {toolTipMsg ? <MessageToolTip message={toolTipMsg}
                                      onClose={() => setToolTipMsg('')}/> : <></>}

        <ScrollViewRefresh style={styles.content}
                           refreshing={state.refreshing}
                           onRefresh={() => refresh()}>
            {state.list.map(item => {
                    let res = [];
                    if (stage != item.stage) {
                        stage = item.stage;
                        res.push(<Text key={stage} style={styles.stageText}>{(TRANSLATES as any)[stage]}</Text>);
                    }

                    let onBoardingStep = null;

                    if (state.fantasy) {
                        if (state.fantasy.Status == FantasyStatus.Open) {
                            onBoardingStep = item == state.list[0] ? state.onboardStep : null
                        } else if (state.fantasy.Status == FantasyStatus.Calculated) {
                            if (state.savedAthletes.find(it => it.GAthleteId == item.red.id || it.GAthleteId == item.blue.id)?.CalcPoints != null) {
                                onBoardingStep = state.onboardStep
                            }
                        }
                    }

                    res.push(<BattleItem fight={item}
                                         onBoardStep={onBoardingStep}
                                         preStatClick={() => {
                                             ServerAPI.track('PreStats', 'data')
                                             setPartialState({preStatDialog: item})
                                         }}
                                         liveStatClick={() => {
                                             ServerAPI.track('LiveStats', 'data')
                                             setPartialState({liveStatDialog: item})
                                         }}
                                         userCards={state.allUserCards}
                                         key={item.id}
                                         team={state.savedAthletes}
                                         fantasy={state.fantasy}
                                         redClick={() => athleteClick(item.red, item)}
                                         blueClick={() => athleteClick(item.blue, item)}/>);

                    return res;
                },
            )}

        </ScrollViewRefresh>

        {!state.onboardStep && <View style={{height: 50}}></View>}

        {state.fantasyOpenedInModal &&
            <BottomModal visible={true}
                         padding={0}
                         onCloseClick={() => setPartialState({fantasyOpenedInModal: false})}
                         title={state.fantasy.EventName}
            >
                <FantasyResultTable fantasy={state.fantasy}/>
            </BottomModal>}


        {state.preStatDialog && !state.compareStat &&
            <BottomModalAds visible={!state.viewHistoryFighter}
                            showBanner={false}
                            height={Dimensions.get('window').height - 150}
                            onCloseClick={() => setPartialState({preStatDialog: null})}
                            title={TRANSLATES['PreStats']}
            >

                <PreStatTable fight={state.preStatDialog}
                              oddRed={state.fantasy?.Points?.find(it => it.AthleteId == state.preStatDialog?.red.key)?.Odd}
                              oddBlue={state.fantasy?.Points?.find(it => it.AthleteId == state.preStatDialog?.blue.key)?.Odd}
                              onLastFightClick={(f) => setPartialState({viewHistoryFighter: f})}
                              openCompareStatClick={() => {
                                  ServerAPI.track('tech_arsenal')
                                  if (!user?.HasSubscription) {

                                      props.navigation.navigate('Profile', {
                                          subscription: new Date(),
                                          goBack: true
                                      })
                                  } else
                                      setPartialState({compareStat: state.preStatDialog})
                              }}
                />
                <HorBattleList
                    status={null}
                    onSelect={(f) => setPartialState({preStatDialog: f})}
                    items={state.list} selected={state.preStatDialog}/>
            </BottomModalAds>}


        {state.compareStat &&
            <BottomModalAds visible={true}
                            height={Dimensions.get('window').height - 150}
                            showBanner={false}
                            onCloseClick={() => {
                                setPartialState({compareStat: null})
                            }}
                            title={TRANSLATES['TechnicalArsenal']}
            >
                <ComparePreStatTable fight={state.compareStat}/>

            </BottomModalAds>}

        {state.viewHistoryFighter && <FighterRecordHistory fighter={state.viewHistoryFighter}
                                                           navigation={props.navigation}
                                                           onClose={() => setPartialState({
                                                               viewHistoryFighter: null
                                                           })
                                                           }/>}

        {state.liveStatDialog && !state.liveStatCourseDialog &&
            <BottomModalAds visible={true}
                            showBanner={false}
                            bannerStoreId={"OnBoardingDone_LiveStatTable"}
                            height={Dimensions.get('window').height - 150}
                            onCloseClick={
                                () => {
                                    setPartialState({liveStatDialog: null})
                                    showBattleListBanner()
                                }
                            }
                            title={TRANSLATES['LiveStats']}
            >
                <LiveStatTable fight={state.liveStatDialog}
                               openCourseDialog={() => {
                                   ServerAPI.track('course_of_fight')

                                   if (!user.HasSubscription) {

                                       props.navigation.navigate('Profile', {
                                           subscription: new Date(),
                                           goBack: true
                                       })
                                   } else
                                       setPartialState({liveStatCourseDialog: state.liveStatDialog})
                               }}/>

                <HorBattleList
                    status={BattleStatus.Finished}
                    onSelect={(f) => setPartialState({liveStatDialog: f})}
                    items={state.list} selected={state.liveStatDialog}/>
            </BottomModalAds>}

        {state.liveStatCourseDialog &&
            <BottomModalAds visible={true}
                            height={Dimensions.get('window').height - 30}
                            padding={0}
                            onCloseClick={
                                () => {
                                    setPartialState({liveStatCourseDialog: null})
                                }
                            }
                            title={TRANSLATES['CourseFight']}
            >
                <CourseFight height={Dimensions.get('window').height - 80}
                             fight={state.liveStatCourseDialog}
                             battleId={state.liveStatCourseDialog.id}/>

            </BottomModalAds>}

        {state.athleteResultOpenedInModal &&

            <BottomModal
                height={Dimensions.get('window').height}
                padding={0}
                overlayHeight={1}
                titleVisible={false}
                visible={true}
                onCloseClick={() => setPartialState({
                    athleteResultOpenedInModal: null,
                    fighterResultOpenedInModal: null,
                })}

            >
                <AthleteRowResult fantasy={state.fantasy}
                                  onCloseClick={() => setPartialState({
                                      athleteResultOpenedInModal: null,
                                      fighterResultOpenedInModal: null,
                                  })}
                                  fighter={state.fighterResultOpenedInModal as any}
                                  athleteRow={state.athleteResultOpenedInModal}/>
            </BottomModal>
        }


        {state.navigateName ?

            <BottomModal height={Dimensions.get('window').height - 10}
                         visible={true}
                         overlayHeight={1}
                         backgroundColor="#EFF1F7"
                         titleVisible={false}
                         padding={0}
                         onCloseClick={() => closeChooseAthlete()}

            >
                {getNavigateStep()}
            </BottomModal> : <></>
        }

    </View>)
}


const styles = StyleSheet.create({
    root: {
        height: '100%',
    },
    content: {
        backgroundColor: '#EFF1F7',
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 30,
        height: getHeightExpression(SelectTeamAppBarHeight),
    },
    stageText: {
        color: '#2E2D2D',
        fontSize: 15,
        fontWeight: '700',
        marginBottom: 16,
    },
});

