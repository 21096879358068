import {
    GestureResponderEvent,
    Image,
    ImageBackground,
    SafeAreaView,
    ScrollView,
    StyleSheet,
    Text,
    View
} from 'react-native';
import React from 'react';
import {FantasyInfo, FantasyListFilter, FantasyStatus, UserRating} from '../types';
import {COLORS, MAIN_BAR_COLOR} from '../colors';
import {getDateStringFromUTC2LocalTime, getWidthExpression} from '../func';
import Card from './Card';
import TRANSLATES from '../translates/translates';
import TPressable from "../controls/TPressable";
import {OnBoardingStyles} from "../Onboarding";
import BottomModal from "../controls/BottomModal";
import {ServerAPI} from "../ServerAPI";
import ToolTipButton from "../controls/ToolTipButton";
import MessageToolTip from "../controls/MessageToolTip";

interface IProps {
    balance: number,
    fantasy: FantasyInfo,
    showResultClick: Function,
    userRating?: UserRating | null,
    onboardVis?: boolean,
    filter: FantasyListFilter
}

interface IState {
    viewPlaceEvent: boolean
    toolTipVis: boolean,
    y: number
}

export default class SelectTeamAppBar extends React.Component<IProps, IState> {


    constructor(props: IProps) {
        super(props);
        this.state = {
            viewPlaceEvent: false,
            toolTipVis: false,
            y: 0
        };
    }

    getPromotionImage() {
        if (this.props.fantasy?.LogoUrl)
            return this.props.fantasy.LogoUrl;

        switch (this.props.fantasy?.TournamentName) {
            case 'UFC' :
                return require('../assets/ufc.png');
            case 'Bellator' :
                return require('../assets/bellator.png');
            case 'PFL' :
                return require('../assets/pfl.png');
            case 'Cage Warriors' :
                return require('../assets/cage.png');
        }
        return null;
    }


    render() {
        let tvsheight = Math.min(300, (this.props.fantasy.TVStreamList?.length || 0) * 48)

        return (
            <SafeAreaView style={styles.container}>
                <View style={styles.eventInfo}>

                    {this.state.toolTipVis && <MessageToolTip message={TRANSLATES['battle-list.appbar.tooltip']}
                                                              onClose={() => this.setState({toolTipVis: false})}
                    />}

                    <ImageBackground source={{uri: this.getPromotionImage()}} resizeMode="contain" style={styles.logo}/>
                    <View style={styles.textCont}>
                        <Text style={styles.eventName} numberOfLines={2}>{this.props.fantasy.EventName}</Text>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Text
                                style={styles.eventDate}>{getDateStringFromUTC2LocalTime(this.props.fantasy.EventDate)}</Text>
                            <TPressable name="viewPlaceEventButton"
                                        style={{marginLeft: 'auto', marginRight: 16}}
                                        onPress={() => this.setState({viewPlaceEvent: true})}>
                                <Image style={styles.eventInfoIcon} source={require('../icons/tv.png')}/>
                            </TPressable>
                        </View>
                    </View>
                </View>

                {(!this.props.fantasy.Status || this.props.fantasy.Status == FantasyStatus.Open) && this.props.filter != FantasyListFilter.Past ?

                    <View style={[styles.balanceItem, this.props.onboardVis && OnBoardingStyles.highlight]}>
                        <Card/>
                        <Text style={styles.balance}>
                            {TRANSLATES['YourFantasyBalance']}
                            {this.props.fantasy.Status ? this.props.balance : 0}
                        </Text>

                        {!this.props.fantasy.Status && <ToolTipButton style={styles.helpBtn} text={TRANSLATES['fantasy.will.balance']}/>}
                    </View>
                    :
                    <View style={[styles.resultBlock, this.props.onboardVis && OnBoardingStyles.highlight]}>

                        <TPressable name="teamAppBarRank" style={styles.resultItem}
                                    onPress={(e) => {
                                        if (this.props.userRating && this.props.userRating.Place > 0) {
                                            ServerAPI.track('Event_Past_Rank', this.props.fantasy.EventName)
                                            this.props.showResultClick()
                                        }
                                        else
                                            this.onShowToolTipClick(e)
                                    }}>
                            <Text style={styles.resultText}>{this.props.userRating?.Place || 'n/d'}</Text>
                            <Text style={styles.resultTextName}>{TRANSLATES['Rank']}</Text>
                        </TPressable>

                        <TPressable name="teamAppBarPTS" style={styles.resultItem}
                                    onPress={(e) => {
                                        if (this.props.userRating && this.props.userRating.Place > 0) {
                                            ServerAPI.track('Event_Past_PTS', this.props.fantasy.EventName)
                                            this.props.showResultClick()
                                        }
                                        else
                                            this.onShowToolTipClick(e)
                                    }}>
                            <Text
                                style={styles.resultText}>{this.props.fantasy.Status == FantasyStatus.Calculated ? (this.props.userRating?.PTS || 'n/d') : ''}</Text>
                            <Text style={styles.resultTextName}>{TRANSLATES['PTS']}</Text>
                        </TPressable>

                        <TPressable name="teamAppBarCoins" style={styles.resultItem}
                                    onPress={(e) => {
                                        if (this.props.userRating && this.props.userRating.Place > 0) {
                                            ServerAPI.track('Event_Past_Coins', this.props.fantasy.EventName)
                                            this.props.showResultClick()
                                        }
                                        else
                                            this.onShowToolTipClick(e)
                                    }}>
                            <Text style={styles.resultText}>{this.props.userRating?.CalcedPoints || 'n/d'}</Text>
                            <Text style={styles.resultTextName}>{TRANSLATES['Coins']}</Text>
                        </TPressable>

                    </View>
                }

                <View style={styles.bottom}/>

                {this.state.viewPlaceEvent && <BottomModal visible={true}
                                                           title={this.props.fantasy.EventName}
                                                           height={190 + tvsheight}
                                                           onCloseClick={() => this.setState({viewPlaceEvent: false})}>
                    <Text style={styles.placeText}>
                        {this.props.fantasy.LocationName} {this.props.fantasy.City && `(${this.props.fantasy.City})`}
                    </Text>

                    <Text
                        style={styles.eventDateBottom}>{getDateStringFromUTC2LocalTime(this.props.fantasy.EventDate)}
                    </Text>

                    <View style={[styles.row]}>
                        <Text style={styles.headerCell}>{TRANSLATES['Country']}</Text>
                        <Text style={[styles.headerCell, {marginLeft: 'auto'}]}>{TRANSLATES['TV/Stream']}</Text>
                    </View>
                    <View style={{
                        height: 1,
                        width: '120%',
                        right: 50,
                        position: 'relative',
                        backgroundColor: '#CACDDE'
                    }}></View>
                    <ScrollView style={{height: tvsheight}}>
                        {this.renderTVList()}
                    </ScrollView>
                </BottomModal>}

            </SafeAreaView>

        );
    }

    onShowToolTipClick(e: GestureResponderEvent)
    {
        this.setState({toolTipVis: true, y: e.nativeEvent.pageY})
    }


    renderTVList() {
        let list = this.props.fantasy.TVStreamList || [{
            CountryName: this.props.fantasy.Country,
            TVStreamUrl: this.props.fantasy.TVStreamUrl
        }]
        return list.map((it, idx) => <View style={[styles.row]} key={idx}>
            <Text style={[styles.cellText]}>{it.CountryName}</Text>
            <Text style={[styles.cellText, {marginLeft: 'auto'}]}>{it.TVStreamUrl}</Text>
        </View>)
    }
}

export const SelectTeamAppBarHeight = 193;

const styles = StyleSheet.create({
    cellText: {
        color: '#2E2D2D'
    },
    headerCell: {
        fontWeight: '700',
        fontSize: 12,
        color: '#2E2D2D',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        width: '100%'
    },
    bottom: {
        position: 'absolute',
        bottom: -30,
        left: 0,
        width: getWidthExpression(0),
        backgroundColor: '#EFF1F7',
        borderRadius: 30,
        height: 60
    },
    textCont: {
        marginLeft: 16,
        width: getWidthExpression(135)
    },
    eventName: {
        color: 'white',
        fontWeight: '700',
        fontSize: 15,
        maxWidth: getWidthExpression(100)
    },
    eventInfoIcon: {
        height: 24,
        width: 24
    },
    eventDate: {
        color: 'white',
        opacity: 0.5,
    },
    eventDateBottom: {
        color: '#797B87',
        fontWeight: '500',
        fontSize: 12,
    },
    placeText: {
        marginTop: 8,
        color: '#797B87',
        fontWeight: '700',
        fontSize: 12,
    },
    eventInfo: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 16,
    },
    logo: {
        width: 72,
        height: 72,
        borderRadius: 50,
        backgroundSize: 'contain'
    },
    container: {
        paddingLeft: 16,
        backgroundColor: MAIN_BAR_COLOR,
        height: SelectTeamAppBarHeight,
    },
    helpBtn: {
        position: "absolute",
        right: 8,
        top: 8
    },
    balance: {
        fontWeight: '500',
        fontSize: 15,
        color: 'white',
        marginLeft: 12,
    },
    balanceItem: {
        height: 56,
        backgroundColor: COLORS.SECONDARY_ACCESS_COLOR,
        borderRadius: 10,
        marginRight: 16,
        marginTop: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    resultItem: {
        backgroundColor: COLORS.SECONDARY_ACCESS_COLOR,
        width: 90,
        height: 53,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    resultText: {
        fontSize: 15,
        fontWeight: '700',
        color: 'white',
    },
    resultTextName: {
        fontSize: 12,
        fontWeight: '500',
        opacity: 0.5,
        color: 'white',
    },
    resultBlock: {
        borderWidth: 2,
        borderColor: 'transparent',
        marginRight: 16,
        marginTop: 8,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },

});



