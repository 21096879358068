import React from 'react';
import {Animated, Image, Platform, StyleSheet, Text, View} from 'react-native';
import {getWidthExpression} from '../func';
import {ORANGE_COLOR} from "../colors";
import TPressable from "../controls/TPressable";
import {OnBoardingStyles} from "../Onboarding";


interface IProps {
	onPress?: Function,
	mainText: string,
	points: string | number,
	cardCoefText?: string | number,
	expandTextLines?: string[],
	onBordVis?: boolean
}

interface IState
{
	expanded: boolean,
	animatedHeight: Animated.Value
}

export default class ExpandBoxPoint extends React.Component<IProps, IState> {

	pan = new Animated.ValueXY();

	constructor(props: any)
	{
		super(props);
		this.state = {
			expanded: false,
			animatedHeight: new Animated.Value(0)
		};
	}

	onPress()
	{
		//https://snack.expo.dev/@vivekdoshi2/dynamic-height

		if (this.props.expandTextLines?.length)
		{
			if (!this.state.expanded)
			{
				this.setState({
					expanded: true,
				}, () =>
				{
					Animated.timing(this.state.animatedHeight, {
						toValue:  100 ,
						duration: 400,
						useNativeDriver: false
					}).start()
				});
			}
			else
			{
				Animated.timing(this.state.animatedHeight, {
					toValue:  0,
					duration: 400,
					useNativeDriver: false
				}).start(() => {

					this.setState({expanded: false})
				})
			}
		}
	}


	render()
	{
		let rowCount = this.props.expandTextLines?.filter(it => !!it).length!!

		let maxHeight = 95;

		if (rowCount == 4)
			maxHeight = 85;
		else if (rowCount < 4)
			maxHeight = 70;

		const interpolatedHeight = this.state.animatedHeight.interpolate({
			inputRange: [0, 100],
			outputRange: [0, maxHeight]
		})

		return <>
			<TPressable style={[styles.root, this.props.onBordVis && OnBoardingStyles.highlight ]}
						  name={"expandPoint: " + this.props.mainText}
						  onPress={() => this.onPress()}>
				<View style={styles.name}>
					<Text style={styles.nameText}>{this.props.mainText}</Text>
					{this.props.expandTextLines && <Image source={this.state.expanded ? require(`../icons/collapse.png`) : require(`../icons/expand.png`)}
							 style={styles.expandImage}/>}
				</View>
				<View style={styles.point}>
					<Text style={styles.pointText}>{this.props.points}</Text>
				</View>
				{this.props.cardCoefText && <Text style={[styles.coefText]}>{this.props.cardCoefText}x</Text>}


			</TPressable>
			{this.state.expanded && <Animated.View
				 style={[styles.expandedBlock, { height: interpolatedHeight }]}>
				{this.props.expandTextLines?.filter(it => !!it)
					.map((it, idx) => <Text key={it} style={[styles.expandedText, idx == 0 ? {marginTop: 12} : null]}>
					&#8226; {it}
				</Text>)}
		 </Animated.View>}
		</>;
	}
}

//todo need change
let w =  Platform.OS === 'web' ? 48  + 18 : 48 + 32 + 18;

const styles = StyleSheet.create({
	root: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		flexDirection: 'row',
		marginBottom: 4,
		marginTop: 4,
		marginLeft: 4,
	},
	name: {
		height: 48,
		backgroundColor: '#FFFFFF',
		elevation: 3,
		borderRadius: 10,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		width: getWidthExpression(w),
	},
	expandedBlock: {
		backgroundColor: '#FFFFFF',
		elevation: 3,
		borderRadius: 10,
		marginBottom: 2,
		marginTop: 2,
		marginLeft: 4,
		paddingLeft: 12,
		width: getWidthExpression(w),
		overflow: 'hidden',
	},
	nameText: {
		color: '#2E2D2D',
		fontWeight: '700',
		fontSize: 12,
		marginLeft: 16
	},
	expandImage: {
		marginLeft: 'auto',
		marginRight: 22,
		width: 12,
		height: 8
	},
	point: {
		height: 48,
		width: 48,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#FFFFFF',
		elevation: 3,
		borderRadius: 10,
		marginLeft: 'auto'
	},
	pointText: {
		color: '#2E2D2D',
		fontWeight: '700',
		fontSize: 14,
	},
	coefText: {
		position: 'absolute',
		top: -3,
		right: 0,
		fontSize: 10,
		borderRadius: 20,
		paddingLeft: 4,
		paddingRight: 4,
		backgroundColor: ORANGE_COLOR,
		color: '#FFFFFF',
		fontWeight: '700',
	},
	expandedText: {
		color: '#2E2D2D',
		fontWeight: '500',
		fontSize: 12,
	},
});
