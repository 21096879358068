import React, {createRef, useEffect, useState} from 'react';
import {StyleSheet, View, Text, ScrollView, Dimensions, Image} from 'react-native';
import {BattleStatus, Fight} from "../types";
import {ServerAPI} from "../ServerAPI";
import TRANSLATES from "../translates/translates";

import {getData, getHeightExpression, saveData, UserContext} from "../func";
import {COLORS, RED} from "../colors";
import TPressable from "../controls/TPressable";
import BoardStep from "../controls/BoardStep";
import {OnBoardingStyles} from "../Onboarding";
import GFightBattle from "./GFightBattle";
import {MMABattleResponse, PunchesStat} from "./types";
import FormattedRoundTime from './FormattedRoundTime';
import PositionChart from "./PositionChart";
import ControlItemLine from "./ControlItemLine";
import {GradientButton} from "../controls/GradientButton";
import {CompareOneValue} from "./CompareOneValue";
import GFightBattleByRound from "./GFightBattleByRound";

interface IProps {
    fight: Fight,
    openCourseDialog: () => void
}


const ONBoardSteps = [
    'LiveStatTable_OnBoard1',
    'LiveStatTable_OnBoard2',
    'LiveStatTable_OnBoard3',
    'LiveStatTable_OnBoard4',
    'LiveStatTable_OnBoard5',
    'LiveStatTable_OnBoard6',
    'LiveStatTable_OnBoard7'
]

const StepsScroll = new Map<number, number>();
StepsScroll.set(1, 0);
StepsScroll.set(2, 0);
StepsScroll.set(3, 0);
StepsScroll.set(4, 290);
StepsScroll.set(5, 600);
StepsScroll.set(6, 700);
StepsScroll.set(7, 700);

let timeId: any;

export default function LiveStatTable(props: IProps) {

    let scrollViewRef: any = createRef()


    const [onboardStep, setOnboardStep] = useState<number | null>(null)
    const [currentRound, setCurrentRound] = useState<number | null>(-1)
    const [stat, setStat] = useState<MMABattleResponse | null>(null)
    const [scrollValue, setScrollValue] = useState<number>(0)

    const updateOnBoarding = async () => {
        let res = await getData('OnBoardingDone_LiveStatTable');

        setOnboardStep(res ? null : 1)
    }

    useEffect(() => {
        updateOnBoarding()
    }, [])

    useEffect(() => {
        refresh()

        return () => {
            clearTimeout(timeId)
        }
    }, [props.fight.id, currentRound])


    const refresh = async () => {
        let rndParam = currentRound ? `round=${currentRound}` : ""

        let data = await ServerAPI.get<MMABattleResponse>(`/liveStat/${props.fight.id}?online=true&${rndParam}`)

        if (data.battle.GloryFightID != props.fight.id)
            return
        setStat(data)


        if (currentRound == -1) {
            if (data.battle.Status == BattleStatus.Finished)
                setCurrentRound(null)
        }

        if (data.battle.Status != BattleStatus.Finished)
            timeId = setTimeout(() => refresh(), 1500)
    }

    const getTotalStrikes = (pstat: PunchesStat) => {
        return pstat.head.total + pstat.torso.total + pstat.legs.total
    }

    const getLandedStrikes = (pstat: PunchesStat) => {
        return pstat.head.success + pstat.torso.success + pstat.legs.success
    }

    const max = stat ? Math.max(getTotalStrikes(stat.red.punchesStat), getTotalStrikes(stat.blue.punchesStat)) : 0


    const renderControlItems = () => {

        if (!stat)
            return

        let red = stat.red.ggstat;
        let blue = stat.blue.ggstat;
        return <>
            <ControlItemLine text={TRANSLATES['ClinchControl']}
                             redSeconds={red.grappleSeconds}
                             redPercent={red.grapplePercent}
                             blueSeconds={blue.grappleSeconds}
                             bluePercent={blue.grapplePercent}
            />
            <View style={{height: 16}}></View>
            <ControlItemLine text={TRANSLATES['GroundControl']}
                             redSeconds={red.groundSeconds}
                             redPercent={red.groundPercent}
                             blueSeconds={blue.groundSeconds}
                             bluePercent={blue.groundPercent}
            />
        </>
    }


    //------------------------------------------------------------------------------------

    const renderLeftOneValue = (value: number) => {
        return <View style={{flexDirection: 'column', alignItems: 'flex-start'}}>
            <View style={{
                marginTop: 8,
                height: 4,
                width: 156,
                borderRadius: 10,
                backgroundColor: RED
            }}>
            </View>
        </View>
    }

    const renderRightOneValue = (value: number) => {
        return <View style={{flexDirection: 'column', alignItems: 'flex-end'}}>
            <View style={{
                marginTop: 8,
                height: 4,
                width: 156,
                borderRadius: 10,
                backgroundColor: COLORS.DARK_BLUE
            }}>
            </View>
        </View>
    }

    const renderOneLineItem = (text: string, redValue: number, blueValue: number) => {
        return <View style={{height: 52}}>
            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <Text style={styles.valueText}>{Math.round(redValue)}</Text>
                <Text style={[styles.subTitleText]}>{text}</Text>
                <Text style={styles.valueText}>{Math.round(blueValue)}</Text>
            </View>
            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                {renderLeftOneValue(redValue)}
                {renderRightOneValue(blueValue)}
            </View>
        </View>
    }


    const onBoardNextClick = () => {
        if (onboardStep!! == ONBoardSteps.length) {
            doneOnboarding();
        } else {
            setOnboardStep(onboardStep!! + 1)


            let scrollValue = StepsScroll.get(onboardStep!! + 1)

            setScrollValue(scrollValue || 0)

            scrollViewRef.current?.scrollTo({
                y: scrollValue,
                x: 0,
                animated: false
            });

        }
    }

    const onBoardSkipClick = () => {
        doneOnboarding()
    }

    const doneOnboarding = () => {
        scrollViewRef.current?.scrollTo({
            y: 0,
            x: 0,
            animated: false
        });
        setOnboardStep(null)
        setScrollValue(0)


        saveData('OnBoardingDone_LiveStatTable', 'done')
    }


    const onRoundClick = (rnd: number | null) => {
        if (stat?.battle.Status == BattleStatus.InProgress && rnd == null)
            rnd = -1

        setCurrentRound(rnd)
    }

    const renderRounds = () => {
        if (!stat)
            return <View/>

        let roundCount = stat.battle.Status == BattleStatus.Finished ? stat.maxRound : stat.battle.RoundCount;
        let arr = new Array(roundCount).fill(undefined);


        let rnd = currentRound == -1 ? stat.round : currentRound

        return <View>
            <FormattedRoundTime stat={stat}/>

            <View style={{flexDirection: 'row', alignItems: 'flex-start', height: 30, justifyContent: 'space-around'}}>
                {arr.map((r, idx) => <TPressable name={"round " + (idx + 1)} key={idx}
                                                 onPress={() => onRoundClick(idx + 1)}>
                    <Text style={[styles.roundItem, idx + 1 == rnd ? styles.selectedRound : null]}>
                        RD{idx + 1}
                    </Text>
                </TPressable>)}
                <TPressable name={"total"} onPress={() => onRoundClick(null)}>
                    <Text style={[styles.roundItem, rnd ? null : styles.selectedRound]}>
                        {TRANSLATES['Total']}
                    </Text>
                </TPressable>
            </View>
        </View>
    }


    if (!stat)
        return <View/>


    let boardSteps = ONBoardSteps;

    // @ts-ignore
    return (<UserContext.Consumer>
            {({user, fantasyAppFeatures}) => (
                <ScrollView style={styles.root} ref={scrollViewRef}>

                    {onboardStep != null && <View style={{
                        width: '100%',
                        height: 200,
                        top: onboardStep <= 2 ? Dimensions.get('window').height - 450 : scrollValue,
                        padding: 16,
                        position: 'absolute',
                        zIndex: 2
                    }}>
                        <BoardStep onNextClick={() => onBoardNextClick()}
                                   currentStep={onboardStep - 1}
                                   height={200}
                                   position={'top'}
                                   stepCount={boardSteps.length}
                                   text={(TRANSLATES as any)[boardSteps[onboardStep - 1]]}
                                   onSkipClick={() => onBoardSkipClick()}/>

                    </View>}

                    {renderRounds()}
                    <View style={{width: '100%', paddingLeft: 12, paddingRight: 12}}>
                        {props.fight.status != BattleStatus.Finished || currentRound != null ?
                            <GFightBattle fight={props.fight}
                                          advantage={stat?.advantage}
                                          onboardStep={onboardStep}
                                          red={props.fight.red}
                                          blue={props.fight.blue}/>
                            :

                            <GFightBattleByRound
                                maxDataRoundCount={props.fight.winRound}
                                roundCount={props.fight.winRound}
                                currentRound={currentRound}
                                fight={props.fight}
                                advantage={stat?.advantage}
                                onboardStep={onboardStep}
                                red={props.fight.red}
                                blue={props.fight.blue}/>
                        }
                    </View>

                    <View style={[styles.line, {marginBottom: 3}]}/>

                    {fantasyAppFeatures?.enableSubscription &&
                        <View style={{padding: 16}}>
                            <GradientButton onClick={props.openCourseDialog}
                                            height={35}
                                            type="hor"
                                            width={Dimensions.get('window').width - 32}>
                                <Text style={{
                                    zIndex: 1,
                                    color: '#FFFFFF',
                                    fontWeight: '700',
                                    fontSize: 15,
                                    lineHeight: 16,
                                    textTransform: 'uppercase'
                                }}>{TRANSLATES['course-fight.open']}
                                </Text>
                                <Image style={{
                                    height: 14,
                                    width: 14,
                                    marginLeft: 8
                                }} source={require('../icons/plus-more.png')}/>
                            </GradientButton>
                        </View>
                    }

                    <View style={[onboardStep == 2 && OnBoardingStyles.highlight]}>
                        <Text style={[styles.headerText, {marginBottom: 12}]}>{TRANSLATES['Position']}</Text>
                        {stat && <PositionChart
                            roundTotalTime={stat.roundTotalTime}
                            isGround={stat.isGround}
                            isGrapple={stat.isGrapple}
                            stancePercent={stat.ggstat.stancePercent}
                            grapplePercent={stat.ggstat.grapplePercent}
                            groundPercent={stat.ggstat.groundPercent}
                            grappleSeconds={stat.ggstat.grappleSeconds}
                            stanceSeconds={stat.ggstat.stanceSeconds}
                            groundSeconds={stat.ggstat.groundSeconds}
                        />}
                    </View>

                    <View style={[styles.line, {marginBottom: 3}]}/>

                    <View style={onboardStep == 3 && OnBoardingStyles.highlight}>
                        <Text style={styles.headerText}>{TRANSLATES['Control']}</Text>
                        <View style={{height: 12}}></View>
                        {renderControlItems()}
                    </View>


                    <View style={[styles.line, {marginBottom: 3}]}/>

                    <View
                        style={[(onboardStep == 4 || onboardStep == 5) && OnBoardingStyles.highlight,
                            {
                                paddingLeft: 12,
                                paddingRight: 12,
                            }
                        ]}>
                        <Text style={styles.headerText}>{TRANSLATES['Strikes']}</Text>
                        <Text style={[styles.subTitleText, {marginBottom: 16}]}>{TRANSLATES['LandedThrown']}</Text>

                        <CompareOneValue
                            text={TRANSLATES['Total']}
                            redValue={getLandedStrikes(stat.red.punchesStat)}
                            redTotal={getTotalStrikes(stat.red.punchesStat)}
                            blueValue={getLandedStrikes(stat.blue.punchesStat)}
                            blueTotal={getTotalStrikes(stat.blue.punchesStat)}
                            max={max}
                        />

                        {renderOneLineItem(
                            TRANSLATES['EfStrikes'],
                            stat.RedStat.EfStrikes,
                            stat.BlueStat.EfStrikes
                        )}
                        {renderOneLineItem(
                            TRANSLATES['StrikesPerMin'],
                            stat.RedStat.StrikesPerMin,
                            stat.BlueStat.StrikesPerMin
                        )}


                        <Text style={styles.headerText}>{TRANSLATES['byPosition']}</Text>
                        <View style={styles.v12}/>

                        <CompareOneValue
                            text={TRANSLATES['Distance']}
                            redValue={stat.red.punchesStat.stance.success}
                            redTotal={stat.red.punchesStat.stance.total}
                            blueValue={stat.blue.punchesStat.stance.success}
                            blueTotal={stat.blue.punchesStat.stance.total}
                            max={max}
                        />

                        <CompareOneValue
                            text={TRANSLATES['Clinch']}
                            redValue={stat.red.punchesStat.grapple.success}
                            redTotal={stat.red.punchesStat.grapple.total}
                            blueValue={stat.blue.punchesStat.grapple.success}
                            blueTotal={stat.blue.punchesStat.grapple.total}
                            max={max}
                        />

                        <CompareOneValue
                            text={TRANSLATES['Ground']}
                            redValue={stat.red.punchesStat.ground.success}
                            redTotal={stat.red.punchesStat.ground.total}
                            blueValue={stat.blue.punchesStat.ground.success}
                            blueTotal={stat.blue.punchesStat.ground.total}
                            max={max}
                        />

                        <Text style={styles.headerText}>{TRANSLATES['byTarget']}</Text>
                        <View style={styles.v12}/>


                        <CompareOneValue
                            text={TRANSLATES['Head']}
                            redValue={stat.red.punchesStat.head.success}
                            redTotal={stat.red.punchesStat.head.total}
                            blueValue={stat.blue.punchesStat.head.success}
                            blueTotal={stat.blue.punchesStat.head.total}
                            max={max}
                        />


                        <CompareOneValue
                            text={TRANSLATES['Body']}
                            redValue={stat.red.punchesStat.torso.success}
                            redTotal={stat.red.punchesStat.torso.total}
                            blueValue={stat.blue.punchesStat.torso.success}
                            blueTotal={stat.blue.punchesStat.torso.total}
                            max={max}
                        />

                        <CompareOneValue
                            text={TRANSLATES['Leg']}
                            redValue={stat.red.punchesStat.legs.success}
                            redTotal={stat.red.punchesStat.legs.total}
                            blueValue={stat.blue.punchesStat.legs.success}
                            blueTotal={stat.blue.punchesStat.legs.total}
                            max={max}
                        />


                        <Text style={styles.headerText}>{TRANSLATES['byType']}</Text>
                        <View style={styles.v12}/>


                        <CompareOneValue
                            text={TRANSLATES['Punches']}
                            redValue={stat.RedStat.Punches.Landed}
                            redTotal={stat.RedStat.Punches.Thrown}
                            blueValue={stat.BlueStat.Punches.Landed}
                            blueTotal={stat.BlueStat.Punches.Thrown}
                            max={max}
                        />


                        <CompareOneValue
                            text={TRANSLATES['Elbows']}
                            redValue={stat.RedStat.Elbows.Landed}
                            redTotal={stat.RedStat.Elbows.Thrown}
                            blueValue={stat.BlueStat.Elbows.Landed}
                            blueTotal={stat.BlueStat.Elbows.Thrown}
                            max={max}
                        />


                        <CompareOneValue
                            text={TRANSLATES['Kick']}
                            redValue={stat.RedStat.Kicks.Landed}
                            redTotal={stat.RedStat.Kicks.Thrown}
                            blueValue={stat.BlueStat.Kicks.Landed}
                            blueTotal={stat.BlueStat.Kicks.Thrown}
                            max={max}
                        />


                        <CompareOneValue
                            text={TRANSLATES['Knees']}
                            redValue={stat.RedStat.Knees.Landed}
                            redTotal={stat.RedStat.Knees.Thrown}
                            blueValue={stat.BlueStat.Knees.Landed}
                            blueTotal={stat.BlueStat.Knees.Thrown}
                            max={max}
                        />

                    </View>


                    <View style={[onboardStep == 6 && OnBoardingStyles.highlight, {
                        paddingLeft: 12,
                        paddingRight: 12,
                    }]}>
                        <Text style={styles.headerText}>{TRANSLATES['Takedowns']}</Text>
                        <Text
                            style={[styles.subTitleText, {marginBottom: 16}]}>{TRANSLATES['SuccessAttempts']}</Text>


                        <CompareOneValue
                            text={TRANSLATES['Total']}
                            redValue={stat.red.groundStat.takedown.success + stat.red.groundStat.transition.success}
                            redTotal={stat.red.groundStat.takedown.total + stat.red.groundStat.transition.total}
                            blueValue={stat.blue.groundStat.takedown.success + stat.blue.groundStat.transition.success}
                            blueTotal={stat.blue.groundStat.takedown.total + stat.blue.groundStat.transition.total}
                            useMax={false}
                            max={max}
                        />

                    </View>

                    <View style={[onboardStep == 7 && OnBoardingStyles.highlight, {
                        paddingLeft: 12,
                        paddingRight: 12,
                    }]}>
                        <Text style={styles.headerText}>{TRANSLATES['Submissions']}</Text>
                        <Text
                            style={[styles.subTitleText, {marginBottom: 16}]}>{TRANSLATES['SuccessAttempts']}</Text>


                        <CompareOneValue
                            text={TRANSLATES['Total']}
                            redValue={stat.red.submissionStat.success}
                            redTotal={stat.red.submissionStat.total}
                            blueValue={stat.blue.submissionStat.success}
                            blueTotal={stat.blue.submissionStat.total}
                            max={max}
                            useMax={false}
                        />
                    </View>

                    <View style={{height: 50}}/>

                </ScrollView>
            )}
        </UserContext.Consumer>
    );
}


const styles = StyleSheet.create({
    root: {
        height: getHeightExpression(100),
        overflow: "hidden",
    },
    roundItem: {
        color: '#2E2D2D',
        fontSize: 15
    },
    selectedRound: {
        fontWeight: '700'
    },
    line: {
        left: -20,
        width: Dimensions.get("window").width + 40,
        height: 1,
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: '#797B87',
        marginTop: 12,
        position: 'relative'
    },
    headerText: {
        fontSize: 15,
        fontWeight: '700',
        textAlign: 'center',
        marginTop: 12,
        color: '#2E2D2D'
    },
    v12: {
        height: 12
    },
    subTitleText: {
        fontSize: 12,
        fontWeight: '500',
        textAlign: 'center',
        color: '#797B87'
    },
    measureText: {
        color: '#797B87',
        fontSize: 12,
        fontWeight: '500',
    },
    valueText: {
        color: '#2E2D2D',
        fontSize: 12,
        fontWeight: '700',
        textAlign: 'center'
    },


    rightTextAlign: {
        textAlign: 'right',
    },
});
