import {Image, Pressable, StyleProp, StyleSheet, Text, ViewStyle} from "react-native";
import {ORANGE_COLOR} from "../colors";
import React from "react";


export function OrangeButtonWithPlusIcon(props: {
    onClick?: () => void,
    text: string,
    style?: StyleProp<ViewStyle>
}) {
    return <Pressable onPress={props.onClick} style={[styles.titleRow, props.style]}>
        <Text style={styles.text}>{props.text}</Text>
        <Image style={styles.img} source={require('../icons/plus-more.png')}/>
    </Pressable>
}

export const styles = StyleSheet.create({
    titleRow: {
        borderRadius: 30,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 10,
        gap: 8,
        paddingBottom: 10,
        flexDirection: 'row',
        backgroundColor: ORANGE_COLOR,
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'uppercase'
    },
    text: {
        color: '#FFFFFF',
        fontWeight: '700',
        fontSize: 15
    },
    img: {
        height: 14,
        width: 14
    },
})
