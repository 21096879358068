import React from 'react';
import { View } from 'react-native';
import WebView from 'react-native-webview';
//import WebView from "react-native-webview";
//import {Platform, View} from "react-native";




export default class MultiWebView extends React.Component<{url: string, style?: any}, any> {

	constructor(props: any)
	{
		super(props);
		this.state = {};
	}


	render()
	{
		return <iframe src={this.props.url} style={this.props.style} frameBorder='none'/>
	}
}

