import React from 'react';
import {Dimensions, Image, Modal, Pressable, StyleSheet, Text, View} from 'react-native';
import TPressable from "./TPressable";

interface IProps {
	visible: boolean,
	children: any,
	title?: string,
	onCloseClick: Function,
	height?: number,
	titleVisible?: boolean
	padding?: number,
	backgroundColor?: string
	overlayHeight?: number
}

interface IState {

}

export default class BottomModal extends React.Component<IProps, IState> {

	constructor(props: IProps)
	{
		super(props);
	}

	render()
	{
		let height = Dimensions.get('window').height - (this.props.height || 550);
		if (this.props.overlayHeight)
			height = this.props.overlayHeight;

		return <Modal
			 animationType="slide"
			 transparent={true}
			 visible={this.props.visible}
		>
			<View style={styles.overlay}>
				<Pressable style={{height: height}} onPress={() => this.props.onCloseClick()}/>
				<View style={{...styles.content,
					backgroundColor: this.props.backgroundColor || '#fff',
					height: this.props.height || 550,
					padding: this.props.padding == undefined ? 16 : this.props.padding}}>
					{this.props.titleVisible !== false &&
				   <View style={[styles.title, this.props.padding === 0 && styles.titlePadding]}>
					   <Text style={styles.titleText}>{this.props.title}</Text>
					   <TPressable name={"closeModal"} onPress={() => this.props.onCloseClick()}
									  style={[styles.closeBtn, { right: this.props.padding === 0 ? 8 : -6}]}>
						   <Image style={{width: 10, height: 10}} source={require('../icons/close.png')}/>
					   </TPressable>
				   </View>
					}
					{this.props.children}
				</View>
			</View>
		</Modal>;
	}
}


const styles = StyleSheet.create({
	titlePadding: {
		paddingRight: 16,
		marginTop: 16,
		paddingLeft: 16
	},
	title: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center'
	},
	closeBtn: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute',
		width: 30,
		height: 30
	},
	titleText: {
		color: '#2E2D2D',
		fontWeight: '700',
		fontSize: 15
	},
	content: {
		borderTopRightRadius: 15,
		borderTopLeftRadius: 15,
		marginTop: 'auto'
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.2)',
		flex: 1,
		justifyContent: 'center',
	}
});
