import {Image, Modal, Pressable, StyleSheet, Text, View} from "react-native";
import React, {useEffect} from "react";
import {getWidthExpression} from "../func";

let timeId: any = null

export function ToolTipBody(props: {
    children?: React.ReactNode,
    style?: any,
    message?: string
}) {
    return <View style={[styles.modalView, props.style, {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: 10,
    }]}>
        <Image source={require('../assets/logo_2.png')} style={{width: 24, height: 22}}/>
        {props.message && <Text style={styles.modalText}>{props.message}</Text>}
        {props.children}
    </View>
}


export default function MessageToolTip(props: {
    message?: string,
    children?: React.ReactNode,
    onClose?: () => void,
    top?: number
}) {

    const [visible, setVisible] = React.useState(true)

    useEffect(() => {
        if (timeId)
            clearTimeout(timeId)
        timeId = setTimeout(() => props.onClose && props.onClose(), 5000)
        return () => {
            if (timeId)
                clearTimeout(timeId)
            timeId = null
        }
    }, [props.message])

    return <Modal
        animationType="fade"
        transparent={true}
        visible={visible}
        onRequestClose={() => {
            if (timeId)
                clearTimeout(timeId)
            if (props.onClose)
                props.onClose()
        }}>
        <Pressable style={styles.centeredView}
                   onPress={() => {
                       setVisible(false)
                       if (props.onClose)
                       props.onClose()
                   }}>
            <View style={{
                position: 'absolute',
                top: props.top || 250,
                width: getWidthExpression(100),
            }}>
                <ToolTipBody message={props.message}>
                    {props.children}
                </ToolTipBody>
            </View>
        </Pressable>
    </Modal>
}

const styles = StyleSheet.create({
    modalText: {
        textAlign: 'center',
        color: '#fff',
        fontSize: 15,
        fontWeight: '700',
        zIndex: 1,
        marginTop: 12
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        overflow: "hidden",
        position: 'relative',
        borderRadius: 10,
        padding: 24,
        alignItems: 'center',
        minHeight: 150
    },
})