import {Dimensions, StyleSheet, Text, View} from "react-native";
import TRANSLATES from "../translates/translates";
import {secondsToTime} from "../func";
import React from "react";

interface IProps
{
    roundTotalTime: number
    isGround: boolean
    isGrapple: boolean
    stancePercent: number,
    grapplePercent: number,
    groundPercent: number,
    grappleSeconds: number,
    stanceSeconds: number,
    groundSeconds: number
}

export default function PositionChart(props: IProps) {


    let size = Dimensions.get('window').width;


    let arr = [{name: 'stance', value: props.stancePercent, size: 0},
        {name: 'grapple', value: props.grapplePercent, size: 0},
        {name: 'ground', value: props.groundPercent, size: 0}].sort((a, b) => {
        return a.value - b.value;
    })

    arr.forEach(it => {
        it.size = size * it.value / 100
        if (it.size < 68 && it.size > 0)
            it.size = 68
    })

    let sum = arr[0].size + arr[1].size + arr[2].size;

    if (sum > size)
        arr[2].size = size - arr[0].size - arr[1].size


    let grappleWidth = arr.find(it => it.name == 'grapple')!!.size
    let groundWidth = arr.find(it => it.name == 'ground')!!.size
    let distanceWidth = arr.find(it => it.name == 'stance')!!.size


    return <View style={[{flexDirection: 'column', width: '100%'}]}>

        <View style={{flexDirection: 'row', width: '100%'}}>

            {props.stancePercent > 0 &&
                <View style={{
                    width: props.stancePercent + '%',
                    backgroundColor: '#A6A9C0',
                    alignItems: 'center',
                    height: 24,
                    justifyContent: 'center'
                }}>
                </View>
            }

            {props.grapplePercent > 0 &&

                <View style={{
                    width: props.grapplePercent + '%',
                    backgroundColor: '#CACDDE',
                    alignItems: 'center',
                    height: 24,
                    justifyContent: 'center'
                }}>
                </View>
            }
            {props.groundPercent > 0 &&

                <View style={{
                    width: props.groundPercent + '%',
                    backgroundColor: '#E5E6EB',
                    alignItems: 'center',
                    height: 24,
                    justifyContent: 'center'
                }}>

                </View>
            }
        </View>

        <View style={{flexDirection: 'row', width: '100%', marginTop: 8}}>

            {props.stancePercent > 0 &&
                <View style={{
                    alignItems: 'center',
                    width: distanceWidth
                }}>
                    <Text style={{
                        color: '#797B87',
                        fontWeight: props.roundTotalTime && !props.isGround && !props.isGrapple ? '700' : '400'
                    }}>{TRANSLATES['Distance']}</Text>
                    <Text style={styles.measureText} numberOfLines={1}>
                        {secondsToTime(props.stanceSeconds)}
                        <Text style={[styles.valueText, {fontSize: 12}]}> {props.stancePercent}%</Text>
                    </Text>
                </View>
            }

            {props.grapplePercent > 0 &&
                <View style={{
                    alignItems: 'center',
                    width: grappleWidth
                }}>
                    <Text style={{
                        color: '#797B87',
                        fontWeight: !props.isGround && props.isGrapple ? '700' : '400'
                    }}>{TRANSLATES['Clinch']}</Text>
                    <Text style={styles.measureText} numberOfLines={1}>
                        {secondsToTime(props.grappleSeconds)}
                        <Text style={[styles.valueText, {fontSize: 12}]}> {props.grapplePercent}%</Text>
                    </Text>
                </View>
            }
            {props.groundPercent > 0 &&
                <View style={{
                    alignItems: 'center',
                    width: groundWidth
                }}>
                    <Text style={{
                        color: '#797B87',
                        fontWeight: props.isGround ? '700' : '400'
                    }}>{TRANSLATES['Ground']}</Text>
                    <Text style={styles.measureText} numberOfLines={1}>{secondsToTime(props.groundSeconds)}
                        <Text style={[styles.valueText, {fontSize: 12}]}> {props.groundPercent}%</Text></Text>
                </View>
            }
        </View>
    </View>
}

const styles = StyleSheet.create({
    measureText: {
        color: '#797B87',
        fontSize: 12,
        fontWeight: '500'
    },
    valueText: {
        color: '#2E2D2D',
        fontSize: 12,
        fontWeight: '700',
        width: 50,
        textAlign: 'center'
    },
})
