import {SafeAreaView, Text, StyleSheet, View, ScrollView, Dimensions} from 'react-native';

import React from 'react';
import {DistributionPoint, FantasyInfo, FantasyRateRow, Fighter, UserCard} from '../types';
import TRANSLATES from '../translates/translates';
import FButton from '../controls/FButton';
import CheckBox from './CheckBox';
import {MAIN_BAR_COLOR} from '../colors';
import FighterAppBar from './FighterAppBar';
import {getData, getHeightExpression, saveData} from '../func';
import StepProgress from '../controls/StepProgress';
import {ServerAPI} from "../ServerAPI";
import BoardStep from "../controls/BoardStep";
import {CardList} from "../shop/CardShop";


interface IProps {
	athlete : Fighter;
	fantasy : FantasyInfo;
	fantasyRateRow : FantasyRateRow;
	onBackClick: Function
	onNextStepClick: Function
	userCards: UserCard[]
}

interface IState {
	allPoints: DistributionPoint[];
	fantasyRateRow: FantasyRateRow,
	athlete: Fighter,
	fantasy: FantasyInfo,
	userCards: UserCard[],
	onboardStep: number | null
}

const ONBoardSteps = [
	'SelectFighter2_OnBoard1',
	'SelectFighter2_OnBoard2',
	'SelectFighter2_OnBoard3',
	'SelectFighter2_OnBoard4',
	'SelectFighter2_OnBoard5'
]


export default class SelectFighter2 extends React.Component<IProps, IState> {

	constructor(props: IProps)
	{
		super(props);
		this.state = {
			onboardStep: null,
			userCards: props.userCards,
			fantasyRateRow: props.fantasyRateRow,
			athlete: props.athlete,
			fantasy: props.fantasy,
			allPoints: []
		};
	}


	componentDidMount()
	{
		this.refresh();

		this.updateOnBoarding();
	}

	getOnBoardSteps()
	{
		return ONBoardSteps;
	}

	onBoardNextClick()
	{
		if (this.state.onboardStep!! + 1 == this.getOnBoardSteps().length)
			this.doneOnboarding()
		else
			this.setState({onboardStep: this.state.onboardStep!! + 1})
	}

	onBoardSkipClick()
	{
		this.doneOnboarding()
	}

	doneOnboarding()
	{
		this.setState({onboardStep: null})

		saveData('OnBoardingDone_SelectFighter2', 'done')
	}


	async updateOnBoarding()
	{
		let res = await getData('OnBoardingDone_SelectFighter2');

		if (!res)
			this.setState({
				onboardStep: 0
			})
	}

	async refresh()
	{
		let allPoints = await ServerAPI.getDistributionPoints();
		this.setState({
			allPoints: allPoints
		});
	}

	isPointSelected(item: DistributionPoint)
	{
		return !!this.state.fantasyRateRow.AdPoints?.find(it => it.Id == item.Id);
	}

	onPointSelected(item: DistributionPoint)
	{
		let isSelected = this.isPointSelected(item);
		if (!isSelected)
		{

			this.setState({
				fantasyRateRow: {
					...this.state.fantasyRateRow,
					AdPoints: [...this.state.fantasyRateRow.AdPoints, item],

				}
			});
		}
		else
		{
			let newSelectedCards = this.state.fantasyRateRow.SelectedUserCards
				 .map(it => this.state.userCards.find(c => c.id == it))
				 .filter(it =>it && it.fantasyPointId != item.Key)
				 .map(it => it!!.id);


			this.setState({
				fantasyRateRow: {
					...this.state.fantasyRateRow,
					AdPoints: this.state.fantasyRateRow.AdPoints.filter(it => it.Name != item.Name),
					SelectedUserCards: newSelectedCards
				}
			});
		}
	}

	onCardSelected(userCard: UserCard)
	{
		let isSelected = !!this.state.fantasyRateRow.SelectedUserCards?.find(it => it == userCard.id);
		if (!isSelected)
		{
			this.setState({
				fantasyRateRow: {
					...this.state.fantasyRateRow,
					SelectedUserCards: [...this.state.fantasyRateRow.SelectedUserCards, userCard.id],
				},
			});
		}
		else
		{
			this.setState({
				fantasyRateRow: {
					...this.state.fantasyRateRow,
					SelectedUserCards: this.state.fantasyRateRow.SelectedUserCards.filter(it => it != userCard.id),
				},
			});
		}
	}

	onNextClick()
	{
		this.props.onNextStepClick(this.state.fantasyRateRow);
	}

	onBackClick()
	{
		this.props.onBackClick();
	}

	render()
	{
		let firstList = this.state.allPoints.filter(it => it.Type == 'Additional2' && it.Points > 0);
		let selectedPoints = firstList.filter(p => this.state.fantasyRateRow.AdPoints.find(it => it.Key == p.Key));

		let userCards = this.state.userCards;
		let selectedCount = selectedPoints.length;

		let boardSteps = this.getOnBoardSteps()

		return (
			 <SafeAreaView style={styles.root}>

				 {this.state.athlete && <FighterAppBar fighter={this.state.athlete} fantasy={this.state.fantasy}/>}

				 <StepProgress current={1} count={5}/>

				 {this.state.onboardStep != null && <View style={{
					 width: '100%',
					 height: '100%',
					 padding: 16,
					 position: 'absolute',
					 zIndex: 2
				 }}>
				 <BoardStep onNextClick={() => this.onBoardNextClick()}
							currentStep={this.state.onboardStep}
							position={this.state.onboardStep > 0 && Dimensions.get('window').height < 700 ? 'top' : 'bottom'}
							stepCount={boardSteps.length}
							text={(TRANSLATES as any)[boardSteps[this.state.onboardStep]]}
							onSkipClick={() => this.onBoardSkipClick()}/>

			 </View>}

				 <ScrollView style={styles.container}>

					 <Text style={styles.chooseText}>
						 {TRANSLATES['Point2Title']}
					 </Text>

					 {firstList.map(p => <CheckBox
						  key={p.Id}
						  cardCoefText={this.state.userCards.find(it => it.fantasyPointId == p.Key)?.statusCoef}
						  onPress={() => this.onPointSelected(p)}
						  selected={this.isPointSelected(p)}
						  tooltip={TRANSLATES[`${p.Id}.desc`] || p.Description}
						  enabled={this.isPointSelected(p) || selectedCount < 2}
						  mainText={TRANSLATES[p.Id] || p.Name}
						  points={p.Points}
					 />)}

					 <Text style={{marginTop: 16, textAlign: 'center', color: '#797B87', paddingLeft: 16, paddingRight: 16}}>
						 {TRANSLATES['Point2Desc']}
					 </Text>

					 <CardList
						  showText={true}
						  showShop={true}
						  onCardListChanged={() => this.refresh()}
						  selectedList={userCards.filter(it => this.state.fantasyRateRow.SelectedUserCards.find(id => it.id == id))}
						  enabledList={userCards.filter(it => selectedPoints.find(sp => sp.Key == it.fantasyPointId))}
						  onCardSelected={(uc: UserCard) => this.onCardSelected(uc)}
						  list={this.state.userCards.filter(it => it.exDescData == 'Additional2')}/>


				 </ScrollView>

				 <View style={styles.buttons}>

					 <FButton text={TRANSLATES['Back']} width={160} onClick={() => this.onBackClick()}/>
					 <FButton text={TRANSLATES['Next']} width={160} onClick={() => this.onNextClick()}
								 enabled={selectedCount == 2}
								 backgroundColor={MAIN_BAR_COLOR}
								 color="#FFFFFF"/>
				 </View>



			 </SafeAreaView>);
	}
}



const styles = StyleSheet.create({
	chooseText: {
		fontWeight: '700',
		fontSize: 15,
		color: '#2E2D2D',
		marginBottom: 16
	},
	container: {
		backgroundColor: '#EFF1F7',
		paddingLeft: 16,
		paddingRight: 16
	},
	root: {
		height: getHeightExpression(0),
	},
	buttons: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 16
	},
});
