import {Image, ImageBackground, SafeAreaView, StyleSheet, Text, View} from 'react-native';
import React from 'react';
import {FantasyInfo, Fighter} from '../types';
import {MAIN_BAR_COLOR} from '../colors';
import TRANSLATES from '../translates/translates';
import {getWidthExpression} from '../func';
import TPressable from "../controls/TPressable";


interface IProps {
	fighter: Fighter,
	fantasy: FantasyInfo,
	onBackClick?: Function
}

interface IState {


}

export default class FighterAppBar extends React.Component<IProps, IState> {


	constructor(props: IProps)
	{
		super(props);
		this.state = {};
	}

	getAthleteImage()
	{
		let f = this.props.fighter;
		let source = (f.avatarUrl || f.photoUrl) ? {uri: f.avatarUrl || f.photoUrl} : require('../assets/athlete_shadow.png')
		return <View style={{backgroundColor: '#EFF1F6', borderRadius: 50}}>
			<ImageBackground source={source} resizeMode="contain" style={styles.fighterImage}/>
		</View>
	}

	renderRating(a: Fighter)
	{
		return <View style={[styles.ratingView]}>
			<Image source={require('../assets/rating.png')} style={[styles.ratingImg, {marginRight: 4}]}/>
			<Text style={styles.ratingText}>{a.rating.total || a.rating.totalRecord}</Text>
		</View>
	}

	render()
	{
		let flagUrl = this.props.fighter.countryISO && `https://storage.yandexcloud.net/bd-sport-images/country/${this.props.fighter.countryISO}.png`;
		return (
			 <SafeAreaView style={styles.root}>

				 {this.props.onBackClick ?
					  <View style={{justifyContent: 'center', alignItems: 'center', flexDirection: 'row', height: 44}}>

						  <TPressable name="fighterAppBarBack" onPress={() => this.props.onBackClick!!()}
										 style={{width: 24, position: 'absolute', left: 0}}>
							  <Image source={require('../icons/arrow-left-2.png')} style={{width: 24, height: 24}}/>
						  </TPressable>

						  <Text style={styles.titleText}>{TRANSLATES['MakePicks']}</Text>
					  </View>
					  :
					  <View style={{justifyContent: 'center', alignItems: 'center', flexDirection: 'row', height: 44}}>
						  <Text style={styles.titleText}>{TRANSLATES['MakePicks']}</Text>
					  </View>
				 }

				 <View style={styles.container}>

					 <View>
						 {this.getAthleteImage()}
						 {flagUrl && <Image source={{uri: flagUrl}} style={styles.flag}/>}
					 </View>
					 <View style={styles.fighterName}>


						 <Text style={styles.fighterNameText} numberOfLines={2}>
							 {this.props.fighter.firstName} {this.props.fighter.lastName}
						 </Text>

						 <View style={{flexDirection: 'row', alignItems: 'center'}}>
							 <Text style={styles.eventNameText} numberOfLines={2}>
								 {this.props.fantasy.EventName}
							 </Text>
							 {this.renderRating(this.props.fighter)}
						 </View>

					 </View>
				 </View>
				 <View style={styles.bottom}/>
			 </SafeAreaView>

		);
	}
}

export const FighterAppBarHeight = 150;


const styles = StyleSheet.create({
	bottom: {
		position: 'absolute',
		bottom: -30,
		left: 0,
		width: getWidthExpression(0),
		backgroundColor: '#EFF1F7',
		borderRadius: 30,
		height: 60
	},
	fighter: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	flag: {
		width: 18,
		height: 10,
		position: 'absolute',
		right: 5,
		bottom: 5,
	},
	fighterName: {
		marginLeft: 16,
		width: getWidthExpression(150),
	},
	fighterNameText: {
		color: 'white',
		fontWeight: '700',
		fontSize: 20,
	},
	eventNameText: {
		color: 'white',
		fontWeight: '700',
		fontSize: 15
	},
	titleText: {
		fontSize: 15,
		fontWeight: '700',
		color: 'white',
		textAlign: 'center'
	},
	container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	root: {
		backgroundColor: MAIN_BAR_COLOR,
		borderTopRightRadius: 10,
		borderTopLeftRadius: 10,
		height: FighterAppBarHeight,
		paddingLeft: 18,
	},
	fighterImage: {
		width: 80,
		height: 80
	},
	ratingView: {
		width: 42,
		height: 20,
		flexDirection: 'row',
		backgroundColor: '#FFDAA5',
		borderRadius: 20,
		paddingLeft: 6,
		paddingRight: 6,
		alignItems: 'center',
		justifyContent: 'center',
		marginLeft: 'auto'
	},
	ratingImg: {
		width: 12,
		height: 12,
	},
	ratingText: {
		color: '#2E2D2D',
		fontWeight: '700',
		fontSize: 10
	},
	nick_rating: {
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 4
	}
});



