import {
    StyleSheet,
    View,
    Text,
    Pressable,
    TextInput,
    Image,
    Platform,
    ImageBackground,
    ScrollView,
    Dimensions, Alert, Switch, AppState
} from 'react-native';
import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {EMPTY_EMAIL, User, UserStat} from '../types';
import {getDeviceInfo, ServerAPI} from '../ServerAPI';
import UserAppBar, {TopUserPanelHeight} from '../UserAppBar';
import TRANSLATES, {getCurrentLang} from "../translates/translates";
import BottomModal from "../controls/BottomModal";
import SignForm from "../auth/SignForm";
import Tabs from "./Tabs";
import ScrollViewRefresh from "../controls/ScrollViewRefresh";
import FButton from "../controls/FButton";
import {deleteData, SESSION_TOKEN, UserContext} from "../func";
import SInput from "../controls/SInput";
import {COLORS, MAIN_BAR_COLOR, ORANGE_COLOR} from "../colors";
import InputWithLabel from "../controls/InputWithLabel";

import DocumentPicker, {DocumentPickerResponse} from 'react-native-document-picker'
import CircularProgress from "../controls/CircularProgress";
import MultiWebView from "../controls/MultiWebView";
import TPressable from "../controls/TPressable";
import {cancelSubscription, hideBanner, subscribe} from "../ads";
import {OrangeButtonWithPlusIcon} from "../controls/OrangeButtonWithPlusIcon";
import SubDetails from "./SubDetails";
import AchievementView from "./AchievementView";


interface IProps {
    navigation?: any;
    route?: any
}


export default function ProfilePage(props: IProps) {


    const {user, setUser, fantasyAppFeatures} = useContext(UserContext);

    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [loginVisible, setLoginVisible] = useState<boolean>(false);
    const [signUpVisible, setSignUpVisible] = useState<boolean>(false);
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [signText, setSignText] = useState<string>('');
    //const [stat, setStat] = useState<UserStat | null>(null);
    const [errorText, setErrorText] = useState<string>('');
    const [feedback, setFeedback] = useState<{ title: string, desc: string } | null>(null);
    const [showBottomMessage, setShowBottomMessage] = useState<string>('');
    const [canEditEmail, setCanEditEmail] = useState<boolean>(false);
    const [newProfile, setNewProfile] = useState<{ email: string, username: string, avatarUrl: string } | null>(null);
    const [avatarLoading, setAvatarLoading] = useState<boolean>(false);
    const [webViewPage, setWebViewPage] = useState<{ title: string, url: string } | null>(null);
    const [subscriptionVisible, setSubscriptionVisible] = useState<boolean>(false);
    const [subscriptionEnabled, setSubscriptionEnabled] = useState<boolean>(user?.HasSubscription);
    const [showDetailsSubscription, setShowDetailsSubscription] = useState<boolean>(false);
    const [showCancelSubscription, setShowCancelSubscription] = useState<boolean>(false);

    const [subscriptionEnabledClicked, setSubscriptionEnabledClicked] = useState<boolean>(false);

    async function logoutClick() {
        await ServerAPI.logout();
        deleteData(SESSION_TOKEN);
        setUser({} as User);
        //setStat(null)
        setSubscriptionEnabled(false)
    }


    const onPageFocus = () => {
        ServerAPI.track('Profile')
        hideBanner();
        setShowCancelSubscription(false)
        refresh()
    }

    useEffect(() => {
        refresh();
        props.navigation.addListener('focus', onPageFocus);

        const subscription = AppState.addEventListener('change', nextAppState => {
            if (nextAppState === 'active') {
                setShowCancelSubscription(false)
                refresh()
            }
        });

        if (props.route.params?.navName == 'SignUp')
            setSignUpVisible(true)

        return () => {
            if (subscription.remove)
                subscription.remove();
            if (props.navigation.removeEventListener)
                props.navigation.removeEventListener(onPageFocus)
        };
    }, []);


    useEffect(() => {

        if (props.route.params?.subscription) {
            setSelectedIndex(1)
            setSubscriptionVisible(true)
            setShowDetailsSubscription(true)
        }

    }, [props.route.params?.subscription])

    async function refresh() {
        if (ServerAPI.hasAuth()) {
            // let res = await ServerAPI.get<UserStat>('/fantasy/user-stat');
            // setStat(res)

            let up = await ServerAPI.get<User>("/fantasy/getProfile")

            if (up) {
                setUser(up)
                setSubscriptionEnabled(up.HasSubscription)
            }
            else
                setSubscriptionEnabled(false)
        }
    }


    function renderLogin(user: User) {
        if (loginVisible && !user?.Email)
            return <BottomModal visible={true}
                                title={signText || TRANSLATES['SignIn']}
                                height={400}
                                onCloseClick={() => {
                                    setLoginVisible(false)
                                    setSignText('')
                                }}

            >
                <SignForm onTitleChanged={(text: string) => setSignText(text)}
                          doneCallback={() => {
                              refresh();
                              setLoginVisible(false)
                              setSelectedIndex(0)
                              setSignText('')
                          }}/>
            </BottomModal>

        return signUpVisible && (!user.Email || user.Email?.startsWith(EMPTY_EMAIL)) &&
            <BottomModal visible={true}
                         title={signText || TRANSLATES['SignUp']}
                         height={400}
                         onCloseClick={() => {
                             setSignUpVisible(false)
                             setSignText('')
                         }}

            >
                <SignForm isSignIn={false}
                          currentEmail={user.Email}
                          onTitleChanged={(text: string) => setSignText(text)}
                          doneCallback={() => {
                              refresh();
                              setLoginVisible(false)
                              setSelectedIndex(0)
                              setSignText('')
                          }}/>
            </BottomModal>

    }

    function renderMyDataValue(text: string, value: number) {
        return <View style={{flexDirection: 'row', height: 48, justifyContent: 'center'}}>
            <Text style={styles.firstText}>{text}</Text>
            <Text style={[styles.firstText, {marginLeft: 'auto'}]}>{value}</Text>
        </View>
    }

    function getTabContentHeight() {
        return Dimensions.get('window').height - TopUserPanelHeight - 100
    }

    // function renderMyData() {
    //
    //     if (!stat)
    //         return;
    //
    //     return <ScrollView style={{
    //         marginLeft: 16, marginRight: 16, marginTop: 36,
    //         height: getTabContentHeight()
    //     }}>
    //         {renderMyDataValue(TRANSLATES['NumberEvents'], stat.numberEvents)}
    //         {renderMyDataValue(TRANSLATES['NumberCoins'], user.Coins)}
    //         {renderMyDataValue(TRANSLATES['HighestPlace'], stat.highestPlace)}
    //         {renderMyDataValue(TRANSLATES['AveragePlace'], stat.averagePlace)}
    //         {renderMyDataValue(TRANSLATES['AverageNumberCoins'], stat.averageNumberCoins)}
    //
    //
    //     </ScrollView>
    // }

    function renderSettingText(rk: string, onClick?: Function) {
        return <TPressable name={rk} onPress={() => onClick && onClick()} style={{
            flexDirection: 'row', height: 48,
            alignItems: 'center',
            justifyContent: 'flex-start', borderBottomWidth: 1,
            borderBottomColor: '#CACDDE', borderStyle: "solid", width: '100%'
        }}>
            <Text style={styles.firstText}>{(TRANSLATES as any)[rk]}</Text>
        </TPressable>
    }

    function onEditProfileEditClick() {
        if (!user?.Email) {
            setLoginVisible(true)
            return;
        }

        let email = user.Email;

        if (email?.startsWith(EMPTY_EMAIL))
        {
            setSignUpVisible(true)
            return
        }

        setNewProfile({
            email: email,
            username: user.FirstName,
            avatarUrl: user.AvatarUrl
        })

        setCanEditEmail(!email)

    }

    function renderSettingTab() {
        return <ScrollView style={{marginLeft: 16, marginRight: 16, marginTop: 36, height: getTabContentHeight()}}>
            {renderSettingText('Profile', () => onEditProfileEditClick())}

            {fantasyAppFeatures?.enableSubscription && renderSettingText('Subscription', () => setSubscriptionVisible(true))}

            {user?.Email && renderSettingText('Feedback', () => setFeedback({
                desc: '',
                title: ''
            }))}


            {renderSettingText('TermService', () => setWebViewPage({
                title: TRANSLATES['TermService'],
                url: 'https://platform.bd-sport.com/fantasy-terms'
            }))}

            {renderSettingText('PrivacyPolicy', () => setWebViewPage({
                title: TRANSLATES['PrivacyPolicy'],
                url: 'https://platform.bd-sport.com/fantasy-privacy-notice'
            }))}

            {user?.Email && renderSettingText('DeleteAccount', () => showConfirmDeleteMeDialog())}

            {user?.Email && <FButton text={"Logout"} onClick={() => logoutClick()}/>}

            <View style={{height: 50}}></View>
        </ScrollView>
    }

    function showConfirmDeleteMeDialog() {
        return Alert.alert(
            TRANSLATES['Are your sure'],
            TRANSLATES['DeleteMeConfirm'],
            [
                {
                    text: "Yes",
                    onPress: () => {
                        onDeleteMe();
                    },
                },
                {
                    text: "No",
                },
            ]
        );
    }

    async function onDeleteMe() {
        await ServerAPI.post<UserStat>('/fantasy/deleteMe');

        ServerAPI.clearToken();
        deleteData(SESSION_TOKEN);
        setUser({} as User);
        //setStat(null)
    }

    function renderFeedback() {
        return <BottomModal visible={true}
                            title={TRANSLATES['Feedback']}
                            height={318}
                            onCloseClick={() => setFeedback(null)}

        >

            <SInput value={feedback?.title || ''}
                    placeHolder={TRANSLATES['Title']}
                    style={{width: '100%', marginTop: 12}}
                    onChangeText={(e: string) => setFeedback({
                        desc: feedback?.desc || '',
                        title: e
                    })}/>

            <TextInput style={[styles.descFeedbackInput]}
                       value={feedback?.desc || ''}
                       multiline={true}


                       placeholderTextColor="#A6A9C0"
                       placeholder={TRANSLATES['Details']}
                       onChangeText={(e) => setFeedback({
                           desc: e,
                           title: feedback?.title || ''
                       })}/>

            <FButton margin={0} text={TRANSLATES['Submit']} width="100%" onClick={() => submitFeedback()}
                     backgroundColor={MAIN_BAR_COLOR}
                     color="#FFFFFF"/>

        </BottomModal>
    }


    function renderSubscription() {

        if (showCancelSubscription)
            return renderCancelSubscription()

        if (showDetailsSubscription)
            return <SubDetails onClose={() => setShowDetailsSubscription(false)} onSubscribeClick={() => {
                ServerAPI.track('sub_buy')
                onSubscribeEnabledClick()
            }}/>

        return <BottomModal visible={true}
                            title={TRANSLATES['Subscription']}
                            height={170}
                            onCloseClick={() => setSubscriptionVisible(false)}>

            <View style={{display: 'flex', flexDirection: 'row', marginTop: 16}}>
                <Text style={{
                    fontSize: 15,
                    fontWeight: '700',
                    color: COLORS.TEXT_COLOR
                }}>{TRANSLATES['StatsFightPlus']}</Text>
                <Switch
                    style={{marginLeft: 'auto'}}
                    trackColor={{false: '#eee', true: ORANGE_COLOR}}
                    thumbColor={'#fff'}
                    {...Platform.select({
                        web: {
                            activeThumbColor: "white"
                        }
                    })}
                    ios_backgroundColor={ORANGE_COLOR}
                    onValueChange={(value) => {
                        if (value) {
                            onSubscribeEnabledClick()
                        } else {
                            setShowCancelSubscription(true)
                        }
                    }}
                    value={subscriptionEnabled}
                />
            </View>

            <View style={{marginTop: 'auto'}}>
                <OrangeButtonWithPlusIcon text={TRANSLATES['MoreDetails']}
                                          onClick={() => {
                                              ServerAPI.track('sub_more_details')
                                              setShowDetailsSubscription(true)
                                          }}/>
            </View>
        </BottomModal>
    }


    function renderCancelSubscription() {
        return <BottomModal visible={true}
                            title={TRANSLATES['CancelSubscription']}
                            height={227}
                            onCloseClick={() => setShowCancelSubscription(false)}>

            <View style={{
                display: 'flex',
                paddingLeft: 35,
                paddingRight: 35,
                flexDirection: 'column',
                marginTop: 16,
                alignItems: 'center'
            }}>
                <Text style={{fontSize: 14, fontWeight: '700', textAlign: 'center', color: COLORS.TEXT_COLOR}}>
                    {TRANSLATES['CancelSubscription.Title1']}
                </Text>


                <Text style={{
                    fontSize: 13, fontWeight: '500',
                    textAlign: 'center',
                    color: COLORS.TEXT_COLOR,
                    marginTop: 16
                }}>
                    {TRANSLATES['CancelSubscription.Title2']}
                </Text>
            </View>

            <View style={{
                marginTop: 'auto', display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'row', alignItems: 'center'
            }}>

                <FButton margin={0} text={TRANSLATES['Yes']}
                         onClick={async () => {
                             await cancelSubscription(fantasyAppFeatures.subscriptionId)
                             setShowCancelSubscription(false)
                             refresh()
                         }}
                         style={{width: '45%'}}/>

                <FButton margin={0} text={TRANSLATES['No']}
                         style={{width: '45%'}}
                         backgroundColor={MAIN_BAR_COLOR}
                         color={'#FFFFFF'}
                         onClick={() => setShowCancelSubscription(false)}/>
            </View>
        </BottomModal>
    }

    async function onSubscribeEnabledClick() {

        ServerAPI.track('sub_action')
        if (!user.Email || user.Email?.startsWith(EMPTY_EMAIL))
            return setSignUpVisible(true)

        if (subscriptionEnabled || subscriptionEnabledClicked)
            return
        setShowDetailsSubscription(false)
        setSubscriptionEnabledClicked(true)
        try {

            await subscribe(fantasyAppFeatures.subscriptionId)
            refresh()
            if (props.route.params?.goBack)
                props.navigation.goBack()
        }
        finally {
            setSubscriptionEnabledClicked(false)
        }
    }

    function submitFeedback() {
        ServerAPI.post('/fantasy/sendFeedback', {
            title: feedback!.title,
            desc: feedback!.desc,
            device: getDeviceInfo()
        });

        setFeedback(null)
        setShowBottomMessage(TRANSLATES['FeedbackResult'])
    }

    async function saveProfile() {

        setErrorText('')


        let errorText = await ServerAPI.post('/fantasy/updateProfile', {
            FirstName: newProfile!.username,
            AvatarUrl: newProfile!.avatarUrl,
            Email: newProfile!.email,
            tz: new Date().getTimezoneOffset() / 60
        })


        if (errorText == 'UserAlreadyExistsException') {
            setErrorText(TRANSLATES['UserAlreadyExistsException'])
            return
        }

        user.FirstName = newProfile!.username
        user.AvatarUrl = newProfile!.avatarUrl
        if (user.Email?.startsWith(EMPTY_EMAIL)) {
            user.Email = newProfile!.email;
            ServerAPI.track("SignUp")
        }

        setUser(user)

        setNewProfile(null as any)
        setShowBottomMessage(TRANSLATES['Saved'])

    }

    function getProfileImage() {
        let avatarUrl = newProfile!.avatarUrl
        if (avatarUrl)
            return <ImageBackground source={{uri: avatarUrl}} resizeMode="contain"
                                    style={{width: '100%', height: '100%'}}/>


        return <Image source={require('../icons/profile-yellow.png')} style={{width: 40, height: 40}}/>
    }

    function renderEditProfile() {

        return <BottomModal visible={true}
                            title={TRANSLATES['MyProfile']}
                            height={353}
                            onCloseClick={() => {
                                setNewProfile(null as any)
                                setErrorText('')
                            }}
        >

            <View style={{width: '100%', alignItems: 'center', marginTop: 12}}>
                <Pressable style={{
                    width: 72,
                    height: 72,
                    backgroundColor: COLORS.YELLOW,
                    alignItems: 'center',
                    borderRadius: 40,
                    justifyContent: 'center',
                    overflow: 'hidden'
                }}
                           onPress={() => {
                               if (Platform.OS != 'web')
                                   DocumentPicker.pickSingle({type: [DocumentPicker.types.images]}).then((res) => {
                                       onNativeFileSelected(res)
                                   })
                           }}
                >

                    {Platform.OS == 'web' &&
                        <input type="file"
                               style={styles.fileWebInput}
                               onChange={(e: ChangeEvent<HTMLInputElement>) => onWebFileSelected(e.target.files!![0])}/>
                    }
                    {getProfileImage()}

                    {avatarLoading && <CircularProgress style={{position: 'absolute'}}/>}

                </Pressable>

            </View>

            <InputWithLabel value={newProfile!.email}
                            style={{marginTop: 16}}
                            label={TRANSLATES['EmailAddress']}
                            onChangeText={(e: string) => canEditEmail && setNewProfile({
                                email: e,
                                avatarUrl: newProfile!.avatarUrl,
                                username: newProfile!.username
                            })}/>

            <InputWithLabel value={newProfile!.username}
                            label={TRANSLATES['Username']}
                            style={{marginTop: 16}}
                            onChangeText={(e: string) => setNewProfile({
                                email: newProfile!.email,
                                avatarUrl: newProfile!.avatarUrl,
                                username: e
                            })}/>

            <Text style={{color: 'red'}}>{errorText}</Text>

            <FButton margin={0} style={{marginTop: 32}} text={TRANSLATES['Save']} width="100%"
                     onClick={() => saveProfile()}
                     backgroundColor={MAIN_BAR_COLOR}
                     color="#FFFFFF"/>

        </BottomModal>
    }


    function renderRules() {
        let h = Dimensions.get('window').height - TopUserPanelHeight - 162;
        return <View style={{height: h}}>
            <MultiWebView url={getCurrentLang() == 'ru' ? 'https://platform.bd-sport.com/fantasy-rules-ru' : 'https://platform.bd-sport.com/fantasy-rules'}
                          style={{marginTop: 12, height: h}}/>
        </View>
    }

    async function onWebFileSelected(file: File) {
        if (file) {
            setAvatarLoading(true)

            let data = await ServerAPI.post(`/fantasy/creates3Link`, {
                id: 0,
                name: file.name
            }) as {
                uploadUrl: string,
                getUrl: string
            }

            const response = await fetch(data.uploadUrl, {
                method: 'PUT',
                body: file
            });

            await response.text();


            setNewProfile({
                email: newProfile!.email,
                avatarUrl: data.getUrl,
                username: newProfile!.username
            })

            setAvatarLoading(false)
        }
    }

    async function onNativeFileSelected(nativeResponse: DocumentPickerResponse) {
        if (nativeResponse) {
            //https://stackoverflow.com/questions/74349361/cannot-upload-file-with-formdata-on-react-native
            //this.setState({avatarLoading: true})
            let data = await ServerAPI.post(`/fantasy/creates3Link`, {
                id: 0,
                name: 'image'
            }) as {
                uploadUrl: string,
                getUrl: string
            }

            //console.log(nativeResponse.uri)

            const response = await fetch(data.uploadUrl, {
                method: 'PUT',
                body: nativeResponse.uri
            });

            await response.text();

            setNewProfile({
                email: newProfile!.email,
                avatarUrl: data.getUrl,
                username: newProfile!.username
            })

            setAvatarLoading(false)
        }
    }


    return (
        <View style={styles.root}>

            {renderLogin(user)}

            <UserAppBar navigation={props.navigation} onEventSelected={(fId: number) => {
                props.navigation.navigate('fantasyList', {fantasyId: fId})
            }}/>
            <ScrollViewRefresh
                style={styles.content}
                refreshing={refreshing}
                onRefresh={() => refresh()}
            >
                <Tabs value={selectedIndex}
                      items={[
                          {value: 0, width: 110, text: TRANSLATES['Achievements']},
                          {value: 1, width: 110, text: TRANSLATES['Settings']},
                          {value: 2, width: 110, text: TRANSLATES['Rules']}
                      ]}
                      onTabSelected={(idx: number) => setSelectedIndex(idx)}/>
            </ScrollViewRefresh>


            {selectedIndex == 0 && <AchievementView height={getTabContentHeight() - 20} navigation={props.navigation}/>}

            {selectedIndex == 1 && renderSettingTab()}

            {selectedIndex == 2 && renderRules()}

            {feedback && renderFeedback()}

            {subscriptionVisible && renderSubscription()}

            {newProfile && renderEditProfile()}

            {!!showBottomMessage &&
                <BottomModal visible={true}
                             title={TRANSLATES['ThankYou']}
                             height={163}
                             onCloseClick={() => setShowBottomMessage('')}>

                    <Text style={{
                        color: '#2E2D2D',
                        fontSize: 12,
                        marginTop: 16,
                        marginBottom: 32,
                        fontWeight: '500',
                        textAlign: 'center'
                    }}> {showBottomMessage}</Text>

                    <FButton margin={0} text={TRANSLATES['Close']}
                             width="100%"
                             onClick={() => setShowBottomMessage('')}
                             backgroundColor={MAIN_BAR_COLOR}
                             color="#FFFFFF"/>
                </BottomModal>
            }

            {webViewPage &&
                <BottomModal visible={true}
                             title={webViewPage.title}
                             overlayHeight={10}
                             padding={0}
                             height={Dimensions.get('window').height}
                             onCloseClick={() => setWebViewPage(null as any)}>

                    <MultiWebView url={webViewPage.url}
                                  style={{height: Dimensions.get('window').height - 30}}/>

                </BottomModal>
            }

        </View>
    );


}


const styles = StyleSheet.create({

    root: {
        width: '100%'
    },
    firstText: {
        color: '#2E2D2D',
        fontWeight: '700',
        fontSize: 15
    },
    content: {
        width: '100%'
    },
    descFeedbackInput: {
        paddingLeft: 16,
        paddingTop: 9,
        paddingBottom: 9,
        paddingRight: 16,
        marginTop: 16,
        marginBottom: 32,
        height: 120,
        width: '100%',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: '#CACDDE'
    },
    fileWebInput: {
        width: '100%',
        height: '100%',
        opacity: 0,
        position: 'absolute',
        zIndex: 1
    }
});
