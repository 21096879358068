import {StyleSheet, Text, View} from "react-native";
import {COLORS, RED} from "../colors";
import {secondsToTime} from "../func";
import React from "react";

export default function ControlItemLine(props: {
    text: string,
    redSeconds: number,
    redPercent: number,
    blueSeconds: number,
    bluePercent: number,
}) {
    return <View style={{alignItems: 'center', paddingRight: 12, paddingLeft: 12}}>
        <View style={{
            width: '100%', alignItems: 'center', flexDirection: 'row',
            marginBottom: 12,
            justifyContent: 'space-between'
        }}>
            <Text style={[styles.valueText, {textAlign: 'left'}]}>{props.redPercent}%</Text>
            <Text style={styles.measureText}>{props.text}</Text>
            <Text style={[styles.valueText, {textAlign: 'right'}]}>{props.bluePercent}%</Text>
        </View>
        {renderLinePercent(props.redPercent, props.bluePercent)}
        {renderTimeTextControls(props.redSeconds, props.blueSeconds)}
    </View>
}

function renderLinePercent(redPercent: number, bluePercent: number) {
    if (redPercent == undefined)
        redPercent = 0;
    return <View
        style={{height: 4, width: '100%', flexDirection: 'row', borderRadius: 10, overflow: 'hidden', marginTop: 4}}>
        {(redPercent > 0 || bluePercent > 0) &&
            <>
                <View style={{height: 4, width: redPercent + '%', backgroundColor: RED}}/>
                <View style={{height: 4, width: (100 - redPercent) + '%', backgroundColor: COLORS.DARK_BLUE}}/>
            </>
        }
    </View>
}

function renderTimeTextControls(redSeconds: number, blueSec: number) {
    return <View style={styles.controlTimeText}>
        <Text style={styles.measureText}>
            {secondsToTime(redSeconds)}
        </Text>
        <Text style={[styles.measureText]}>
            {secondsToTime(blueSec)}
        </Text>
    </View>
}


const styles = StyleSheet.create({
    measureText: {
        color: '#797B87',
        fontSize: 12,
        fontWeight: '500',
    },
    valueText: {
        color: '#2E2D2D',
        fontSize: 12,
        fontWeight: '700',
        width: 50,
        textAlign: 'center'
    },
    controlTimeText: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 12,
        width: '100%'
    },
})
