import {
    Dimensions,
    Image,
    Modal,
    Pressable,
    ScrollView, StyleProp,
    StyleSheet,
    Text,
    View, ViewStyle
} from "react-native";
import React, {useContext, useEffect, useState} from "react";
import {COLORS, MAIN_BAR_COLOR} from "../colors";
import FButton from "../controls/FButton";
import {CardStatus, FantasyInfo, UserCard} from "../types";
import TRANSLATES from "../translates/translates";
import {getDateStringFromUTC2LocalTime, getWidthExpression, UserContext} from "../func";
import {ServerAPI} from "../ServerAPI";
import GradientBox from "../controls/GradientBox";
import {DailyCard} from "../profile/DailyRewardModal";
import {OrangeButton} from "../controls/OrangeButton";
import GiveRewardScreen, {CardStatusCoefInfo} from "./GiveRewardScreen";
import Card from "../UserCardPage/Card";
import MessageToolTip from "../controls/MessageToolTip";


interface IProps {
    onClose: () => void,
    fantasy: FantasyInfo,
    navigation: any
}

enum DayCardType {
    additinal = 'additinal',
    bonus = 'bonus',
    athlete = 'athlete'
}

interface Reward {
    cardType: DayCardType,
    status: CardStatus,
}


export default function EventRewardModal(props: IProps) {

    const {user, setUser} = useContext(UserContext);


    const [givedRewards, setGivedRewards] = useState<UserCard[]>([])

    const [toolTipMsg, setToolTipMsg] = useState('')
    const [viewCards, setViewCards] = useState<UserCard[] | null>(null)
    const [stepViewCard, setStepViewCard] = useState<number>(0)
    const [cardCoef, setCardCoef] = useState<CardStatusCoefInfo>({})

    const refresh = async () => {
        let res = await ServerAPI.get<CardStatusCoefInfo>(`/card-coef`)
        setCardCoef(res)

        let res2 = await ServerAPI.get<UserCard[]>(`/fantasy/event-reward-cards/${props.fantasy.EventId}`)
        setGivedRewards(res2)
    }

    useEffect(() => {
        refresh()
    }, []);


    let viewRewards = [
        [{cardType: DayCardType.additinal, status: CardStatus.bronze}, {
            cardType: DayCardType.additinal,
            status: CardStatus.silver
        }],
        [{cardType: DayCardType.bonus, status: CardStatus.bronze}, {
            cardType: DayCardType.bonus,
            status: CardStatus.silver
        }],
        [{cardType: DayCardType.athlete, status: CardStatus.bronze}, {
            cardType: DayCardType.athlete,
            status: CardStatus.silver
        }]
    ] as Reward[][]

    const getStatusImage = (isSub: boolean) => {
        let source = null;

        let givedCards = givedRewards.find(it => it.isSubCard === isSub) != null

        if (givedCards)
            source = require('../icons/reward/today-getted-reward.png')
        else {
            if (isSub) {
                if (user.HasSubscription)
                    source = require('../icons/reward/get-reward.png')
                else
                    source = require('../icons/reward/lock-reward.png')
            } else
                source = require('../icons/reward/get-reward.png')
        }

        let s: StyleProp<ViewStyle> = {
            position: 'absolute',
            top: -8
        }

        if (isSub)
            s.right = -8
        else
            s.left = -8

        return <View style={s}>
            <Image source={source} style={{width: 24, height: 24}}/>
        </View>
    }

    const closeReward = () => {
        setViewCards(null)
        refresh()
    }

    const onRewardClick = async (isSub: boolean) => {

        ServerAPI.track(isSub ? "cards_event_sub_click" : "cards_event_click")

        if (!user?.Email)
            return;

        if (isSub && !user.HasSubscription) {
            setToolTipMsg(TRANSLATES['reward.no-sub'])
            return;
        }

        if (givedRewards.find(it => it.isSubCard === isSub))
            return

        let res = await ServerAPI.post<UserCard[]>(`/fantasy/give-event-cards/${props.fantasy.EventId}?sub=${isSub}`)
        setGivedRewards(res)
        if (res?.length)
        {
            setViewCards(res)
            ServerAPI.track(isSub ? "cards_event_sub_get" : "cards_event_get")
        }
    }

    const nextCard = () => {
        if (viewCards && stepViewCard < viewCards.length)
            setStepViewCard(stepViewCard + 1)
        else {
            setStepViewCard(0)
            closeReward()
        }
    }


    return (
        <Modal
            animationType={"slide"}
            transparent={false}
            visible={true}
            style={{backgroundColor: MAIN_BAR_COLOR, position: 'relative'}}
        >
            {viewCards ? <GiveRewardScreen closeReward={nextCard} animateNum={stepViewCard}>
                    {stepViewCard < viewCards.length ?
                        <Card card={viewCards[stepViewCard]} onSelected={nextCard}/>
                        :
                        <>
                            <Card card={viewCards[0]} onSelected={() => closeReward}/>
                            <View style={{height: 16}}></View>
                            <Card card={viewCards[1]} onSelected={() => closeReward}/>
                            <View style={{height: 16}}></View>
                            {viewCards[2] &&
                                <Card card={viewCards[2]} onSelected={() => closeReward}/>
                            }
                        </>
                    }
                </GiveRewardScreen>
                :
                <>
                    <ScrollView style={styles.root}>

                        <View style={{
                            padding: 16,
                            alignItems: 'flex-start',
                            position: 'relative',
                            justifyContent: 'flex-start',
                            width: '100%',
                            height: 200
                        }}>

                            <GradientBox width={Dimensions.get('window').width} height={200}
                                         style={{position: 'absolute', left: 0, top: 0}}
                                         stop1={"#7593FF"}
                                         stop2={"#FFBC60"}
                            />

                            {toolTipMsg ? <MessageToolTip message={toolTipMsg}
                                                          onClose={() => setToolTipMsg('')}/> : <></>}

                            <View style={{width: '100%'}}>
                                <Text style={styles.mainTextStyle}>
                                    Event Pack
                                </Text>

                                <Text style={styles.eventName}>
                                    {props.fantasy.EventName}
                                </Text>

                                <Text
                                    style={styles.eventDate}>{getDateStringFromUTC2LocalTime(props.fantasy.EventDate)}</Text>

                                {!user?.HasSubscription && <FButton text={"Buy stats fight +"}
                                         backgroundColor={COLORS.MAIN_BAR_COLOR}
                                         color={'#fff'}
                                         style={{
                                             marginTop: 12,
                                             marginRight: 0,
                                             marginBottom: 0,
                                             marginLeft: 'auto',
                                             paddingLeft: 12,
                                             paddingRight: 12,
                                         }}
                                         textStyle={{
                                             fontSize: 12
                                         }}
                                         onClick={() => {
                                             ServerAPI.track('buy_stats_fight_get_of_cards')
                                             props.navigation.navigate('Profile', {
                                                 subscription: new Date(),
                                                 goBack: true
                                             })
                                         }}/>
                                }
                            </View>
                        </View>


                        <View>
                            <View style={styles.roundItem}/>
                            <View style={styles.header2}>
                                <Text style={styles.mainTextStyle}>
                                    Rewards
                                </Text>
                            </View>

                            <View style={{
                                alignItems: 'center',
                                justifyContent: 'space-around',
                                paddingLeft: 16,
                                paddingRight: 16,
                                flexDirection: 'row',
                                marginTop: 20
                            }}>
                                <FButton width={94} margin={0}
                                         style={styles.button}
                                         text={'Free'}
                                         backgroundColor={"#5D76D1"}
                                         color={'#fff'}/>


                                <FButton width={94} margin={0}
                                         style={styles.button}
                                         text={"Stats fight+"}
                                         backgroundColor={COLORS.YELLOW}
                                         color={'#fff'}/>
                            </View>

                            <View style={{padding: 16, alignItems: 'center'}}>
                                {viewRewards.map((arr, idx) =>
                                    <View key={idx} style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative',
                                        top: idx > 0 ? -30 * idx : 0
                                    }}>
                                        <DailyCard cardCoef={cardCoef} reward={arr[0]}
                                                   style={{position: 'relative', marginRight: 20 * idx + 14}}/>
                                        <DailyCard cardCoef={cardCoef} reward={arr[1]}
                                                   style={{position: 'relative', marginLeft: 20 * idx + 14}}/>
                                    </View>
                                )}
                            </View>

                            <View style={{
                                zIndex: 1,
                                position: 'relative',
                                top: -70,
                                padding: 16,
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'space-around'
                            }}>
                                <OrangeButton text={TRANSLATES["reward.get-a-reward"]}
                                              onClick={() => onRewardClick(false)}>
                                    {getStatusImage(false)}
                                </OrangeButton>
                                <OrangeButton text={TRANSLATES["reward.get-a-reward"]}
                                              onClick={() => onRewardClick(true)}>
                                    {getStatusImage(true)}
                                </OrangeButton>
                            </View>
                        </View>
                        <View style={{height: 56}}></View>
                    </ScrollView>

                    <Pressable style={styles.footer} onPress={() => props.onClose()}>
                        <Image source={require('../icons/arrow-left-2.png')} style={{width: 24, height: 24}}/>
                        <Text style={styles.backText}>{TRANSLATES['Back']}</Text>
                    </Pressable>

                </>}

        </Modal>)
}

const styles = StyleSheet.create({
    eventName: {
        color: 'white',
        fontWeight: '700',
        fontSize: 15,
        marginTop: 28,
        maxWidth: getWidthExpression(100)
    },
    eventDate: {
        marginTop: 12,
        color: 'white',
        opacity: 0.5,
    },
    button: {
        width: 148
    },
    root: {
        position: 'relative',
        backgroundColor: MAIN_BAR_COLOR,
        height: Dimensions.get('window').height
    },
    header2: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    ellipse: {
        position: "absolute",
        width: '100%',
        height: 300,
        top: 0
    },
    mainTextStyle: {
        textAlign: 'center',
        fontSize: 15,
        color: '#fff',
        fontWeight: '700'
    },
    roundItem: {
        left: 0,
        width: '100%',
        backgroundColor: MAIN_BAR_COLOR,
        borderRadius: 60,
        top: -25,
        position: 'absolute',
        height: 60
    },
    footer: {
        height: 56,
        width: '100%',
        backgroundColor: '#131C3A',
        position: 'absolute',
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        gap: 8,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10
    },
    backText: {
        color: '#fff',
        fontSize: 15,
        fontWeight: '700'
    },
    dayBack: {
        left: 0,
        top: 0,
        position: 'absolute'
    },
    currentDayCenterBack: {
        position: "relative",
        height: 202,
        width: 342
    }
})
