import {StyleSheet, View} from 'react-native';
import React from 'react';
import {CardType, UserCard} from "../types";
import Card from "./Card";


interface IProps {
	style?: any,
	onOpen: Function,
	cards: UserCard[],
	name: string
}

interface IState {

}

export default class GroupCard extends React.Component<IProps, IState> {


	constructor(props: IProps)
	{
		super(props);
		this.state = {};
	}


	render()
	{

		let viewList = [...this.props.cards].splice(0, 7)

		return (
			 <View style={styles.root}>

				 <Card key={-1}
						 onSelected={() => this.props.onOpen()}
						 style={{
							 position: 'absolute',
							 left: 0,
							 zIndex: this.props.cards.length + 1,
							 top: 0
						 }} card={{exDescData: this.props.name, name: CardType.Promotion} as UserCard}/>

				 {viewList.map((it, idx) =>
					  <Card key={idx}
							  onSelected={() => this.props.onOpen(this.props.cards)}
							  style={{
								  position: 'absolute',
								  left: 0,
								  zIndex: this.props.cards.length - idx,
								  top: idx * 10
							  }} card={it}/>)
				 }
			 </View>
		)
	}
}


const styles = StyleSheet.create({
	root: {
		width: 100,
		position: 'relative',
		minHeight: 200
	}
});
