import React from 'react';
import {
    StyleSheet,
    View,
    Text,
    ScrollView,
    Image,
    Modal,
    Dimensions,
} from 'react-native';
import {AthleteStat, Fighter, RecordData, Tournament} from "../types";
import {ServerAPI} from "../ServerAPI";
import TRANSLATES from "../translates/translates";
import {parse} from "date-fns";
import ProgressCircle from "../controls/ProgressCircle";
import FighterCardAppBar from "./FighterCardAppBar";
import TPressable from "../controls/TPressable";
import ToolTipButton from "../controls/ToolTipButton";
import {ToolTipBody} from "../controls/MessageToolTip";

interface IProps {
    athleteId: number,
    onClose: Function
}

interface IState {
    athleteRecord: RecordData[],
    athleteStat: AthleteStat | null,
    fighter: Fighter | null,
    promotions: Tournament[],
    y: number
}


export default class StatsFighterRating extends React.Component<IProps, IState> {


    constructor(props: any) {
        super(props);
        this.state = {
            athleteRecord: [],
            athleteStat: null,
            fighter: null,
            promotions: [],
            y: 0
        };
    }

    componentDidMount() {
        this.refresh();
    }

    async refresh() {
        let athleteId = this.props.athleteId;

        let athleteGloryId = await ServerAPI.get<string>(`/fantasy/getAthleteId/${athleteId}`)

        let record = await ServerAPI.get<RecordData[]>(`/getRecordData/${athleteGloryId}`)
        this.setState({
            athleteRecord: record
        })

        let stat = await ServerAPI.get<AthleteStat>(`/getStatAthlete/${athleteGloryId}`)
        this.setState({
            athleteStat: stat
        })

        if (!stat)
            this.props.onClose()

        let promotions = await ServerAPI.getTournaments()
        this.setState({promotions})
    }

    getAgeString(f: AthleteStat) {
        if (!f.DateBirth)
            return '';

        let birthDate = parse(f.DateBirth, 'yyyy-MM-dd', new Date());

        let age = new Date().getFullYear() - birthDate.getFullYear();
        let m = new Date().getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && new Date().getDate() < birthDate.getDate())) {
            age--;
        }
        return age + '';
    }

    getRecordString(data: RecordData[]) {
        if (!data)
            return ''
        let win = 0, draw = 0, loss = 0;

        data.forEach(b => {
            if (b.Result == 'LOSE')
                loss++
            else if (b.Result == 'WIN')
                win++;
            else if (b.Result == 'DRAW')
                draw++;

        })

        return `${win}-${loss}-${draw}`
    }

    getCnt(data: RecordData[], winType: string[]) {
        if (!data)
            return ''
        return data.filter(it => it.Result == 'WIN' && winType.indexOf(it.WinType) > -1).length + ''
    }


    renderItem(name: string, value?: string | number) {
        if (!isNaN(value as number))
            value = Math.round(value as number * 100) / 100

        if (!isNaN(value as number))
            value = Math.round(value as number * 100) / 100

        return <View style={styles.item}>
            <Text style={{fontSize: 12, fontWeight: '500', color: '#797B87'}}>{name}</Text>
            <Text style={styles.textItem}>{value == undefined ? 'N/D' : value}</Text>
        </View>
    }

    renderRatings() {
        let rating = this.state.athleteStat?.AthleteRating;

        if (!rating)
            return <View/>;


        return <View style={{marginTop: 32}}>
            <View style={{flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>

                <View style={styles.recordItem}>
                    <ProgressCircle value={(rating.force) / 100} size={40} thickness={4}>
                        <Text style={styles.recordValueText}>{rating.force}</Text>
                    </ProgressCircle>
                    <Text style={styles.recordText}>{TRANSLATES['Force']}</Text>
                </View>

                <View style={styles.recordItem}>
                    <ProgressCircle value={(rating.speed) / 100} size={40} thickness={4}>
                        <Text style={styles.recordValueText}>{rating.speed}</Text>
                    </ProgressCircle>
                    <Text style={styles.recordText}>{TRANSLATES['Speed']}</Text>
                </View>

                <View style={styles.recordItem}>
                    <ProgressCircle value={(rating.takedown) / 100} size={40} thickness={4}>
                        <Text style={styles.recordValueText}>{rating.takedown}</Text>
                    </ProgressCircle>
                    <Text style={styles.recordText}>{TRANSLATES['Takedown']}</Text>
                </View>
            </View>

            <View style={{flexDirection: 'row', justifyContent: 'space-around', marginTop: 16, alignItems: 'center'}}>

                <View style={styles.recordItem}>
                    <ProgressCircle value={(rating.control) / 100} size={40} thickness={4}>
                        <Text style={styles.recordValueText}>{rating.control}</Text>
                    </ProgressCircle>
                    <Text style={styles.recordText}>{TRANSLATES['Control']}</Text>
                </View>

                <View style={styles.recordItem}>
                    <ProgressCircle value={(rating.defense) / 100} size={40} thickness={4}>
                        <Text style={styles.recordValueText}>{rating.defense}</Text>
                    </ProgressCircle>
                    <Text style={styles.recordText}>{TRANSLATES['Defense']}</Text>
                </View>

                <View style={styles.recordItem}>
                    <ProgressCircle value={(rating.efficiency) / 100} size={40} thickness={4}>
                        <Text style={styles.recordValueText}>{rating.efficiency}</Text>
                    </ProgressCircle>
                    <Text style={styles.recordText}>{TRANSLATES['Efficiency']}</Text>
                </View>
            </View>

        </View>
    }


    render() {
        let {athleteStat, athleteRecord} = this.state;


        if (!athleteStat)
            return <View/>

        let total = athleteStat.AthleteRating.total || athleteStat.AthleteRating.totalRecord;

        let promotion = this.state.promotions.find(it => it.Id == athleteStat!!.TournamentIds[0])?.Name

        return (<Modal
            animationType="slide"
            transparent={false}
            visible={true}
            style={{backgroundColor: '#fff'}}
        >
            <FighterCardAppBar athlete={athleteStat} onClose={this.props.onClose}/>

            <View style={{backgroundColor: '#fff', height: Dimensions.get('window').height - 160}}>

                <View style={styles.title}>
                    <Text style={styles.titleText}>{TRANSLATES['StatsFightRating']}</Text>
                    <TPressable name={"closeModal"} onPress={() => this.props.onClose()}
                                style={[styles.closeBtn, {right: 0}]}>
                        <Image style={{width: 10, height: 10}} source={require('../icons/close.png')}/>
                    </TPressable>
                </View>

                <ScrollView style={styles.root}>

                    <View style={{flexDirection: 'row', justifyContent: 'space-around', marginTop: 28}}>
                        <ProgressCircle value={(total) / 100} size={80}>
                            <Text style={styles.totalStyle}>{total}</Text>
                        </ProgressCircle>

                        <View style={{flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center'}}>
                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <Text style={{color: 'rgba(121, 123, 135, 1)', marginRight: 8}}>{TRANSLATES['GeneralRating']}</Text>
                                <ToolTipButton text={TRANSLATES['StatsFighterRatingToolTip']}/>
                            </View>
                            <Text style={styles.totalStyle}>{total}</Text>
                        </View>
                    </View>

                    {this.renderRatings()}

                    <ToolTipBody message={TRANSLATES['athlete-rating-page.msg']} style={{marginTop: 16}}/>
                    <Text style={[styles.headerText, {marginTop: 16}]}>{TRANSLATES['GeneralInformation']}</Text>
                    {this.renderItem(TRANSLATES['Record'], this.getRecordString(athleteRecord))}
                    {this.renderItem(TRANSLATES['Age'], this.getAgeString(athleteStat))}
                    {this.renderItem(TRANSLATES['Country'], athleteStat.CountryName)}
                    {this.renderItem(TRANSLATES['Promotion'], promotion)}
                    {this.renderItem(TRANSLATES['Weight'], athleteStat.Weight)}
                    {this.renderItem(TRANSLATES['Height'], athleteStat.Height)}
                    {this.renderItem(TRANSLATES['Reach'], athleteStat.ArmSpan)}

                    <Text style={styles.headerText}>{TRANSLATES['WinBy']}</Text>
                    {this.renderItem('KO/TKO', this.getCnt(athleteRecord, ['KO', 'TKO']))}
                    {this.renderItem('SUB', this.getCnt(athleteRecord, ['SUB', 'TB']))}
                    {this.renderItem('DEC', this.getCnt(athleteRecord, ['WP', 'UD', 'MD', 'SD']))}


                    <Text style={styles.headerText}>{TRANSLATES['Stats']}</Text>
                    {this.renderItem(TRANSLATES['ThrownStrikesPerMin'], athleteStat?.StrikesPerMin)}
                    {this.renderItem(TRANSLATES['LandedStrikesPerMin'], athleteStat?.StrikesLandedPerMin)}
                    {this.renderItem(TRANSLATES['StrikesEfficiency'], athleteStat ? Math.round(athleteStat.EfStrikes) : undefined)}
                    {this.renderItem(TRANSLATES['TakedownPer15'], athleteStat?.TakedownCountAvg5)}
                    {this.renderItem(TRANSLATES['AttemptsSubmissionPer15'], athleteStat?.SubmissionCountAvg5)}
                    {this.renderItem(TRANSLATES['SuccessSubmissionPer15'], athleteStat?.SubmissionCountSuccessAvg5)}

                </ScrollView>

            </View>


        </Modal>)
    }
}


const styles = StyleSheet.create({
    title: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    titleText: {
        color: '#2E2D2D',
        fontWeight: '700',
        fontSize: 15
    },
    recordItem: {
        justifyContent: "center",
        flexDirection: 'column',
        alignItems: 'center',
        width: 70
    },
    recordValueText: {
        color: 'rgba(46, 45, 45, 1)'
    },
    recordText: {
        marginTop: 10,
        color: 'rgba(121, 123, 135, 1)'
    },
    root: {},
    headerText: {
        height: 43,
        fontSize: 15,
        fontWeight: '700',
        textAlign: 'center',
        marginTop: 12,
        color: '#000'
    },
    item: {
        height: 48,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: 20
    },
    textItem: {
        fontSize: 15,
        fontWeight: '700',
        color: '#2E2D2D',
        marginRight: 24
    },

    nickname: {
        color: '#797B87',
        fontSize: 8,
    },
    name: {
        color: '#2E2D2D',
        fontWeight: '700',
        fontSize: 12,
    },
    closeBtn: {
        position: 'absolute',
        top: 6,
        width: 30,
        height: 30
    },
    rightTextAlign: {
        textAlign: 'right',
    },

    athleteImage: {
        width: 80,
        height: 80
    },
    totalStyle: {
        color: '#000',
        fontSize: 15,
        fontWeight: '700'
    }
});
