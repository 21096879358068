import React from 'react';
import {StyleSheet, View} from 'react-native';
import {COLORS, ORANGE_COLOR} from "../colors";


export default class Card extends React.Component<{ width?: number, height?: number }, any> {


	constructor(props: any)
	{
		super(props);
		this.state = {};
	}


	render()
	{
		return (
			 <View style={{...styles.root, width: this.props.width, height: this.props.height}}>
				 <View style={styles.line}/>
				 <View style={styles.line2}/>
				 <View style={styles.line3}/>
			 </View>
		);
	}
}

const styles = StyleSheet.create({
	root: {
		height: 16,
		width: 24,
		backgroundColor: COLORS.YELLOW,
		borderRadius: 3,
	},
	line: {
		height: 4,
		marginTop: 3,
		backgroundColor: ORANGE_COLOR,
	},
	line2: {
		marginTop: 3,
		marginLeft: 3,
		height: 1,
		width: 5,
		backgroundColor: '#FFDAA5',
	},
	line3: {
		marginLeft: 3,
		marginTop: 1,
		height: 1,
		width: 8,
		backgroundColor: '#FFDAA5',
	},
});
