import {Dimensions, Text, View} from "react-native";
import TRANSLATES from "../../translates/translates";
import React from "react";
import {PunchesStat} from "./compare-types";
import {COLORS} from "../../colors";

interface IProps {
    punchesStat: PunchesStat,
    color: string
}


export default function TypePunchesChart(props: IProps) {

    let size = Dimensions.get('window').width;
    let stat = props.punchesStat

    let arr = [
        {name: 'compare-stat.punches-stat.punch', value: stat.punch, color: COLORS.BLACK},
        {name: 'compare-stat.punches-stat.elbow', value: stat.elbow, color: COLORS.BLACK},
        {name: 'compare-stat.punches-stat.kick', value: stat.kick, color: COLORS.BLACK},
        {name: 'compare-stat.punches-stat.knee', value: stat.knee, color: COLORS.BLACK}
    ].sort((a, b) => b.value - a.value)

    arr = arr.filter(it => it.value > 0)

    let sum = arr.reduce((prev, curr) => curr.value + prev, 0)

    arr.forEach((it, idx) => {
        if (idx % 2 == 0)
            it.color = props.color
    })

    return <View style={{width: '100%'}}>

        <View style={{flexDirection: 'row', width: '100%'}}>
            {arr.map(it =>
                <View key={it.name} style={{backgroundColor: it.color, height: 24, width: 100 * it.value / sum + '%'}}>
                </View>
            )}
        </View>

        <View style={{flexDirection: 'row', width: '100%'}}>
            {arr.map(it => <View key={it.name}
                                 style={{
                                     flexGrow: it.value,
                                     flexDirection: 'column'
                                 }}>

                    <View style={{alignItems: 'center'}}>
                        <Text style={{
                            color: it.color,
                            fontSize: 12,
                            fontWeight: '500'
                        }}>{(TRANSLATES as any)[it.name]}</Text>
                        <Text style={{color: it.color, fontSize: 12, fontWeight: '500'}}>{it.value}%</Text>
                    </View>
                </View>
            )}
        </View>

    </View>
}
