import {StyleSheet, Text, View} from "react-native";
import React from "react";
import {Fight} from "../types";


export const getEndTime = (fight: Fight) => {
    if (!fight.endTime) {
        return '';
    }
    return fight.endTime.replace('00:', '');
}

export default function WinBlock(props: { fight: Fight }) {


    return <View style={styles.winBlock}>
        <Text style={styles.winText1}>{props.fight.winType}</Text>
        <Text style={styles.winText2}>({props.fight.winDescription || props.fight.winType})
            R{props.fight.winRound}, {getEndTime(props.fight)}</Text>
    </View>

}

const styles = StyleSheet.create({
    winBlock: {
        marginTop: 8,
        backgroundColor: '#CACDDE',
        borderRadius: 10,
        width: 144,
        height: 44,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    winText1: {
        color: '#2E2D2D',
        fontSize: 10,
        fontWeight: '700',
    },
    winText2: {
        color: '#797B87',
        fontSize: 10,
        fontWeight: '500',
        textAlign: 'center'
    },
})