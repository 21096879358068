import * as d3 from "d3";
import {COLORS} from "../../colors";
import React from "react";
import {Text, View} from "react-native";
import {getStepInfo} from "../../func";
import Svg, {G, Line, Text as SvgText} from "react-native-svg";

interface IProps {
    sortedValues: BarValue[],
    width: number,
    leftAxisMargin?: number
}

interface BarValue {
    value: number,
    color: string,
    name: string
}

export default function BarChart(props: IProps) {

    const axisFontSize = 10


    let sum = props.sortedValues.reduce((prev, it) => prev + it.value, 0)

    let stepInfo = getStepInfo(sum);

    let arr = props.sortedValues.map(it => ({
        ...it,
        percent: 100 * it.value / stepInfo.max
    }))


    arr.push({
        name: '',
        value: 0,
        color: COLORS.GRAY,
        percent: 100 - 100 * sum / stepInfo.max
    })


    let xAxisLabels: number[] = []
    for (let i = 0; i <= stepInfo.max; i += stepInfo.step) {
        xAxisLabels.push(i)
    }

    const getX = d3.scaleLinear()
        .domain([xAxisLabels[0], xAxisLabels[xAxisLabels.length - 1]])
        .range([0, props.width]);

    let leftAxisMargin = props.leftAxisMargin || 0

    const renderXAxis = () => {
        return <G>
            {xAxisLabels.map((item, index) => {
                return (<G key={index}>
                        <Line
                            x1={getX(item)}
                            y1={axisFontSize + 3}
                            x2={getX(item)}
                            y2={axisFontSize + 10}
                            stroke={COLORS.GRAY}
                            strokeWidth={1}
                        />
                        <SvgText x={item == 0 ? getX(item) + leftAxisMargin - 3:
                            (item == xAxisLabels[xAxisLabels.length - 1] ?
                                getX(item) - leftAxisMargin - 5:
                                getX(item) - 5)
                        }
                                 fill={COLORS.GRAY}
                                 fontSize={axisFontSize}
                                 fontWeight={700}
                                 y={axisFontSize}>
                            {Math.abs(item)}
                        </SvgText>
                    </G>
                );
            })}
        </G>
    }

    return <View style={[{flexDirection: 'column', width: props.width}]}>

        <Svg height={20} style={{overflow: 'visible'}}>
            {renderXAxis()}
            <Line strokeWidth={1} stroke={COLORS.GRAY}
                  x1={0}
                  y1={20}
                  x2={props.width}
                  y2={20}
            />
        </Svg>
        <View style={{flexDirection: 'row', width: '100%', marginTop: 16}}>

            {arr.map(it => <View
                key={it.name}
                style={{
                    width: it.percent + '%',
                    backgroundColor: it.color,
                    alignItems: 'center',
                    height: 24,
                    justifyContent: 'center'
                }}>
            </View>)}
        </View>

        <View style={{flexDirection: 'row', width: '100%', marginTop: 8}}>
            {arr.filter(it => it.percent > 0 && it.name != 'round')
                .map((it, idx) =>
                    <View
                        key={idx}
                        style={{
                            alignItems: 'center',
                            width: it.percent + '%',
                            minWidth: 70
                        }}>
                        <Text style={{
                            color: it.color,
                            fontSize: 12,
                            fontWeight: '500'
                        }}>{it.name}</Text>
                        <Text style={{color: it.color, fontSize: 12, fontWeight: '500'}}>{it.value}</Text>
                    </View>
                )}
        </View>
    </View>
}
