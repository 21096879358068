import {SafeAreaView, StyleSheet, Text, View} from 'react-native';
import React from 'react';
import {COLORS, MAIN_BAR_COLOR} from './colors';
import {getWidthExpression, UserContext} from './func';
import TRANSLATES from "./translates/translates";
import BottomModal from "./controls/BottomModal";
import PTSRatingUsers from "./main/PTSRatingUsers";
import PTSUserHistory from "./main/PTSUserHistory";
import CoinUserHistory from "./main/CoinUserHistory";
import TPressable from "./controls/TPressable";
import UserAvatarInfo from "./UserAvatarInfo";


interface IProps {
    onEventSelected?: Function,
    navigation: any
}

interface IState {

    loginVisible: boolean,
    refreshing: boolean,
    ptsRatingVisible: boolean,
    ptsHistoryVisible: boolean,
    coinHistoryVisible: boolean
}

export default class UserAppBar extends React.Component<IProps, IState> {


    constructor(props: IProps) {
        super(props);
        this.state = {
            loginVisible: false,
            refreshing: false,
            ptsRatingVisible: false,
            ptsHistoryVisible: false,
            coinHistoryVisible: false
        };
    }


    render() {
        return (
            <UserContext.Consumer>
                {({user, setUser}) => (
                    <SafeAreaView style={styles.container}>

                        <UserAvatarInfo navigation={this.props.navigation}/>

                        <View style={styles.part2}>

                            <TPressable name={"UserAppBarRank"} style={styles.part2Item}
                                        onPress={() => user.Id && this.setState({ptsRatingVisible: true})}>
                                <Text style={styles.itemValue}>{user?.rank || ''}</Text>
                                <Text style={styles.itemText}>{TRANSLATES['Rank']}</Text>
                            </TPressable>

                            <TPressable name={"UserAppBarPTS"} style={styles.part2Item}
                                        onPress={() => user.Id && this.setState({ptsHistoryVisible: true})}>
                                <Text style={styles.itemValue}>{user?.PTS || ''}</Text>
                                <Text style={styles.itemText}>PTS</Text>
                            </TPressable>

                            <TPressable name={"UserAppBarCoins"} style={styles.part2Item}
                                        onPress={() => user.Id && this.setState({coinHistoryVisible: true})}>
                                <Text style={styles.itemValue}>{user?.Coins || ''}</Text>
                                <Text style={styles.itemText}>{TRANSLATES['Coins2']}</Text>
                            </TPressable>

                        </View>
                        <View style={styles.bottom}/>

                        {this.state.ptsRatingVisible &&
                            <BottomModal visible={true}
                                         padding={0}
                                         onCloseClick={() => this.setState({
                                             ptsRatingVisible: false,
                                         })}
                                         title={TRANSLATES['RatingStatus'] + " " + (TRANSLATES as any)['Level' + user.currentLevel]}
                            >
                                <PTSRatingUsers/>
                            </BottomModal>}

                        {this.state.ptsHistoryVisible &&
                            <BottomModal visible={true}
                                         padding={0}
                                         onCloseClick={() => this.setState({ptsHistoryVisible: false})}
                                         title={TRANSLATES['YourPTS']}
                            >
                                <PTSUserHistory onEventSelected={(fId: number) => {
                                    if (this.props.onEventSelected) {
                                        this.props.onEventSelected(fId)
                                        this.setState({ptsHistoryVisible: false})
                                    }
                                }}/>
                            </BottomModal>}


                        {this.state.coinHistoryVisible &&
                            <BottomModal visible={true}
                                         padding={0}
                                         onCloseClick={() => this.setState({coinHistoryVisible: false})}
                                         title={TRANSLATES['YourCoins']}
                            >
                                <CoinUserHistory onEventSelected={(fId: number) => {
                                    if (this.props.onEventSelected) {
                                        this.props.onEventSelected(fId)
                                        this.setState({coinHistoryVisible: false})
                                    }
                                }}/>
                            </BottomModal>}

                    </SafeAreaView>
                )}
            </UserContext.Consumer>
        );
    }
}

export const TopUserPanelHeight = 243;

export const styles = StyleSheet.create({
    itemValue: {
        color: '#FFFFFF',
        fontWeight: '700',
        fontSize: 15,
    },
    itemText: {
        fontWeight: '700',
        fontSize: 12,
        color: '#FFFFFF',
        opacity: 0.5,
    },
    part2Item: {
        backgroundColor: COLORS.SECONDARY_ACCESS_COLOR,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 90,
        height: 53,
        borderRadius: 10,
    },
    part2: {
        marginTop: 24,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },


    pointsText: {
        color: '#FFFFFF',
        fontSize: 12,
        opacity: 0.5,
        marginTop: 8,
    },


    container: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 24,
        backgroundColor: MAIN_BAR_COLOR,
        height: TopUserPanelHeight,
    },

    bottom: {
        position: 'absolute',
        bottom: -30,
        left: 0,
        width: getWidthExpression(0),
        backgroundColor: '#EFF1F7',
        borderRadius: 30,
        height: 60
    }
});



