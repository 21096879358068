import {Dimensions, StyleSheet, Text, View} from "react-native";
import TRANSLATES from "../../translates/translates";
import React from "react";
import ManIcon from "./ManIcon";
import {COLORS} from "../../colors";
import {PunchesByZoneStat, TCSStat} from "./compare-types";


export default function PunchesZone(props: {
    landedColor: string,
    totalColor: string,
    zoneStat: PunchesByZoneStat
}) {


    const renderZoneStat = (stat: TCSStat) => {
        return <>
            <Text style={[styles.valueText, {color: props.totalColor}]}>
                {TRANSLATES['compare-stat.punches-stat.total']}
                {"\n"}
                {stat.total}
            </Text>
            <Text style={[styles.valueText, {color: props.landedColor}]}>
                {TRANSLATES['compare-stat.punches-stat.landed']}
                {"\n"}
                {stat.success}
            </Text>
            <Text style={[styles.valueText, {color: COLORS.BLACK}]}>
                {TRANSLATES['compare-stat.punches-stat.critical']}
                {"\n"}
                {stat.critical}
            </Text>
        </>
    }

    return <View style={{alignItems: 'center', position: 'relative'}}>
        <ManIcon color={props.totalColor}/>

        <View style={{position: 'absolute', top: 5, right: 22}}>
            <Text style={styles.zonNameText}>
                {TRANSLATES['compare-stat.punches-zones.head']}
            </Text>
            {renderZoneStat(props.zoneStat.head)}
        </View>

        <View style={{position: 'absolute', top: 95, left: 22}}>
            <Text style={styles.zonNameText}>
                {TRANSLATES['compare-stat.punches-zones.body']}
            </Text>
            {renderZoneStat(props.zoneStat.body)}
        </View>

        <View style={{position: 'absolute', top: 175, right: 22}}>
            <Text style={styles.zonNameText}>
                {TRANSLATES['compare-stat.punches-zones.legs']}
            </Text>
            {renderZoneStat(props.zoneStat.legs)}
        </View>

    </View>
}


const styles = StyleSheet.create({
    zonNameText: {
        fontSize: 12,
        fontWeight: '700',
        color: COLORS.BLACK,
        textAlign: 'center',
    },
    valueText: {
        marginTop: 8,
        textAlign: 'center',
        fontSize: 12,
        fontWeight: '500',
    }
})
