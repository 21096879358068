
export const EMPTY_EMAIL = 'empty@player.com'

export interface EventTournament {
	Id: number
	TournamentId: number
	Name: string,

	BattleCount: number,
	TournamentName: string,
	Date: string,
	DateStr: string,

	LocationId: number,
	GloryEventID: string,
	VideoFilePath: string
	HLUrl_Full: string
	HLUrl_Short: string
	HLUrl_Optimal: string
	VideoStreamUrl: string
	TVStreamUrl: string
}

export enum FantasyStatus {
	Calculated = 'Calculated', Open = 'Open', Closed = 'Closed'
}

export interface FantasyInfo {
	LogoUrl: string,
	EventId: number;
	GloryEventID: string,
	Id: number;
	TournamentName: string;
	EventName: string;
	EventDate: string,
	Points: FantasyRateRow[];
	Status: FantasyStatus,
	UserBalance: number
	IsLive: boolean
	PTS: number
	Coins: number
	Rank: number,
	LocationName: string
	City: string
	State: string
	Country: string
	TVStreamUrl: string
	TVStreamList: TVStreamItem[]
	IsPast: boolean
}

export interface TVStreamItem
{
	CountryName: string,
	CountryId: string,
	TVStreamUrl: string,
}

export interface FantasyRateRow {
	Id: number
	Total: number;
	AthleteId: number;
	GAthleteId: string;
	BattleId: number;
	/**
	 * Стоимость купленного бойца
	 */
	Points: number;
	Odd: number
	CalcPoints: number

	FirstName: string;
	LastName: string;
	CountryId: number;
	PhotoUrl: string,
	AdPoints: DistributionPoint[],
	WinPoints: DistributionPoint[],
	SelectedUserCards: number[],
	CalcLog: CalcLog[],
}

export interface CalcLog {
	winPointId: number,
	startPoints: number,
	cardPoints: number,
	pointCoef: number,
	userCardId: number
}

export interface BoughtFantasyInfo {
	items: FantasyRateRow[],
	userRating: UserRating
}

export interface DistributionPoint {
	Id: string;
	Type: string;

	Key: number,
	Name: string;
	Points: number;
	Description: string;
}

export interface User {
	Id: number,
	Email: string,
	Coins: number,
	PTS: number,
	AvatarUrl: string,
	rank: number,
	currentLevel: number,
	nextLevelPTS: number,
	prevLevelPTS: number
	FirstName: string
	LastName: string,
	UILang: string
	Token: string,
	FCMToken: string,
	HasSubscription: boolean,
}

export interface FantasyAppFeatures
{
	 enableSubscription: boolean
	 subscriptionId: string
	 subscriptionPrice: string
}

export interface CardPackage4 {
	id: number;
	name: string;
	status: CardStatus;
	price: number;
}

export enum CardStatus {
	gold = 'gold',
	silver = 'silver',
	bronze = 'bronze'
}

export interface UserCard {

	cardId: number;
	id: number;
	fantasyPointId: number;
	timeuse: CardTimeUse;
	name: string;
	created: string;
	status: CardStatus;
	statusCoef: number;
	cardCoef: number;
	exCardData: number;
	exDescData: string;
	countryName: string;
	countryISO: string
	athletePhotoUrl: string
	athleteRating: number
	athleteWeightId: number
	gyms: number[]

	promotionUrl: string
	athletePromotionName: string

	rewardEventId: number
	isSubCard: boolean
}

export enum CardType {
	Athlete = 'Athlete',
	Promotion = 'Promotion',
	Country = 'Country',
	Point = 'Point'
}

export enum CardTimeUse {
	temp = 'temp', perm = 'perm'
}

export enum BattleStatus {
	Canceled = 'Canceled',
	Finished = 'Finished',
	InProgress = 'InProgress',
	NoContest = 'NoContest'
}

export interface Fight {

	id: string;
	winner: string;
	stage: string;
	winRound: number;
	endTime: string;
	winType: string;
	status: string;
	winDescription: string;
	promotionId: number,
	red: Fighter;
	blue: Fighter;
	sportType: string
	weight: string
	date: string,
	eventName: string
	blueRating: number
	redRating: number
	roundCount: number
	hasStat: boolean 	// only in /fighter-history
}

export interface Fighter {
	key: number
	id: string;
	avatarUrl: string;
	photoUrl: string;
	firstName: string;
	lastName: string;
	nickname: string;
	countryISO: string;
	record: Record;
	countryId: number,
	weight: string;
	height: string;
	armSpan: string;
	dateBirth: string;
	countryName: string;
	rating: AthleteRating
}

export interface Record {
	Wins: number;
	Losses: number;
	Draws: number;
}

export interface UserRating {
	Id: number;
	Name: string;
	Level: number;
	CalcedPoints: number;
	PTS: number;
	Place: number;
}


export interface RecordData {
	OpponentId: number;
	Date: string;
	Result: string;
	Opponent: string;
	WinType: string;
	EndTime: string;
	Round: number;
	EventName: string;
	GloryFightID: string;
}


export interface UserStat {
	numberEvents: number,
	highestPlace: number,
	averagePlace: number,
	averageNumberCoins: number
}


export interface LevelMetaInfo {
	id: number
	name: string
	borderValue: number
}

export interface Athlete
{
	CountryName: string
	FirstName: string
	LastName: string,
	Nickname: string,
	CountryISO: string,
	Weight: string,
	AvatarUrl: string,
	PhotoUrl: string,
	SportType: string,
	DateBirth: string,
	Height: string,
	ArmSpan: string,
	AthleteRating: AthleteRating
	TournamentIds: number[]
}

export interface AthleteRating
{
	 force: number
	 speed: number
	 takedown: number
	 control: number
	 defense: number
	 efficiency: number
	 total: number
	 totalRecord: number
	 winCount: number
	 loseCount: number
	 drawCount: number
}

export interface AthleteStat extends Athlete {
	Strikes: number
	LandedStrikes: number
	TakedownCount: number
	TakedownCountSuccess: number
	SubmissionTotal: number
	SubmissionSuccess: number
	StrikesPerMin: number
	StrikesLandedPerMin: number
	TakedownCountAvg5: number

	SubmissionCountAvg5: number
	SubmissionCountSuccessAvg5: number
	EfStrikes: number
}

export interface Tournament {
	Id?: number;
	Name: string;
	Date?: string;
	Place?: string;
	UserInRaiting?: boolean;
	PermissionId?: number;
	IsPro?: boolean;
	IsAvailable?: boolean;
	LogoUrl: string
}

export interface RegisteringUser
{
	 email?: string
	 firstName?:string
	 lastName?: string
	 password?: string
	 device: string
	 currentEmail?: string,
	 tz: number
}


export interface CoinPrice {
	coins: number,
	price: number,
	productId: string
}


export interface Weight
{
    Name: string
    Id: number
    Sex: number
}


export interface Gym
{
	Id: number,
	Type: 'general'
}


export type ValidatePurchaseAndBuy = (receipt: string, productId: string, purchaseToken: string | undefined) => Promise<void>


export interface CountTabs{
    Live: number
    Open: number
    Upcoming: number
}

export interface DailyReward {

	 id: number,
	 givenDay: number,
	 isSubCard: boolean
	 createdDate: string
	 coins: number
	 isToday: boolean
}

export enum FantasyListFilter {
	Past= "Past", Live="Live", Open="Open", Upcoming="Upcoming"
}

export enum AchievementType {
	coins = 'coins',
	play_fantasy = 'play_fantasy',
	rank = 'rank',
	general_raiting_gym = 'general_raiting_gym',
}

export interface Achievement {
	 id: number
	 type: AchievementType
	 name_ru: string,
	 name_en: string
	 caption_ru: string
	 caption_en: string
	 value: number
	 value_ex: string
	 desc_ru: string
	 desc_en: string
	 desc_done_ru: string
	 desc_done_en: string
}

export interface AchievementUser {
	id: number
	achievement_id: number
	user_id: number
	created_at: string
	value: number
}