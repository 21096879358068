import {EN} from './en';
import {RU} from './ru';
import {NativeModules, Platform} from 'react-native';

export function getCurrentLang()
{
    let lang = 'en';

    switch (Platform.OS)
    {
        case 'ios':
            lang =  NativeModules.SettingsManager.settings.AppleLocale || NativeModules.SettingsManager.settings.AppleLanguages[0] // "es”
            break;
        case 'web':
            lang = window.navigator.language;
            break
        default:
            lang = NativeModules.I18nManager.localeIdentifier
            break;
    }

    if (lang.toLowerCase().indexOf('ru') > -1)
        return 'ru';
    else
        return 'en'
}

const TRANSLATES = (getCurrentLang() == 'ru' ? RU : EN) as {[key: string]: string};

export default TRANSLATES;
