import {Image, SafeAreaView, StyleSheet, Text, View} from 'react-native';
import React, {useContext} from 'react';
import {MAIN_BAR_COLOR} from '../colors';
import {getWidthExpression, UserContext} from '../func';
import UserAvatarInfo from "../UserAvatarInfo";
import {styles as base_app_bar_styles, TopUserPanelHeight} from "../UserAppBar";
import TRANSLATES from "../translates/translates";

interface IProps {
    navigation: any
}

export default function UserAppBarOnShopPage(props: IProps) {
    const {user} = useContext(UserContext);
    return (
        <SafeAreaView style={styles.container}>

            <UserAvatarInfo navigation={props.navigation}/>


            <View style={{
                flexDirection: "row",
                marginTop: 24,
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Text style={base_app_bar_styles.itemValue}>
                    {TRANSLATES['coins_bank']}
                </Text>


                <Image source={require(`../icons/coins.png`)}
                       resizeMode={'contain'}
                       style={styles.image_coins}/>

                <View style={base_app_bar_styles.part2Item}>
                    <Text style={base_app_bar_styles.itemValue}>{user?.Coins || ''}</Text>
                </View>
            </View>
            <View style={styles.bottom}/>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    image_coins: {
        height: 50,
        width: 100
    },
    container: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 24,
        backgroundColor: MAIN_BAR_COLOR,
        height: TopUserPanelHeight,
    },
    bottom: {
        position: 'absolute',
        bottom: -30,
        left: 0,
        width: getWidthExpression(0),
        backgroundColor: '#EFF1F7',
        borderRadius: 30,
        height: 60
    }
});



