import React from 'react';
import {StyleSheet, View, Text, Pressable} from 'react-native';
import TRANSLATES from "../translates/translates";


interface IProps {
	onNextClick: Function,
	text: string,
	currentStep: number,
	stepCount: number,
	onSkipClick: Function,
	position: 'top' | 'bottom',
	height?: number
}

export default class BoardStep extends React.Component<IProps, any> {

	constructor(props: IProps)
	{
		super(props);
		this.state = {};
	}

	render()
	{
		let position = this.props.position == 'top' ? {marginBottom: 'auto'} : {marginTop: 'auto'}

		return <View style={[styles.root, position, {height: this.props.height || 167}]}>
			<Text style={styles.text}>
				{this.props.text}
			</Text>

			{this.props.stepCount > 1 &&
			 <View style={styles.items}>
					 {[...new Array(this.props.stepCount)].map((it, idx) =>
						  <View key={idx} style={[styles.item, idx == this.props.currentStep ? styles.currentItem : null]}/>
					 )}
			 </View>
			}


			<View style={{flexDirection: 'row', marginTop: 'auto', justifyContent: 'space-around'}}>
				<Pressable
					 onPress={() => this.props.onNextClick()}
					 style={styles.nextButton}
				>
					<Text style={styles.textButtonNext}>
						{TRANSLATES['GotIt']}
					</Text>
				</Pressable>

				<Pressable
					 onPress={() => this.props.onSkipClick()}
					 style={styles.skipButton}
				>
					<Text style={styles.textButtonSkip}>
						{TRANSLATES['Skip']}
					</Text>
				</Pressable>
			</View>
		</View>
	}
}

const styles = StyleSheet.create({
	root: {
		padding: 16,
		backgroundColor: '#FFDAA5',
		borderRadius: 10,
		overflow: 'hidden',
		elevation: 3,
		boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12)'
	},
	items: {
		flexDirection: 'row',
		marginTop: 'auto',
		justifyContent: 'center',
		paddingRight: 3
	},
	item: {
		marginLeft: 6,
		height: 6,
		width: 6,
		backgroundColor: 'rgba(46, 45, 45, 0.3)',
		borderRadius: 5
	},
	currentItem: {
		backgroundColor: '#2E2D2D',
		width: 20
	},
	text: {
		minHeight: 60,
		color: '#2E2D2D',
		fontSize: 15,
		fontWeight: '500'
	},
	nextButton: {
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 30,
		height: 43,
		width: 140,
		backgroundColor: '#FF9400'
	},
	skipButton: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 43,
		width: 140,
		borderStyle: 'solid',
		borderColor: '#FF9400',
		borderWidth: 2,
		borderRadius: 30,
	},
	textButtonNext: {
		fontSize: 12,
		fontWeight: '700',
		textTransform: 'uppercase',
		color: '#fff'
	},
	textButtonSkip: {
		fontSize: 12,
		fontWeight: '700',
		textTransform: 'uppercase',
		color: '#FF9400'
	}
});
