import React, {useContext, useEffect, useState} from 'react';
import {StyleSheet, View, Text, ScrollView, Image, Dimensions} from 'react-native';
import {Athlete, Fight, Fighter} from "../types";
import TRANSLATES from "../translates/translates";
import FighterCardAppBar from "../UserCardPage/FighterCardAppBar";
import TPressable from "../controls/TPressable";
import {ServerAPI} from "../ServerAPI";
import BattleItem from "./BattleItem";
import {format, parse} from "date-fns";
import Modal from "../controls/CModal";
import LiveStatTable from "./LiveStatTable";
import CourseFight from "./CourseFight";
import {UserContext} from "../func";


interface IProps {
    fighter: Fighter,
    onClose: Function
    navigation?: any,
}


export default function FighterRecordHistory(props: IProps) {

    const {user} = useContext(UserContext);
    const [fights, setFights] = useState<Fight[]>([])
    const [liveStatsFight, setLiveStatsFight] = useState<Fight | null>(null)
    const [courseDialogFight, setCourseDialogFight] = useState<Fight | null>(null)

    useEffect(() => {
        refresh();
    }, [])


    const refresh = async () => {
        let athleteId = props.fighter.id;

        let res = await ServerAPI.get<Fight[]>(`/fighter-history/${athleteId}`)

        setFights(res)
    }


    const toAthlete = () => {
        let f = props.fighter;
        return {
            FirstName: f.firstName,
            LastName: f.lastName,
            CountryName: f.countryName,
            CountryISO: f.countryISO,
            AthleteRating: f.rating,
            AvatarUrl: f.avatarUrl,
            PhotoUrl: f.photoUrl
        } as Athlete
    }

    const getBattleText = (eventName: string, eventDate: string) => {
        if (!eventDate && !eventName)
            return "";

        let res = [];

        if (eventName)
            res.push(eventName)


        if (eventDate) {
            let d = parse(eventDate, 'yyyy-MM-dd', new Date());
            res.push(format(d, 'dd/MM/yy'));
        }

        return res.join(', ')
    }

    const onCloseClick = () => {

        if (courseDialogFight)
            setCourseDialogFight(null)
        else {
            if (liveStatsFight)
                setLiveStatsFight(null)
            else
                props.onClose()
        }
    }

    const renderContent = () => {

        if (courseDialogFight)
            return <CourseFight height={Dimensions.get('window').height - 140}
                                fight={courseDialogFight}
                                battleId={courseDialogFight.id}/>

        if (liveStatsFight) {
            return <LiveStatTable fight={liveStatsFight}
                                  openCourseDialog={() => {
                                      ServerAPI.track('course_of_fight')

                                      if (!user.HasSubscription) {
                                          props.navigation.navigate('Profile', {
                                              subscription: new Date()
                                          })
                                      } else
                                          setCourseDialogFight(liveStatsFight)
                                  }}/>
        }


        return <View style={{paddingLeft: 16, paddingRight: 16}}>
            {fights.map(it =>
                <View key={it.id}>
                    <Text style={styles.battleText}>{getBattleText(it.eventName, it.date)}</Text>
                    <BattleItem fight={it} hasStat={it.hasStat} key={it.id} liveStatClick={it.hasStat ? () => {
                        ServerAPI.track('LiveStatsFromHistory', 'data')
                        setLiveStatsFight(it)
                    } : undefined}/>
                </View>
            )}
        </View>
    }


    return (<Modal visible={true}>

        <FighterCardAppBar athlete={toAthlete()} onClose={props.onClose}
                           bottomStyle={{backgroundColor: '#EFF1F7'}}/>
        <View style={{backgroundColor: '#EFF1F7', height: Dimensions.get('window').height}}>

            <View style={styles.title}>
                <Text style={styles.titleText}>{TRANSLATES['LastFights']}</Text>
                <TPressable name={"closeModal"} onPress={onCloseClick}
                            style={[styles.closeBtn, {right: 0}]}>
                    <Image style={{width: 10, height: 10}} source={require('../icons/close.png')}/>
                </TPressable>
            </View>

            <ScrollView style={styles.scrollView}>
                {renderContent()}
            </ScrollView>

            <View style={{height: 120}}></View>

        </View>

    </Modal>)
}


const styles = StyleSheet.create({
    battleText: {
        marginBottom: 15,
        fontWeight: '700',
        fontSize: 15,
        color: '#2E2D2D'
    },
    infoBtn: {
        width: 24,
        height: 24,
        marginLeft: 10
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: '#EFF1F7',
    },
    titleText: {
        color: '#2E2D2D',
        fontWeight: '700',
        fontSize: 15
    },
    recordItem: {
        justifyContent: "center",
        flexDirection: 'column',
        alignItems: 'center',
        width: 70
    },
    recordText: {
        marginTop: 10,
    },
    scrollView: {
        marginTop: 16
    },
    headerText: {
        height: 43,
        fontSize: 15,
        fontWeight: '700',
        textAlign: 'center',
        marginTop: 12,
        color: '#000'
    },
    item: {
        height: 48,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: 20
    },
    textItem: {
        fontSize: 15,
        fontWeight: '700',
        color: '#2E2D2D',
        marginRight: 24
    },

    nickname: {
        color: '#797B87',
        fontSize: 8,
    },
    name: {
        color: '#2E2D2D',
        fontWeight: '700',
        fontSize: 12,
    },
    closeBtn: {
        position: 'absolute',
        top: 6,
        width: 30,
        height: 30
    },
    rightTextAlign: {
        textAlign: 'right',
    },

    athleteImage: {
        width: 80,
        height: 80
    },
    totalStyle: {
        color: '#000',
        fontSize: 15,
        fontWeight: '700'
    }
});
