import {BattleStatus, Fight} from "../types";
import {Image, Pressable, ScrollView, StyleSheet, Text, View} from "react-native";
import React, {createRef, useEffect} from "react";
import {getFlagUrl} from "../func";
import {COLORS} from "../colors";


export default function HorBattleList(props: { items: Fight[], selected: Fight, onSelect: (f: Fight) => void, status: BattleStatus | null }) {

    let scrollViewRef : any = createRef()

    useEffect(() => {
        let index = props.items.findIndex(f => f.id == props.selected.id)
        if (index > 0) {
            scrollViewRef.current?.scrollTo({
                y: 0,
                x: index * (168 + 8),
                animated: false
            });
        }

    }, [props.items]);

    const canOpen = (item: Fight) => {

        if (item.status == BattleStatus.Finished && props.status == null) {
            return false
        }

        if (item.status != BattleStatus.Finished && props.status == BattleStatus.Finished) {
            return false
        }

        return true
    }

    return <View style={{
        paddingLeft: 8,
        paddingRight: 8,
    }}>
        <ScrollView ref={scrollViewRef} style={{
            flexDirection: 'row',
            height: 46,
            width: '100%',
            maxHeight: 46
        }} horizontal={true}>
            {props.items.map((item, index) => {
                let refFlagUrl = getFlagUrl(item.red.countryISO)
                let blueFlagUrl = getFlagUrl(item.blue.countryISO)
                let textColor = item.id == props.selected.id ? '#fff' : '#000';
                return <Pressable
                    onPress={() => canOpen(item) ? props.onSelect(item) : {

                    }}
                    key={item.id}
                    style={[{
                        ...styles.item,
                        backgroundColor: item.id == props.selected.id ? COLORS.MAIN_BAR_COLOR : canOpen(item) ? '#EFF1F6': '#BABABA'
                    }]}>
                    <View>
                        {refFlagUrl && <Image source={{uri: refFlagUrl}} style={{width: 18, height: 10}}/>}
                        <Text style={{
                            color: textColor,
                            fontWeight: 'bold',
                            fontSize: 12,
                            marginTop: 4
                        }}>{item.red.lastName}</Text>
                    </View>
                    <View style={{
                        borderStyle: 'dashed',
                        borderWidth: 1,
                        borderColor: '#CACDDE',
                        width: 1,
                        height: 29
                    }}></View>
                    <View>
                        {blueFlagUrl && <Image source={{uri: blueFlagUrl}} style={{width: 18, height: 10}}/>}
                        <Text style={{
                            color: textColor,
                            fontWeight: 'bold',
                            fontSize: 12,
                            marginTop: 4
                        }}>{item.blue.lastName}</Text>
                    </View>
                </Pressable>
            })}
        </ScrollView>
    </View>
}


const styles = StyleSheet.create({
    item: {
        alignItems: 'center',
        justifyContent: 'space-around',
        flexDirection: 'row',
        height: 46,
        width: 168,
        borderRadius: 10,
        marginLeft: 8
    },
})