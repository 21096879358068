import {Image, Pressable, StyleSheet, Text, View} from 'react-native';
import React from 'react';
import {CardStatus} from '../types';
import {getBaseColor, getRatingInterval} from "../func";
import RatingBox from "../controls/RatingBox";
import {styles as card_styles} from "../UserCardPage/Card";
import TRANSLATES from "../translates/translates";

interface IProps {
    status: CardStatus,
    style?: any,
    onSelected?: Function,
    isAthlete?: boolean
}

export default function PreviewCard(props: IProps) {

    return (
        <Pressable style={[styles.root, props.style]}
                   onPress={() => props.onSelected && props.onSelected()}>
            <Image source={require('../assets/black_gradient.png')}
                   style={[styles.img, {backgroundColor: getBaseColor(props.status)}]}/>
            {props.isAthlete && <Image source={require('../assets/athlete_shadow.png')} style={styles.img}/>}
            <Image source={require('../assets/card_background.png')} style={styles.img}/>

            <View style={{
                position: 'absolute',
                top: 4,
                height: '100%',
                alignItems: 'center'
            }}>
                <RatingBox text={getRatingInterval(props.status)}
                           style={{width: 54, marginRight: 'auto', opacity: props.isAthlete ? 1: 0}}/>

                <Text style={{textAlign: 'center', color: '#fff', fontSize: 14, fontWeight: '900', marginTop: 40, textTransform: 'uppercase'}}>
                    {props.isAthlete ? TRANSLATES['fighter_card'] : TRANSLATES['other_card']}
                </Text>

                <Text style={[card_styles.coefText, {
                    bottom: 12, left: 0
                }]}>
                    1.5x
                </Text>
            </View>
        </Pressable>
    );

}


const styles = StyleSheet.create({
    img: {
        position: 'absolute',
        width: '100%',
        height: '105%',
    },
    background: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    root: {
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'flex-end',
        overflow: 'hidden',
        display: 'flex',
        width: 100,
        height: 140,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: '#F6D191',
        borderStyle: 'solid',
    }
});
