import {StyleSheet, Text, View} from "react-native";
import {COLORS, RED} from "../colors";
import React from "react";
import {round100} from "../func";

export const CompareOneValue = ({
                                    text, redValue, redTotal, blueValue, blueTotal,
                                    max,
                                    useMax = true
                                }: {
    text: string, redValue?: number, redTotal?: number, blueValue?: number, blueTotal?: number, useMax?: boolean,
    max: number
}) => {


    const renderLeftValue = (value: number | undefined, total: number, useMax = true) => {

        let sto = 156;
        let perc = value ? 100 * value / (useMax ? max : total) : 0
        if (isNaN(perc))
            perc = 0;

        return <View style={{flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: sto}}>
            <View style={{
                marginTop: 8,
                height: 4,
                width: sto,
                flexDirection: 'row',
                borderRadius: 10,
                overflow: 'hidden',
                justifyContent: 'flex-end'
            }}>
                <View style={{height: 4, width: (100 - perc) + '%', backgroundColor: RED, opacity: 0.5}}/>
                <View style={{height: 4, width: perc + '%', backgroundColor: RED}}/>
            </View>
        </View>
    }

    const renderRightValue = (value: number | undefined, total: number, useMax = true) => {
        let sto = 156;
        let perc = value ? 100 * value / (useMax ? max : total) : 0;
        if (isNaN(perc))
            perc = 0;
        return <View style={{flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center', width: sto}}>
            <View style={{
                marginTop: 8,
                height: 4,
                width: sto,
                flexDirection: 'row',
                borderRadius: 10,
                overflow: 'hidden',
                justifyContent: 'flex-end'
            }}>
                <View style={{height: 4, width: perc + '%', backgroundColor: COLORS.DARK_BLUE}}/>
                <View style={{height: 4, width: (100 - perc) + '%', backgroundColor: COLORS.DARK_BLUE, opacity: 0.5}}/>
            </View>
        </View>
    }

    const renderTextValue = (value: number | undefined, total: number | undefined) => {
        if (value == undefined)
            return 'N/D'

        if (total == undefined)
            return value.toString()

        return `${value}/${total}`
    }

    let maxVal = round100(Math.max(redValue || 10, blueValue || 10))

    return <View style={{height: 52}}>
        <View style={{flexDirection: 'row'}}>
            <Text style={[styles.valueText, {
                marginRight: 'auto',
                width: '25%',
                textAlign: 'left'
            }]}>{renderTextValue(redValue, redTotal)}</Text>
            <Text style={[styles.subTitleText, {width: '50%'}]}>{text}</Text>
            <Text style={[styles.valueText, {
                marginLeft: 'auto',
                width: '25%',
                textAlign: 'right'
            }]}>{renderTextValue(blueValue, blueTotal)}</Text>
        </View>
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            {renderLeftValue(redValue, redTotal || maxVal, useMax)}
            {renderRightValue(blueValue, blueTotal || maxVal, useMax)}
        </View>
    </View>

}

const styles = StyleSheet.create({
    subTitleText: {
        fontSize: 12,
        fontWeight: '500',
        textAlign: 'center',
        color: '#797B87'
    },
    valueText: {
        color: '#2E2D2D',
        fontSize: 12,
        fontWeight: '700',
        textAlign: 'center'
    },
})