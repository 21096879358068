import React from 'react';
import {GestureResponderEvent, Pressable} from 'react-native';
import {ServerAPI} from "../ServerAPI";

interface IProps {
	name: string
	onPress?: (e: GestureResponderEvent) => void,
	style?: any
	enabled?: boolean,
	children?: any
}

export default class TPressable extends React.Component<IProps> {

	onPress(e: GestureResponderEvent)
	{
		if (this.props.enabled !== false)
		{
			ServerAPI.track(this.props.name, '')
			if (this.props.onPress)
				this.props.onPress(e)
		}
	}

	render()
	{
		return <Pressable style={this.props.style} onPress={(e) => this.onPress(e)}>
			{this.props.children}
		</Pressable>
	}
}
