import React from 'react';
import {Dimensions, Platform, StyleSheet, View, Text} from 'react-native';
import {MAIN_BAR_COLOR} from '../colors';


interface IProps {
	nextStep: number,
	prevStep: number,
	current: number,
	rightText: string
}


export default class StatusProgress extends React.Component<IProps, any> {

	constructor(props: any)
	{
		super(props);
	}

	getWidth()
	{
		if (this.props.current >= this.props.nextStep)
			return "100%"

		let perc =  (this.props.current - this.props.prevStep) / (this.props.nextStep - this.props.prevStep)

		if (perc == 0)
			return 0

		if (Platform.OS === 'web')
			return `${100 * perc}%`;


		return  (Dimensions.get('window').width - 18) * perc;
	}

	render()
	{
		return <View style={styles.root}>

			<View style={styles.backLine}>
				<View style={[styles.line, {width: this.getWidth()}]}/>
			</View>

			<View style={{
				flexDirection: 'row', marginTop: 8,
				justifyContent: 'center',
				alignItems: 'flex-start', width: '100%'
			}}>
				<Text style={styles.leftText}>
					<Text style={{fontWeight: '700'}}>{this.props.current}</Text>
					{this.props.nextStep > this.props.current && <Text>/{this.props.nextStep} PTS</Text>}
				</Text>
				<Text style={styles.rightText}>
					{this.props.rightText}
				</Text>
			</View>
		</View>
	}
}


const styles = StyleSheet.create({
	root: {
		padding: 16,
		width: '100%',
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column'
	},
	backLine: {
		height: 8,
		backgroundColor: '#CACDDE',
		width: '100%',
		borderRadius: 10
	},
	line: {
		height: 8,
		backgroundColor: MAIN_BAR_COLOR,
		borderRadius: 3
	},
	rightText: {
		fontWeight: '700',
		color: '#2E2D2D',
		fontSize: 12,
		marginLeft: 'auto'
	},
	leftText: {
		color: '#2E2D2D',
		fontSize: 12
	}
});
