import React from 'react';


import {createStackNavigator} from '@react-navigation/stack';
import {MAIN_BAR_COLOR} from '../colors';
import TRANSLATES from '../translates/translates';
import UserCardPage from "./UserCardPage";
import UserGymCards from "./UserGymCards";


const Stack = createStackNavigator();


export default function CardNavigation(props: {navigation: any})
{

	return <Stack.Navigator screenOptions={{headerShown: false}}>

		<Stack.Screen name={'cardPage'} component={UserCardPage}/>


		<Stack.Screen name={'userCardGymPage'} component={UserGymCards}
						  options={{
							  headerTitleStyle: {
								  color: 'white',
							  },
							  headerTitleAlign: 'center',
							  headerStyle: {
								  backgroundColor: MAIN_BAR_COLOR,
							  },
							  headerTintColor: 'white',
							  headerShadowVisible: false,
							  headerShown: true,
							  headerTitle: TRANSLATES['Cards'],
						  }}/>

	</Stack.Navigator>;
}
