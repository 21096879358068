import React from 'react';
import {StyleSheet, View, Text, Image, Pressable} from 'react-native';
import {MAIN_BAR_COLOR} from '../colors';
import TRANSLATES from '../translates/translates';
import {formatWithDecimals} from "../func";

interface IProps {
    coins: number,
    price: number | string,
    onClick: Function
}

function getCoinImage(coins: number) {

    if (coins <= 1000) {
        return <Image style={{width: 23, height: 23}} source={require('./images/coin1.png')}/>;
    }

    if (coins <= 10000) {
        return <Image style={{width: 24, height: 32}} source={require('./images/coin2.png')}/>;
    }

    if (coins <= 25000) {
        return <Image style={{width: 46, height: 61}} source={require('./images/coin4.png')}/>;
    }

    if (coins <= 50000) {
        return <Image style={{width: 46, height: 51}} source={require('./images/bag1.png')}/>;
    }

    if (coins <= 100000) {
        return <Image style={{width: 70, height: 51}} source={require('./images/bag2.png')}/>;
    }

    return <Image style={{width: 63, height: 70}} source={require('./images/bag3.png')}/>;
}

export default class CoinPackage extends React.Component<IProps, any> {


    constructor(props: any) {
        super(props);
        this.state = {};
    }


    formatPrice() {
        if (!this.props.price)
            return 'Watch ads';

        let s = this.props.price + '';
        let res = '';
        for (let i = 0; i < s.length; i++) {
            if (i == s.length - 2)
                res += '.';
            res += s[i];
        }
        return "$ " + res;
    }

    render() {
        return (
            <Pressable style={{...styles.root}} onPress={() => this.props.onClick()}>
                {getCoinImage(this.props.coins)}
                <Text style={styles.coinText}>{formatWithDecimals(this.props.coins)}{TRANSLATES['Coins']}</Text>
                <View style={styles.bottom}>
                    <Text style={styles.priceText}>{this.formatPrice()}</Text>
                </View>
            </Pressable>
        );
    }
}

const styles = StyleSheet.create({
    image: {},
    root: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: 120,
        width: 100,
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        overflow: 'hidden',
    },
    coinText: {
        fontWeight: '700',
        marginBottom: 8,
        fontSize: 12,
        color: '#2E2D2D',
    },
    bottom: {
        width: '100%',
        height: 20,
        justifyContent: 'center',
        backgroundColor: MAIN_BAR_COLOR,
    },
    priceText: {
        fontSize: 10,
        fontWeight: '700',
        color: '#FFFFFF',
        textAlign: 'center'
    }
});
