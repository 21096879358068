import {Dimensions, Image, Modal, StyleSheet, View, Text, ScrollView} from "react-native";
import React, {useContext, useEffect, useState} from "react";
import {COLORS, MAIN_BAR_COLOR} from "../colors";
import {getWidthExpression, UserContext} from "../func";
import TPressable from "../controls/TPressable";
import TRANSLATES, {getCurrentLang} from "../translates/translates";
import {OrangeButtonWithPlusIcon} from "../controls/OrangeButtonWithPlusIcon";
import BottomModal from "../controls/BottomModal";
import SignForm from "../auth/SignForm";
import {getSubById} from "../ads";
import {EMPTY_EMAIL} from "../types";
import MultiWebView from "../controls/MultiWebView";

interface IProps {
    onClose: () => void,
    onSubscribeClick?: () => void
}


function SubDetailsHeader(props: IProps) {
    return (
        <View style={styles.headerRoot}>
            <View style={styles.headerContainer}>

                <TPressable name="fighterAppBarBack" onPress={() => props.onClose()}
                            style={{width: 24, marginRight: 'auto', marginTop: 12}}>
                    <Image source={require('../icons/arrow-left-2.png')} style={{width: 24, height: 24}}/>
                </TPressable>

                <Image source={require('../icons/logo.png')}
                       style={{height: 19, width: 120, marginTop: 'auto'}}/>

            </View>
            <View style={styles.bottom}>

            </View>
        </View>

    );
}

export default function SubDetails(props: IProps) {

    const [loginVisible, setLoginVisible] = useState(false)
    const [signText, setSignText] = useState(TRANSLATES['SignIn'])
    const context = useContext(UserContext)
    const [price, setPrice] = useState<string>('')
    const [webViewPage, setWebViewPage] = useState<{ title: string, url: string } | null>(null);
    const arr = [
        TRANSLATES['sub.feature.1'],
        TRANSLATES['sub.feature.2'],
        TRANSLATES['sub.feature.3'],
        TRANSLATES['sub.feature.4'],
        TRANSLATES['sub.feature.5']
    ]

    const arr2 = [
        TRANSLATES['sub.feature.6'],
        TRANSLATES['sub.feature.7'],
        TRANSLATES['sub.feature.8']
    ]

    const arr3 = [
        TRANSLATES['sub.feature.9'],
        TRANSLATES['sub.feature.10'],
        TRANSLATES['sub.feature.11']
    ]

    const sub_info = [
        'sub-info.1',
        'sub-info.2',
        'sub-info.3',
        'sub-info.4'
    ]

    const moreInfoLink = <TPressable name="terms-conditions-apply"
                                     onPress={() => setWebViewPage({
                                         title: TRANSLATES['TermService'],
                                         url: 'https://platform.bd-sport.com/terms-conditions-apply'
                                     })}>
        <Text style={{
            color: '#2E2D2D',
            fontSize: 12,
            marginLeft: 3,
            textDecorationLine: 'underline',
            fontWeight: '500'
        }}>
            Terms and Conditions Apply Subscriptions
        </Text>
    </TPressable>

    const loadSub = async () => {
        let sub = await getSubById(context.fantasyAppFeatures.subscriptionId)
        if (sub)
            setPrice((sub.currency || "$") + (sub.localizedPrice || sub.price))
    }

    useEffect(() => {
        loadSub()
    }, [context.fantasyAppFeatures]);

    if (loginVisible)
        return <BottomModal visible={true}
                            title={signText}
                            height={400}
                            onCloseClick={() => setLoginVisible(false)}

        >
            <SignForm onTitleChanged={(text: string) => setSignText(text)}
                      isSignIn={false}
                      doneCallback={() => {
                          if (props.onSubscribeClick)
                              props.onSubscribeClick()
                          else
                              setLoginVisible(false)
                      }}/>
        </BottomModal>


    const renderTextPoint = (text: string, more?: React.ReactNode) => {

        let txtNode = <Text style={{fontSize: 12, fontWeight: '500', marginLeft: 4, color: COLORS.TEXT_COLOR}}>
            {text}
        </Text>

        return <View key={text} style={{marginTop: 8, flexDirection: 'row'}}>
            <Image source={require('../assets/tick.png')}
                   style={{height: 16, width: 16}}/>


            {more ? <View>{txtNode}{more}</View> : txtNode}

        </View>
    }

    if (webViewPage)
        return <BottomModal visible={true}
                            title={webViewPage.title}
                            overlayHeight={10}
                            padding={0}
                            height={Dimensions.get('window').height}
                            onCloseClick={() => setWebViewPage(null as any)}>

            <MultiWebView url={webViewPage.url}
                          style={{height: Dimensions.get('window').height - 30}}/>

        </BottomModal>


    return (
        <Modal
            animationType="slide"
            transparent={false}
            visible={true}
            style={{backgroundColor: '#fff'}}
        >
            <SubDetailsHeader onClose={props.onClose}/>

            <ScrollView style={styles.root}>
                <Image source={require(`../assets/sub_background1.png`)}
                       resizeMethod={'resize'}
                       style={styles.image}/>
                <View style={styles.firstBlock}>
                    <Text style={{color: '#fff', fontSize: getCurrentLang() == 'ru' ? 16: 20, fontWeight: '500'}}>
                        {TRANSLATES['subdetails.new-look']}
                    </Text>
                    <Text style={{color: '#fff', fontSize: 13, fontWeight: '400', marginTop: 8, marginBottom: 12}}>
                        {TRANSLATES['subdetails.additional-features']}
                    </Text>
                    {(!context.user?.Email || context.user?.Email?.startsWith(EMPTY_EMAIL)) &&
                        <OrangeButtonWithPlusIcon text={TRANSLATES["Sign up now"]}
                                                  onClick={() => setLoginVisible(true)}/>
                    }
                </View>


                <Image source={getCurrentLang() == 'ru' ? require(`../assets/sub-ru/sub_info1.png`) : require(`../assets/sub_info1.png`)}
                       resizeMethod={'resize'}
                       style={{height: 464, width: '100%', marginTop: 16}}/>


                {arr.map(it => renderTextPoint(it))}

                <Image source={getCurrentLang() == 'ru' ? require(`../assets/sub-ru/sub_info2.png`) : require(`../assets/sub_info2.png`)}
                       resizeMethod={'resize'}
                       style={{height: 466, width: '100%'}}/>

                {arr2.map(it => renderTextPoint(it))}


                <Image source={getCurrentLang() == 'ru' ? require(`../assets/sub-ru/sub_info3.png`) : require(`../assets/sub_info3.png`)}
                       resizeMethod={'resize'}
                       style={{height: 470, width: '100%', marginTop: 24}}/>


                {arr3.map(it => renderTextPoint(it))}

                <View style={{marginTop: 24}}>
                    <OrangeButtonWithPlusIcon text={price || context.fantasyAppFeatures.subscriptionPrice}
                                              onClick={props.onSubscribeClick!}/>
                </View>


                {sub_info.map(it => renderTextPoint(TRANSLATES[it], it == 'sub-info.4' ? moreInfoLink : null))}


                <View style={{height: 20}}></View>

            </ScrollView>
        </Modal>)
}

const styles = StyleSheet.create({
    headerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 92
    },
    bottom: {
        position: 'absolute',
        alignItems: 'center',
        bottom: -15,
        left: 0,
        width: getWidthExpression(0),
        backgroundColor: '#fff',
        borderRadius: 15,
        height: 30
    },
    headerRoot: {
        backgroundColor: MAIN_BAR_COLOR,

        height: 126,
        paddingLeft: 18,
    },

    image: {
        width: '100%',
        height: 149,
        borderRadius: 10
    },
    root: {
        position: 'relative',
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: '#fff',
        height: Dimensions.get('window').height - 160
    },
    firstBlock: {
        padding: 16,
        position: 'absolute',
        borderRadius: 10,
        height: 149
    }
})