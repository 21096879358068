import React, {useEffect, useState} from "react";
import {CardPackage4, UserCard} from "../types";
import {Dimensions, Image, Modal, Pressable, ScrollView, StyleSheet, Text, View} from "react-native";
import TRANSLATES from "../translates/translates";
import {MAIN_BAR_COLOR} from "../colors";
import GradientBox from "../controls/GradientBox";
import {CardPrice} from "./CardShop";
import PreviewCard from "./PreviewCard";
import {OrangeButton} from "../controls/OrangeButton";
import {formatWithDecimals} from "../func";
import {ServerAPI} from "../ServerAPI";
import Card from "../UserCardPage/Card";
import {CardStatusCoefInfo} from "../team/GiveRewardScreen";

interface IProps {
    previewText: string
    onClose: () => void;
    onBuyClick: () => void;
    cardPrice?: CardPrice | undefined
    cardPackage4?: CardPackage4 | undefined,
}

export default function PreviewPack(props: IProps) {

    const [viewCards, setViewCards] = React.useState<UserCard[]>([])
    const [cardCoef, setCardCoef] = useState<CardStatusCoefInfo>({})

    const loadRandom4Cards = async () => {
        let response = await ServerAPI.get<UserCard[]>('/fantasy/preview-1pack-cards/' + props.cardPrice?.status);
        setViewCards(response)
    }

    const loadCoef = async () => {
        let res = await ServerAPI.get<CardStatusCoefInfo>(`/card-coef`)
        setCardCoef(res)
    }

    useEffect(() => {

        if (props.cardPrice?.status) {
            loadCoef()
            loadRandom4Cards()
        }

    }, [props.cardPrice?.status]);

    const loadRandom8Cards = async () => {
        let response1 = await ServerAPI.get<UserCard[]>('/fantasy/preview-4pack-cards/' + props.cardPackage4?.status);
        //let response2 = await ServerAPI.get<UserCard[]>('/fantasy/random-cards/' + props.cardPackage4?.status);
        setViewCards(response1)
    }

    useEffect(() => {

        if (props.cardPackage4) {
            loadCoef()
            loadRandom8Cards()
        }

    }, [props.cardPackage4?.status]);

    return (
        <Modal
            animationType={"slide"}
            transparent={false}
            visible={true}
            style={{backgroundColor: MAIN_BAR_COLOR, position: 'relative'}}
        >

            <ScrollView style={styles.root}>

                <GradientBox width={Dimensions.get('window').width}
                             height={150}
                             style={{position: 'absolute', left: 0, top: 0}}
                             stop1={"#7593FF"}
                             stop2={"#FFBC60"}
                />

                <View style={styles.header1}>
                    <Text style={styles.mainTextStyle}>
                        {props.previewText}
                    </Text>
                </View>


                <View style={{marginTop: 100}}>
                    <View style={styles.roundItem}/>
                    <View style={styles.header2}>
                        <Text style={styles.mainTextStyle}>
                            {TRANSLATES['guaranteed_rewards']}
                        </Text>
                    </View>


                    <View style={{padding: 16, alignItems: 'center'}}>
                        {props.cardPrice &&
                            <>
                                <PreviewCard isAthlete={true} status={props.cardPrice.status}/>
                                <OrangeButton
                                    onClick={() => props.onBuyClick()}
                                    style={{
                                        marginTop: 24,
                                        width: '100%'
                                    }}
                                    text={formatWithDecimals(props.cardPrice ? props.cardPrice.price : props.cardPackage4!.price)
                                        + ' ' + TRANSLATES["coins"]}/>
                            </>
                        }

                        {props.cardPackage4 &&
                            <>
                                <View style={{flexDirection: "row", position: 'relative'}}>

                                    <PreviewCard isAthlete={true} status={props.cardPackage4.status}
                                                 style={{left: 20}}/>
                                    <PreviewCard isAthlete={true} status={props.cardPackage4.status}
                                                 style={{right: 20}}/>

                                    <PreviewCard isAthlete={false} status={props.cardPackage4.status}
                                                 style={{left: 20}}/>
                                    <PreviewCard isAthlete={false} status={props.cardPackage4.status}
                                                 style={{right: 20}}/>


                                </View>
                                <OrangeButton
                                    onClick={() => props.onBuyClick()}
                                    style={{
                                        marginTop: 24,
                                        width: '100%'
                                    }}
                                    text={formatWithDecimals(props.cardPrice ? props.cardPrice.price : props.cardPackage4!.price)
                                        + ' ' + TRANSLATES["coins"]}/>
                            </>
                        }
                    </View>
                </View>

                <View style={{marginTop: 24, alignItems: 'center'}}>
                    <Text style={styles.mainTextStyle}>
                        {TRANSLATES['possible_rewards']}
                    </Text>

                    <Text style={styles.subtitle}>
                        {TRANSLATES['possible_rewards.sub-title']}
                    </Text>

                </View>


                <View style={{
                    padding: 12,
                    flexWrap: "wrap",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-evenly"
                }}>
                    {viewCards.map(it => <Card card={it}
                                               style={{marginBottom: 12}}
                                               key={it.id}/>)}

                </View>

                <View style={{height: 56}}></View>

            </ScrollView>

            <Pressable style={styles.footer} onPress={() => props.onClose()}>
                <Image source={require('../icons/arrow-left-2.png')} style={{width: 24, height: 24}}/>
                <Text style={styles.backText}>{TRANSLATES['Back']}</Text>
            </Pressable>

        </Modal>)
}

const styles = StyleSheet.create({
    button: {
        width: 148
    },
    root: {
        position: 'relative',
        backgroundColor: MAIN_BAR_COLOR,
        height: Dimensions.get('window').height
    },
    header1: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 44
    },
    header2: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    mainTextStyle: {
        fontSize: 15,
        color: '#fff',
        fontWeight: '700'
    },
    subtitle: {
        marginTop: 20,
        fontSize: 10,
        color: '#fff',
        opacity: 0.5
    },
    roundItem: {
        left: 0,
        width: '100%',
        backgroundColor: MAIN_BAR_COLOR,
        borderRadius: 60,
        top: -20,
        position: 'absolute',
        height: 60
    },
    footer: {
        height: 56,
        width: '100%',
        backgroundColor: '#131C3A',
        position: 'absolute',
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        gap: 8,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10
    },
    backText: {
        color: '#fff',
        fontSize: 15,
        fontWeight: '700'
    }
})
