import {Text, StyleSheet, View, ScrollView} from 'react-native';

import React from 'react';
import {DistributionPoint, FantasyInfo, FantasyRateRow, Fighter, UserCard} from '../types';
import TRANSLATES from '../translates/translates';
import FButton from '../controls/FButton';
import CheckBox from './CheckBox';
import {MAIN_BAR_COLOR} from '../colors';
import FighterAppBar from './FighterAppBar';
import {getHeightExpression} from '../func';
import StepProgress from '../controls/StepProgress';
import {CardList} from "../shop/CardShop";
import {ServerAPI} from "../ServerAPI";


interface IProps {
	athlete: Fighter;
	fantasy: FantasyInfo;
	fantasyRateRow: FantasyRateRow;
	onChangeClick: Function
	closeClick: Function,
	onRemoveClick: Function
	userCards: UserCard[]
}

interface IState {
	athlete: Fighter,
	items: DistributionPoint[];
	fantasyRateRow: FantasyRateRow,
	fantasy: FantasyInfo,
	userCards: UserCard[]
}

/**
 *
 */
export default class FantasyRateRowView extends React.Component<IProps, IState> {

	constructor(props: IProps)
	{
		super(props);
		this.state = {
			items: [],
			fantasyRateRow: props.fantasyRateRow,
			athlete: props.athlete,
			fantasy: props.fantasy,
			userCards: props.userCards
		};
	}


	componentDidMount()
	{
		this.refresh();
	}

	async refresh()
	{
		let items = await ServerAPI.getDistributionPoints();

		this.setState({
			items: items
		});
	}


	onChangeClick()
	{
		this.props.onChangeClick();
	}

	onRemoveClick()
	{
		this.props.onRemoveClick();
	}

	onEventClick()
	{
		this.props.closeClick();
	}

	isPointSelected(item: DistributionPoint)
	{
		return !!this.state.fantasyRateRow.AdPoints?.find(it => it.Id == item.Id);
	}

	isCardSelected(userCard: UserCard)
	{
		return !!this.state.fantasyRateRow.SelectedUserCards.find(it => it == userCard.id)
	}

	render()
	{
		let points = this.state.items;

		let ad1List = points.filter(it => it.Type == 'Additional1' && this.isPointSelected(it));
		let ad2List = points.filter(it => it.Type == 'Additional2' && it.Points > 0);

		let winningItem = points.find(it => it.Id == 'Win');

		let selectedCards = this.state.fantasyRateRow?.SelectedUserCards || [];

		let extraList = points.filter(p => p.Type == 'Extra' && selectedCards.find(it => it == p.Key));

		let allAcceptedCards = selectedCards.map(key => this.state.userCards.find(it => it.id == key)).filter(it => !!it) as UserCard[];

		return (
			 <View style={styles.root}>

				 {this.state.athlete && <FighterAppBar fighter={this.state.athlete} fantasy={this.state.fantasy}/>}

				 <StepProgress current={4} count={5}/>

				 <ScrollView style={styles.container}>

					 {winningItem &&
					<CheckBox
						key={winningItem.Id}
						selected={true}
						mainText={TRANSLATES['Win.main']}
						points={winningItem.Points}
					/>
					 }

					 <Text style={styles.chooseText}>
						 {TRANSLATES['Step4Title1']}
					 </Text>

					 {ad1List.map(p => <CheckBox
						  key={p.Id}
						  cardCoefText={this.state.userCards.find(it => this.isCardSelected(it) && it.fantasyPointId == p.Key)?.cardCoef}
						  selected={this.isPointSelected(p)}
						  enabled={this.isPointSelected(p)}
						  points={p.Points}
						  mainText={TRANSLATES[p.Id] || p.Name}
					 />)}

					 <Text style={styles.chooseText}>
						 {TRANSLATES['Step4Title2']}
					 </Text>

					 {ad2List.map(p => <CheckBox
						  key={p.Id}
						  cardCoefText={this.state.userCards.find(it => this.isCardSelected(it) && it.fantasyPointId == p.Key)?.cardCoef}
						  selected={this.isPointSelected(p)}
						  enabled={this.isPointSelected(p)}
						  mainText={TRANSLATES[p.Id] || p.Name}
						  points={p.Points}
					 />)}

					 {extraList?.length > 0 && <Text style={styles.chooseText}>
						 {TRANSLATES['Step4Title3']}
				</Text>
					 }

					 {extraList.map(ep =>
						  <CheckBox
								key={ep.Id}
								selected={true}
								mainText={TRANSLATES[ep.Id] || ep.Name}
								points={ep.Points}
						  />)
					 }

					 <CardList
						  text={TRANSLATES['AcceptedCards']}
						  showText={allAcceptedCards.length > 0}
						  list={allAcceptedCards}/>
				 </ScrollView>


				 <View style={styles.buttons}>

					 <FButton text={TRANSLATES['Change']} style={{marginRight: 0}} width={104}
								 onClick={() => this.onChangeClick()}/>
					 <FButton text={TRANSLATES['Remove']} style={{marginRight: 0}} width={104}
								 onClick={() => this.onRemoveClick()}/>

					 <FButton text={TRANSLATES['Event']} style={{marginRight: 0}} width={104}
								 onClick={() => this.onEventClick()}
								 backgroundColor={MAIN_BAR_COLOR}
								 color="#FFFFFF"/>
				 </View>

			 </View>);
	}
}


const styles = StyleSheet.create({
	chooseText: {
		fontWeight: '700',
		fontSize: 15,
		marginTop: 16,
		marginBottom: 16,
		color: '#2E2D2D',
	},
	container: {
		backgroundColor: '#EFF1F7',
		paddingLeft: 16,
		paddingRight: 16
	},
	root: {
		width: '100%',
		height: getHeightExpression(0)
	},
	buttons: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 16
	},
});
