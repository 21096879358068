import {Image, ImageBackground, StyleSheet, Text, View} from 'react-native';
import React from 'react';
import {Athlete} from '../types';
import {MAIN_BAR_COLOR} from '../colors';
import {getWidthExpression} from '../func';


interface IProps {
	athlete: Athlete,
	onClose: Function,
	bottomStyle?: any
}

interface IState {

}

export default class FighterCardAppBar extends React.Component<IProps, IState> {


	constructor(props: IProps)
	{
		super(props);
		this.state = {};
	}

	getAthleteImage()
	{
		let f = this.props.athlete;
		let source = (f.AvatarUrl || f.PhotoUrl) ? {uri: f.AvatarUrl || f.PhotoUrl} : require('../assets/athlete_shadow.png')
		return <View style={{backgroundColor: '#EFF1F6', borderRadius: 50}}>
			<ImageBackground source={source} resizeMode="contain" style={styles.fighterImage}/>
		</View>
	}

	render()
	{
		let total = this.props.athlete.AthleteRating.total || this.props.athlete.AthleteRating.totalRecord;
		let flagUrl = this.props.athlete.CountryISO && `https://storage.yandexcloud.net/bd-sport-images/country/${this.props.athlete.CountryISO}.png`;
		return (
			 <View style={styles.root}>
				 <View style={styles.container}>

					 <View>
						 {this.getAthleteImage()}
						 {flagUrl && <Image source={{uri: flagUrl}} style={styles.flag}/>}
					 </View>

					 <View style={styles.fighterName}>

						 <Text style={styles.fighterNameText} numberOfLines={2}>
							 {this.props.athlete.FirstName} {this.props.athlete.LastName}
						 </Text>

						 <View style={styles.ratingView}>
							 <Image source={require('../assets/rating.png')} style={styles.ratingImg}/>
							 <Text style={styles.ratingText}>{total}</Text>
						 </View>

					 </View>

				 </View>
				 <View style={[styles.bottom, this.props.bottomStyle]}>

				 </View>
			 </View>

		);
	}
}


const styles = StyleSheet.create({
	ratingView: {
		left: -4,
		top: 4,
		borderRadius: 20,
		width: 36,
		height: 14,
		backgroundColor: 'rgba(64, 92, 191, 1)',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		flexDirection: 'row'
	},
	ratingImg: {
		width: 16,
		height: 16,
	},
	ratingText: {
		color: '#fff',
		fontWeight: '700',
		fontSize: 10
	},
	closeBtn: {
		position: 'absolute',
		top: 6,
		width: 30,
		height: 30
	},
	bottom: {
		position: 'absolute',
		alignItems: 'center',
		bottom: -15,
		left: 0,
		width: getWidthExpression(0),
		backgroundColor: '#fff',
		borderRadius: 15,
		height: 30
	},
	fighter: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	flag: {
		width: 18,
		height: 10,
		position: 'absolute',
		right: 5,
		bottom: 5,
	},
	fighterName: {
		marginLeft: 16,
		width: getWidthExpression(150),
	},
	fighterNameText: {
		color: 'white',
		fontWeight: '700',
		fontSize: 20,
	},
	eventNameText: {
		color: 'white',
		fontWeight: '700',
		fontSize: 15,
	},
	container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: 16
	},
	root: {
		backgroundColor: MAIN_BAR_COLOR,

		height: 126,
		paddingLeft: 18,
	},
	fighterImage: {
		width: 80,
		height: 80
	},
});



