import {Pressable, SafeAreaView, StyleSheet, Text, View} from 'react-native';
import React from 'react';
import {COLORS, MAIN_BAR_COLOR} from '../colors';
import {getWidthExpression} from '../func';
import UserAvatarInfo from "../UserAvatarInfo";
import TRANSLATES from "../translates/translates";
import ToolTipButton from "../controls/ToolTipButton";
import {OnBoardingStyles} from "../Onboarding";


interface IProps {
    titleText?: string
    titleCards?: string
    cardsRating?: number
    onGymOpened?: Function,
    onboardStep: number | null
    navigation: any
}

interface IState {
    loginVisible: boolean,
    refreshing: boolean,
    ptsRatingVisible: boolean,
    ptsHistoryVisible: boolean,
    coinHistoryVisible: boolean,

}

export default class UserAppBarOnCardPage extends React.Component<IProps, IState> {


    constructor(props: IProps) {
        super(props);
        this.state = {
            loginVisible: false,
            refreshing: false,
            ptsRatingVisible: false,
            ptsHistoryVisible: false,
            coinHistoryVisible: false
        };
    }



    render() {

        let onBoardStyle = []
        if (this.props.onboardStep == 0)
            onBoardStyle.push(OnBoardingStyles.highlight, {
                paddingTop: 8,
                paddingLeft: 4,
                paddingBottom: 8,
                paddingRight: 4
            })

        return (
            <SafeAreaView style={styles.container}>

                <UserAvatarInfo navigation={this.props.navigation}/>

                <View style={styles.bottom}/>

                <View style={[styles.titleRow, {marginTop: 48}]}>
                    <Text style={styles.titleText}>
                        {this.props.titleText}
                    </Text>
                    <ToolTipButton>
                        <View style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                            <Text style={styles.modalText}>{TRANSLATES['UserCardToolTip1']}</Text>
                            <Text style={styles.modalText}>-{TRANSLATES['UserCardToolTip2']}</Text>
                            <Text style={styles.modalText}>-{TRANSLATES['UserCardToolTip3']}</Text>
                            <Text style={styles.modalText}>-{TRANSLATES['UserCardToolTip4']}</Text>
                        </View>
                    </ToolTipButton>

                    <Text style={styles.subTitle2}>{this.props.titleCards}</Text>
                </View>


                <View style={onBoardStyle}>
                    <Pressable onPress={() => this.props.onGymOpened && this.props.onGymOpened()}
                               style={[
                                   styles.titleRow,
                                   {marginTop: this.props.onboardStep == null ? 8 : 0},
                                   {backgroundColor: '#fff'}
                               ]}>
                        <Text style={[styles.titleText, {color: COLORS.MAIN_BAR_COLOR}]}>{TRANSLATES['YourGyms']}</Text>
                        <Text
                            style={[styles.subTitle2, {color: COLORS.MAIN_BAR_COLOR}]}>1 {TRANSLATES['gym']}/{this.props.cardsRating} {TRANSLATES['rating']}</Text>
                    </Pressable>
                </View>

            </SafeAreaView>
        );
    }
}

export const TopUserPanelHeight = 290;

const styles = StyleSheet.create({
    titleRow: {
        borderRadius: 10,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 10,
        paddingBottom: 10,
        flexDirection: 'row',
        backgroundColor: COLORS.SECONDARY_ACCESS_COLOR,
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    titleText: {
        color: '#FFFFFF',
        fontWeight: '700',
        fontSize: 15,
        marginRight: 8
    },
    subTitle2: {
        color: '#FFFFFF',
        fontWeight: '500',
        fontSize: 15,
        marginLeft: 'auto'
    },
    modalText: {
        color: '#fff'
    },
    pointsText: {
        color: '#FFFFFF',
        fontSize: 12,
        opacity: 0.5
    },

    container: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 24,
        backgroundColor: MAIN_BAR_COLOR,
        height: TopUserPanelHeight,
    },

    bottom: {
        position: 'absolute',
        bottom: -30,
        left: 0,
        width: getWidthExpression(0),
        backgroundColor: '#EFF1F7',
        borderRadius: 30,
        height: 60
    }
});



