import React from 'react';
import {Animated, StyleSheet, View} from 'react-native';
import {MAIN_BAR_COLOR} from '../colors';
import Svg, {Circle} from "react-native-svg";

interface IProps {
	style?: any
}

export default class CircularProgress extends React.Component<IProps, any> {

	private animation: Animated.Value;
	private myCircle: React.RefObject<any>;
	private flag: boolean;


	constructor(props: IProps)
	{
		super(props);
		this.state = {};
		this.animation = new Animated.Value(0)
		this.myCircle = React.createRef();
		this.flag = false;
	}

	componentDidMount()
	{
		this.runAnimation()

		this.animation.addListener((state: { value: number }) =>
		{
			let value = this.flag ? state.value : 1 - state.value;
			this.myCircle.current?.elementRef?.current.setAttribute('stroke-dasharray', `${100 * value} 120`)
		});
	}

	runAnimation()
	{
		this.flag = !this.flag
		this.animation.setValue(0)
		Animated.timing(this.animation, {
			toValue: 1,
			duration: 1500,
			useNativeDriver: true
		}).start(() => this.runAnimation());
	}

	render()
	{
		let width = 80;
		const size = width - 32;

		const rotation = this.animation.interpolate({
			inputRange: [0, 0.5],
			outputRange: ['0deg', '360deg']
		});


		return <View style={[styles.root, this.props.style]}>
			<Animated.View style={{transform: [{rotate: rotation}], position: 'absolute', left: 1, top: 1}}>
				<Svg width={size} height={size}>
					<Circle
						 ref={this.myCircle}
						 cx='24' cy='24' r='18'
						 fill="none"
						 strokeWidth="3.6"
						 stroke={MAIN_BAR_COLOR}
						 strokeDasharray={[100, 120]}
					/>
				</Svg>
			</Animated.View>
		</View>
	}
}

const styles = StyleSheet.create({
	root: {
		elevation: 3,
		boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12)',
		zIndex: 3,
		backgroundColor: 'white',
		width: 50,
		height: 50,
		borderRadius: 30
	}
});
