import {format, parse} from 'date-fns';
import {ru} from 'date-fns/locale';
import {Dimensions, Platform} from 'react-native';
import {CardStatus, EMPTY_EMAIL, FantasyAppFeatures, FantasyInfo, User, UserCard} from './types';
import AsyncStorage from '@react-native-async-storage/async-storage';
import uuid from 'react-native-uuid';
import React from "react";
import TRANSLATES, {getCurrentLang} from "./translates/translates";

export const SESSION_TOKEN = '@SESSION_TOKEN'


export function getDateString2(eventDate: string) {
    if (!eventDate)
        return '';

    try {
        let d = parse(eventDate, 'yyyy-MM-dd', new Date());
        return format(d, 'MMM do, yyyy');
    } catch (e) {
        return 'parse date error'
    }
}


export function getDateString(eventDate: string) {
    if (!eventDate)
        return '';

    try {
        let d = parse(eventDate, 'yyyy-MM-dd HH:mm:ss.S', new Date());
        return format(d, 'MMMM do, yyyy ');
    } catch (e) {
        return 'parse date error'
    }
}

export function isPastEvent(info: FantasyInfo) {
    if (!info)
        return null;

    if (info.IsPast)
        return true;


    let d = parse(info.EventDate, 'yyyy-MM-dd HH:mm:ss.S', new Date());
    let offsetInMs = new Date().getTimezoneOffset() * 60 * 1000;
    let localDate = new Date(d.getTime() - offsetInMs)

    if (new Date().getTime() > localDate.getTime())
        return true;
}

export function getDateStringFromUTC2LocalTime(eventDate: string) {
    if (!eventDate)
        return '';


    let d = parse(eventDate, 'yyyy-MM-dd HH:mm:ss.S', new Date());

    let offsetInMs = new Date().getTimezoneOffset() * 60 * 1000;
    let localDate = new Date(d.getTime() - offsetInMs)

    let lang = getCurrentLang()

    if (lang == 'ru') {
        let res = format(localDate, 'EE, MMM dd, yyyy HH:mm', {locale: ru});
        return res.charAt(0).toUpperCase() + res.slice(1);
    } else
        return format(localDate, "EE, MMM dd, yyyy hh:mm aaaaa'm'");	//Saturday, March 18, 2023 8:00 pm
}


export function getWidthExpression(minusW: number) {
    if (Platform.OS === 'web') {
        return `calc(100% - ${minusW}px)`;
    }


    return Dimensions.get('window').width - minusW;
}


export function getHeightExpression(minusH: number = 0) {
    if (Platform.OS === 'web') {
        return `calc(100% - ${minusH}px)`;
    }


    return Dimensions.get('window').height - minusH;
}

export function getRatingInterval(status: CardStatus) {
    let ratingText = '70-79'
    if (status == CardStatus.bronze)
        ratingText = '50-69'
    else if (status == CardStatus.gold)
        ratingText = '80-100'

    return ratingText
}

export function formatWithDecimals(num: number) {
    let sepMulti = getCurrentLang() == 'ru' ? ' ' : ',';

    let intNum = Math.trunc(num).toString();

    if (num < 1000)
        return intNum;


    let res = '';

    if (intNum.length > 2) {
        for (var i = intNum.length - 1; i >= 2; i -= 3) {
            let part = intNum[i - 2] + intNum[i - 1] + intNum[i];

            if (res)
                res = part + sepMulti + res;
            else
                res = part
        }

        if (i == 1)
            res = intNum[0] + intNum[1] + sepMulti + res;
        else if (i == 0)
            res = intNum[0] + sepMulti + res;
    } else
        return num;

    return res;
}


export function getCountryFlagUrl(iso: string) {
    return iso && `https://storage.yandexcloud.net/bd-sport-images/country/${iso}.png`;
}

export type DistinctByFunction<T> = {
    (item: T): any;
};

export function distinctBy<T>(arr: T[], func: DistinctByFunction<T>): T[] {
    let pSet = new Set();
    let res: any[] = [];

    arr.forEach(it => {

        let id = func(it);
        if (!pSet.has(id)) {
            res.push(it)
            pSet.add(id)
        }
    })

    return res;
}

export function timeToSec(seconds: string): number {
    let arr = seconds.split(":");
    return parseInt(arr[arr.length - 2]) * 60 + parseInt(arr[arr.length - 1]);
}

export function secondsToTime(seconds: number) {
    if (seconds == 0)
        return '00:00'
    let h = Math.floor(seconds / 3600);
    seconds = seconds % 3600;
    let m = Math.floor(seconds / 60);
    let s = Math.floor(seconds % 60);
    let hStr = h > 0 ? ((h < 10 ? '0' : '') + h + ':') : '';
    return `${hStr}${m < 10 ? '0' : ''}${m}:${s < 10 ? '0' : ''}${s}`;
}

export function secondsToTime2(seconds: number) {
    let res = secondsToTime(seconds)
    return res.substring(1, res.length)
}

export async function deleteData(key: string) {
    try {
        await AsyncStorage.removeItem(key);
    } catch (error) {
        console.log(error)
    }
}


export async function saveData(key: string, value: string) {
    try {
        await AsyncStorage.setItem(key, value);
    } catch (error) {
        console.log(error)
    }
}

export async function getData(key: string) {
    try {
        let v = await AsyncStorage.getItem(key);
        return v!!;
    } catch (error) {
        console.log(error)
    }
}


export function uuidv4() {
    return uuid.v4().toString()
}


export function get3SubList<T>(sortedCards: T[]) {
    let cardList = [];
    for (let i = 0; i < sortedCards.length; i += 3) {
        let arr = [sortedCards[i], sortedCards[i + 1], sortedCards[i + 2]]
        cardList.push(arr.filter(it => !!it));
    }

    return cardList;
}

export function getSumRating(cards: UserCard[], gymId: number | undefined) {
    if (gymId == undefined || !cards.length)
        return 0;
    return cards.filter(it => it.gyms?.indexOf(gymId) > -1)
        .reduce((prev, current) => prev + current.athleteRating, 0);
}


export function isAnonymous(user: User) {
    let email = user.Email;
    if (!email || email.startsWith(EMPTY_EMAIL))
        return true;

    return false
}

export type SaveUserFunc = (user: User) => void

export const UserContext = React.createContext({
    fantasyAppFeatures: {} as FantasyAppFeatures,
    setFantasyAppFeatures: (value: FantasyAppFeatures) => {

    },
    user: {} as User,
    setUser: (user: User) => {
    }
});

export function round(num: number): number {
    return Math.round(num * 100) / 100
}

export function round100(num: number): number {

    let arr = [10, 20, 30, 50, 70]

    for (let i = 0; i < arr.length; i++)
        if (num <= arr[i])
            return arr[i]

    return 100
}


export function getStepInfo(max: number) {
    let steps = [
        {max: 10, step: 2},
        {max: 20, step: 4},
        {max: 30, step: 5},
        {max: 40, step: 5},
        {max: 50, step: 10}]

    for (let m of steps)
        if (max < m.max)
            return m

    return {step: 20, max: 100};
}


export function hexToRgbA(hex: string, opacity: number) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        // @ts-ignore
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${opacity})`;
    }
    throw new Error('Bad Hex');
}


export function getBaseColor(status: CardStatus) {
    if (status == CardStatus.gold)
        return '#F1CB8F';

    if (status == CardStatus.silver)
        return '#CACDDE';

    return '#B97344'; 		//bronze
}

export function getDayOfWeek1_7() {
    let now = new Date();

    let dayOfWeek = now.getDay(); // Sunday - Saturday : 0 - 6

    dayOfWeek = (dayOfWeek === 0) ? 7 : dayOfWeek;

    return dayOfWeek
}

export const DAY = 1000 * 60 * 60 * 24

export function getDiffStr(diff: number) {
    let days = Math.floor(diff / DAY);
    let hours = Math.floor((diff % DAY) / (1000 * 60 * 60));
    if (days > 0)
        return TRANSLATES['in'].replace('{0}', days.toString())
            .replace('{1}', days.toString())

    return TRANSLATES['in_hour']
        .replace('{1}', days.toString())
}

export function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getFlagUrl(iso: string) {
    return `https://storage.yandexcloud.net/bd-sport-images/country/${iso}.png`;
}